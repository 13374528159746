// @flow
import mediatorChannels from "@tvg/mediator";
import { get } from "lodash";
import pushToDataLayer from "../gtmUtils";

type PastPerformancesGTMTypeEvents =
  | "PAST_PERFORMANCES:EXPAND_COLLAPSE"
  | "PAST_PERFORMANCES:CLICK_PP"
  | "PAST_PERFORMANCES:CLICK_MORE_PAGE"
  | "PAST_PERFORMANCES:VIEW_RESULTS"
  | "PAST_PERFORMANCES:SELECT_PP_MORE_PAGE"
  | "PAST_PERFORMANCES:CLOSE_ALL_PP"
  | "PAST_PERFORMANCES:NAVIGATE_PP"
  | "PAST_PERFORMANCES:VIEW_RACECARD"
  | "PAST_PERFORMANCES:VIEW_BETS"
  | "PAST_PERFORMANCES:CLOSE_SINGLE_PP"
  | "PAST_PERFORMANCES:VIEW_REPLAY"
  | "PAST_PERFORMANCES:TOGGLE_CONDITIONS"
  | "PAST_PERFORMANCES:HORSE_SELECTOR"
  | "PAST_PERFORMANCES:SEE_ALL_RUNNERS";

type pastPerformancesGTMData = {
  type: PastPerformancesGTMTypeEvents,
  payload: {
    direction?: string,
    expandAction: "Expand" | "Collapse",
    runnerId: string,
    runnerName: string,
    raceNumber: string,
    trackName: string,
    toggleState: "On" | "Off",
    matchingConditions: string,
    runnerIndex: number
  }
};

export default () => {
  mediatorChannels.base.subscribe(
    "PAST_PERFORMANCES:EXPAND_COLLAPSE",
    (data: pastPerformancesGTMData) => {
      pushToDataLayer({
        event: "siteClick",
        gaEventCategory: "Site Click",
        gaEventAction: get(data, "payload.expandAction"),
        gaEventLabel: "Inline Past Performances",
        module: "Race Card",
        tag: get(data, "payload.runnerId"),
        microApp: undefined
      });
    }
  );

  mediatorChannels.base.subscribe(
    "PAST_PERFORMANCES:CLICK_PP",
    (data: pastPerformancesGTMData) => {
      pushToDataLayer({
        event: "siteClick",
        gaEventCategory: "Site Click",
        gaEventAction: "Select",
        gaEventLabel: "Inline Past Performances",
        module: "Inline PP",
        tag: `${get(data, "payload.runnerName")}/${get(
          data,
          "payload.trackName"
        )}/${get(data, "payload.raceNumber")}`,
        microApp: undefined
      });
    }
  );

  mediatorChannels.base.subscribe("PAST_PERFORMANCES:CLICK_MORE_PAGE", () => {
    pushToDataLayer({
      event: "siteClick",
      gaEventCategory: "Site Click",
      gaEventAction: "Open",
      gaEventLabel: "All PP Modal",
      module: "Inline PP",
      tag: undefined,
      microApp: undefined
    });
  });

  mediatorChannels.base.subscribe(
    "PAST_PERFORMANCES:NAVIGATE_PP",
    (data: pastPerformancesGTMData) => {
      pushToDataLayer({
        event: "siteClick",
        gaEventCategory: "Site Click",
        gaEventAction: "Select",
        gaEventLabel: `PP Navigation ${get(data, "payload.direction")}`,
        module: "PP Modal",
        tag: `${get(data, "payload.runnerName")}/${get(
          data,
          "payload.trackName"
        )}/${get(data, "payload.raceNumber")}`,
        microApp: undefined
      });
    }
  );

  mediatorChannels.base.subscribe(
    "PAST_PERFORMANCES:VIEW_RESULTS",
    (data: pastPerformancesGTMData) => {
      pushToDataLayer({
        event: "siteClick",
        gaEventCategory: "Site Click",
        gaEventAction: "Select",
        gaEventLabel: "Results",
        module: "PP Modal",
        tag: `${get(data, "payload.runnerName")}/${get(
          data,
          "payload.trackName"
        )}/${get(data, "payload.raceNumber")}`,
        microApp: undefined
      });
    }
  );

  mediatorChannels.base.subscribe(
    "PAST_PERFORMANCES:SELECT_PP_MORE_PAGE",
    (data: pastPerformancesGTMData) => {
      pushToDataLayer({
        event: "siteClick",
        gaEventCategory: "Site Click",
        gaEventAction: "Select",
        gaEventLabel: "Inline Past Performances",
        module: "All PP Modal",
        tag: `${get(data, "payload.runnerName")}/${get(
          data,
          "payload.trackName"
        )}`,
        microApp: undefined
      });
    }
  );

  mediatorChannels.base.subscribe("PAST_PERFORMANCES:CLOSE_ALL_PP", () => {
    pushToDataLayer({
      event: "siteClick",
      gaEventCategory: "Site Click",
      gaEventAction: "Close",
      gaEventLabel: "All PP Modal",
      module: "All PP Modal",
      tag: undefined,
      microApp: undefined
    });
  });

  mediatorChannels.base.subscribe("PAST_PERFORMANCES:CLOSE_SINGLE_PP", () => {
    pushToDataLayer({
      event: "siteClick",
      gaEventCategory: "Site Click",
      gaEventAction: "Close",
      gaEventLabel: "PP Modal",
      module: "PP Modal",
      tag: undefined,
      microApp: undefined
    });
  });

  mediatorChannels.base.subscribe(
    "PAST_PERFORMANCES:VIEW_RACECARD",
    (data: pastPerformancesGTMData) => {
      pushToDataLayer({
        event: "siteClick",
        gaEventCategory: "Site Click",
        gaEventAction: "Select",
        gaEventLabel: "RaceCard",
        module: "PP Modal",
        tag: `${get(data, "payload.runnerName")}/${get(
          data,
          "payload.trackName"
        )}/${get(data, "payload.raceNumber")}`,
        microApp: undefined
      });
    }
  );

  mediatorChannels.base.subscribe(
    "PAST_PERFORMANCES:VIEW_BETS",
    (data: pastPerformancesGTMData) => {
      pushToDataLayer({
        event: "siteClick",
        gaEventCategory: "Site Click",
        gaEventAction: "Select",
        gaEventLabel: "Bets",
        module: "PP Modal",
        tag: `${get(data, "payload.runnerName")}/${get(
          data,
          "payload.trackName"
        )}/${get(data, "payload.raceNumber")}`,
        microApp: undefined
      });
    }
  );

  mediatorChannels.base.subscribe(
    "PAST_PERFORMANCES:TOGGLE_CONDITIONS",
    (data: pastPerformancesGTMData) => {
      pushToDataLayer({
        event: "siteClick",
        gaEventCategory: "Site Click",
        gaEventAction: `Toggle ${data.payload.toggleState}`,
        gaEventLabel: "Matching Conditions",
        module: "All PP Modal",
        tag: `${get(data, "payload.matchingConditions")}`,
        microApp: undefined
      });
    }
  );

  mediatorChannels.base.subscribe(
    "PAST_PERFORMANCES:BACK_TO_ALL",
    (data: pastPerformancesGTMData) => {
      pushToDataLayer({
        event: "siteClick",
        gaEventCategory: "Site Click",
        gaEventAction: "Back",
        gaEventLabel: undefined,
        module: "PP Modal",
        tag: `${get(data, "payload.runnerName")}/${get(
          data,
          "payload.trackName"
        )}/${get(data, "payload.raceNumber")}`,
        microApp: undefined
      });
    }
  );

  mediatorChannels.base.subscribe(
    "PAST_PERFORMANCES:VIEW_REPLAY",
    (data: pastPerformancesGTMData) => {
      pushToDataLayer({
        event: "siteClick",
        gaEventCategory: "Site Click",
        gaEventAction: "Play",
        gaEventLabel: "Video",
        module: "PP Modal",
        tag: `${get(data, "payload.runnerName")}/${get(
          data,
          "payload.trackName"
        )}/${get(data, "payload.raceNumber")}`,
        microApp: undefined
      });
    }
  );

  mediatorChannels.base.subscribe(
    "PAST_PERFORMANCES:HORSE_SELECTOR",
    (data: pastPerformancesGTMData) => {
      pushToDataLayer({
        gaEventAction: "Open",
        gaEventCategory: "Site Click",
        gaEventLabel: "Horse Past Performance Modal",
        module: "All PP Modal",
        tag: get(data, "payload.runnerIndex")
      });
    }
  );

  mediatorChannels.base.subscribe("PAST_PERFORMANCES:SEE_ALL_RUNNERS", () => {
    pushToDataLayer({
      gaEventAction: "Open",
      gaEventCategory: "Site Click",
      gaEventLabel: "See all runners",
      module: "Full-Results",
      tag: undefined
    });
  });
};
