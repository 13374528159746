// @flow

import styled, { css } from "styled-components";
import buildColor from "@fdr/static-ui/ColorPalette";
import { fontBold } from "@fdr/static-ui/Typography";

export const LinksGroupBaseStyles = css`
  display: flex;
  flex-direction: column;
  background-color: ${buildColor("white", "100")};
  ${(props) =>
    props.device === "tablet"
      ? css`
          border: 1px solid ${buildColor("grey", "L2")};
          border-radius: 2px;
          overflow: hidden;
        `
      : css`
          border-top: 1px solid ${buildColor("grey", "L2")};
          border-bottom: 1px solid ${buildColor("grey", "L2")};
        `};

  & > li,
  & > button {
    height: 44px;
    list-style-type: none;
    align-items: center;
    position: relative;
    padding: 0 16px;
    background-color: ${buildColor("white")};

    &:active {
      background-color: ${buildColor("grey", "L4")};
    }

    &:not(:last-child) {
      &::before {
        content: "";
        position: absolute;
        height: 1px;
        width: calc(100% - 16px);
        bottom: 0;
        left: 16px;
        background: ${buildColor("grey", "L2")};
      }
    }
  }
`;

export const LinksGroupWrapper = styled.ul`
  ${LinksGroupBaseStyles}
`;

export const HeaderBaseStyles = css`
  height: 20px;
  margin: 12px 16px;
  color: ${buildColor("navy")};
  font-size: 16px;
  font-family: ${fontBold};
  line-height: 125%;
`;

export const Header = styled.div`
  ${HeaderBaseStyles};
`;
