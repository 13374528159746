// @flow

import React, { PureComponent } from "react";
import styled, { keyframes } from "styled-components";
import color from "../ColorPalette";

const pulseAnimation = keyframes`
  0% {
    fill: ${color("grey", "100")};
  }

  50% {
    fill: ${color("grey", "000")};
  }

  100% {
    fill: ${color("grey", "100")};
  }
`;

const SVG = styled.svg`
  display: block;

  rect.pulse {
    animation: ${pulseAnimation} 1.25s infinite;
  }
`;

const Container = styled.div`
  overflow-x: hidden;
  width: 100%;
  background-color: ${color("white", "100")};
`;

export default class WagerSelectorMask extends PureComponent<*> {
  render() {
    return (
      <Container>
        <SVG
          xmlns="http://www.w3.org/2000/svg"
          width="512"
          height="56"
          viewBox="0 0 512 56"
        >
          <g fill="none" fillRule="evenodd">
            <g transform="translate(432)">
              <rect className="pulse" width="80" fill="#FFF" />
              <rect
                className="pulse"
                width="60"
                height="8"
                x="10"
                y="36"
                fill="#F0F3F8"
                rx="2"
              />
              <rect
                className="pulse"
                width="32"
                height="12"
                x="10"
                y="12"
                fill="#F0F3F8"
                rx="2"
              />
            </g>
            <g transform="translate(352)">
              <rect className="pulse" width="80" fill="#FFF" />
              <rect
                className="pulse"
                width="60"
                height="8"
                x="10"
                y="36"
                fill="#F0F3F8"
                rx="2"
              />
              <rect
                className="pulse"
                width="32"
                height="12"
                x="10"
                y="12"
                fill="#F0F3F8"
                rx="2"
              />
            </g>
            <g transform="translate(240)">
              <rect className="pulse" width="112" fill="#FFF" />
              <rect
                className="pulse"
                width="88"
                height="8"
                x="12"
                y="36"
                fill="#F0F3F8"
                rx="2"
              />
              <rect
                className="pulse"
                width="56"
                height="12"
                x="12"
                y="12"
                fill="#F0F3F8"
                rx="2"
              />
            </g>
            <g transform="translate(72)">
              <rect className="pulse" width="80" fill="#FFF" />
              <rect
                className="pulse"
                width="60"
                height="8"
                x="10"
                y="36"
                fill="#F0F3F8"
                rx="2"
              />
              <rect
                className="pulse"
                width="32"
                height="12"
                x="10"
                y="12"
                fill="#F0F3F8"
                rx="2"
              />
              <g transform="translate(80)">
                <rect className="pulse" width="88" fill="#FFF" />
                <rect
                  className="pulse"
                  width="60"
                  height="8"
                  x="17"
                  y="36"
                  fill="#F0F3F8"
                  rx="2"
                />
                <rect
                  className="pulse"
                  width="32"
                  height="12"
                  x="15"
                  y="12"
                  fill="#F0F3F8"
                  rx="2"
                />
              </g>
            </g>
            <rect width="72" height="56" fill="#FFF" />
            <rect
              className="pulse"
              width="48"
              height="8"
              x="12"
              y="36"
              fill="#F0F3F8"
              rx="2"
            />
            <rect
              className="pulse"
              width="32"
              height="12"
              x="12"
              y="12"
              fill="#F0F3F8"
              rx="2"
            />
          </g>
        </SVG>
      </Container>
    );
  }
}
