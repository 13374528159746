// @flow

import mediatorChannels from "@tvg/mediator";
import { get } from "lodash";
import pushToDataLayer from "../gtmUtils";

type ResultGTMEventsType =
  | "RACE_RESULTS_SELECT_TAB"
  | "RACE_RESULTS_NEXT_RACE_CHANGE_TRACK";

type ResultGTMType = {
  type: ResultGTMEventsType,
  payload: {
    tabName: string,
    raceResults: boolean
  }
};

type ResultGtmChangeTrackNextRace = {
  type: ResultGTMEventsType,
  payload: {
    actionName: "Next Race" | "Change Track",
    destinationUrl: string
  }
};

export default () => {
  mediatorChannels.base.subscribe(
    "RACE_RESULTS_SELECT_TAB",
    (data: ResultGTMType) => {
      pushToDataLayer({
        event: "siteClick",
        gaEventCategory: "Site Click",
        gaEventAction: "Open",
        gaEventLabel: get(data, "payload.tabName"),
        module: get(data, "payload.raceResults")
          ? "PP Result Bets Closed"
          : "PP Result After Off",
        microApp: "non-Microapp"
      });
    }
  );

  mediatorChannels.base.subscribe(
    "RACE_RESULTS_NEXT_RACE_CHANGE_TRACK",
    (data: ResultGtmChangeTrackNextRace) => {
      pushToDataLayer({
        event: "navigation",
        gaEventCategory: "Navigation",
        gaEventAction: "Navigated to",
        gaEventLabel: data.payload.actionName,
        module: "Next Race",
        microApp: "non-Microapp",
        destinationUrl: data.payload.destinationUrl
      });
    }
  );
};
