// @flow

import React from "react";
import type { ReactComponentStyled } from "styled-components";
import { get, noop } from "lodash";
import Tag from "../Tag";
import {
  BettingButton,
  BettingExternalLink,
  BettingLink,
  ButtonLink,
  DestructiveButton,
  DestructiveExternalLink,
  DestructiveLink,
  FanDuelLoginButton,
  FanDuelLoginExternalLink,
  FanDuelLoginLink,
  OptedInButton,
  OptedInExternalLink,
  OptedInLink,
  PrimaryButton,
  PrimaryExternalLink,
  PrimaryLink,
  PromoButton,
  PromoExternalLink,
  PromoLink,
  SecondaryButton,
  SecondaryExternalLink,
  SecondaryLink,
  TertiaryButton,
  TertiaryExternalLink,
  TertiaryLink,
  SimpleLinkButton,
  SimpleLinkExternalLink,
  SimpleLinkLink,
  SubTabButton,
  SubTabExternalLink,
  SubTabLink,
  TabButton,
  TabExternalLink,
  TabLink,
  HeaderButton,
  HeaderLink,
  HeaderExternalLink
} from "./styled-components";
import type { Props } from "./types";

const Button = (props: Props) => {
  const renderComponent = (
    ExternalLinkComponent: ReactComponentStyled<*>,
    InternalLinkComponent: ReactComponentStyled<*>,
    ButtonComponent: ReactComponentStyled<*>
  ) =>
    (get(props, "isExternalLink", false) && (
      <ExternalLinkComponent
        $isDisabled={get(props, "isDisabled", false)}
        size={get(props, "size")}
        $borderLess={get(props, "borderLess")}
        $isNightMode={get(props, "isNightMode")}
        $paddingLess={get(props, "paddingLess")}
        $isStretched={get(props, "isStretched")}
        href={get(props, "url", "")}
        url={get(props, "url", "")}
        onClick={props.onClick}
        data-qa-label={props.qaLabel}
        target={props.newTab ? "_blank" : "_self"}
      >
        {(get(props, "children") && props.children) ||
          (get(props, "text", "") && props.text)}
      </ExternalLinkComponent>
    )) ||
    (get(props, "isInternalLink", false) && (
      <InternalLinkComponent
        $isDisabled={get(props, "isDisabled")}
        size={get(props, "size")}
        $borderLess={get(props, "borderLess")}
        $isNightMode={get(props, "isNightMode")}
        $paddingLess={get(props, "paddingLess")}
        $isStretched={get(props, "isStretched")}
        to={get(props, "url", "")}
        onClick={props.onClick}
        data-qa-label={props.qaLabel}
      >
        {(get(props, "children") && props.children) ||
          (get(props, "text", "") && props.text)}
      </InternalLinkComponent>
    )) || (
      <ButtonComponent
        $isDisabled={get(props, "isDisabled", false)}
        size={get(props, "size")}
        onClick={props.onClick}
        data-qa-label={props.qaLabel}
        $borderLess={get(props, "borderLess")}
        $isNightMode={get(props, "isNightMode")}
        $paddingLess={get(props, "paddingLess")}
        $isStretched={get(props, "isStretched")}
        renderTag={get(props, "tag.isVisible", false)}
      >
        {(get(props, "children") && props.children) ||
          (get(props, "text", "") && props.text)}
        {get(props, "tag.isVisible", false) && (
          <Tag tagType="CoupledTag" name={get(props, "tag.text")} />
        )}
      </ButtonComponent>
    );

  switch (props.type) {
    case "secondary":
      return renderComponent(
        SecondaryExternalLink,
        SecondaryLink,
        SecondaryButton
      );
    case "betting":
      return renderComponent(BettingExternalLink, BettingLink, BettingButton);
    case "destructive":
      return renderComponent(
        DestructiveExternalLink,
        DestructiveLink,
        DestructiveButton
      );
    case "fanduel":
      return renderComponent(
        FanDuelLoginExternalLink,
        FanDuelLoginLink,
        FanDuelLoginButton
      );
    case "simpleLink":
      return renderComponent(
        SimpleLinkButton,
        SimpleLinkLink,
        SimpleLinkExternalLink
      );
    case "link":
      return (
        <ButtonLink
          to={props.url}
          onClick={props.onClick}
          data-qa-label={props.qaLabel}
        >
          {(get(props, "children") && props.children) || props.text}
        </ButtonLink>
      );
    case "tab":
      return renderComponent(TabExternalLink, TabLink, TabButton);
    case "subTab":
      return renderComponent(SubTabExternalLink, SubTabLink, SubTabButton);
    case "promo":
      return renderComponent(PromoExternalLink, PromoLink, PromoButton);
    case "optedIn":
      return renderComponent(OptedInExternalLink, OptedInLink, OptedInButton);
    case "tertiary":
      return renderComponent(
        TertiaryExternalLink,
        TertiaryLink,
        TertiaryButton
      );
    case "header":
      return renderComponent(HeaderExternalLink, HeaderLink, HeaderButton);
    default:
      // $FlowFixMe
      return renderComponent(PrimaryExternalLink, PrimaryLink, PrimaryButton);
  }
};

Button.defaultProps = {
  type: "primary",
  size: "big",
  text: "",
  borderLess: false,
  paddingLess: false,
  isStretched: false,
  isDisabled: false,
  isInternalLink: false,
  isExternalLink: false,
  url: "",
  qaLabel: "",
  children: null,
  onClick: noop,
  tag: { isVisible: false, text: "" },
  newTab: false,
  isNightMode: false
};

export default Button;
