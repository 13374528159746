import thunk from "redux-thunk";
import { createStore, applyMiddleware, compose } from "redux";
import rootReducer from "./reducers/index";

// @ts-ignore
export const configureStore = ({ initialState, middleware = [] } = {}) => {
  const devtools =
    typeof window !== "undefined" &&
    // @ts-ignore
    typeof window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ === "function" &&
    // @ts-ignore
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ actionsDenylist: [] });

  const composeEnhancers = devtools || compose;

  const store = createStore(
    rootReducer,
    initialState,
    composeEnhancers(applyMiddleware(...[thunk].concat(...middleware)))
  );

  if (process.env.NODE_ENV !== "production") {
    // @ts-ignore
    if (module.hot) {
      // @ts-ignore
      module.hot.accept("./reducers", () =>
        // eslint-disable-next-line
        store.replaceReducer(require("./reducers").default)
      );
    }
  }

  return store;
};

export default configureStore;
