import React from "react";
import { LoadingMaskAnimation } from "@tvg/design-system";

const LHNMask = () => (
  <LoadingMaskAnimation>
    <svg
      width="292"
      height="948"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#a)">
        <path fill="transparent" d="M0 0h292v48H0z" />
        <path fill="#fff" fillOpacity=".2" d="M0 47h292v1H0z" />
        <rect
          x="12"
          y="18"
          width="154"
          height="12"
          rx="1"
          fill="#fff"
          fillOpacity=".1"
        />
        <path fill="transparent" d="M0 48h292v64H0z" />
        <rect
          x="56"
          y="64"
          width="224"
          height="12"
          rx="1"
          fill="#fff"
          fillOpacity=".1"
        />
        <rect
          x="56"
          y="84"
          width="224"
          height="12"
          rx="1"
          fill="#fff"
          fillOpacity=".1"
        />
        <path fill="#fff" fillOpacity=".1" d="M56 111h236v1H56z" />
        <rect
          x="12"
          y="74"
          width="32"
          height="12"
          rx="1"
          fill="#fff"
          fillOpacity=".1"
        />
        <path fill="transparent" d="M0 112h292v64H0z" />
        <rect
          x="56"
          y="128"
          width="224"
          height="12"
          rx="1"
          fill="#fff"
          fillOpacity=".1"
        />
        <rect
          x="56"
          y="148"
          width="224"
          height="12"
          rx="1"
          fill="#fff"
          fillOpacity=".1"
        />
        <path fill="#fff" fillOpacity=".1" d="M56 175h236v1H56z" />
        <rect
          x="12"
          y="138"
          width="32"
          height="12"
          rx="1"
          fill="#fff"
          fillOpacity=".1"
        />
        <path fill="transparent" d="M0 176h292v64H0z" />
        <rect
          x="56"
          y="192"
          width="224"
          height="12"
          rx="1"
          fill="#fff"
          fillOpacity=".1"
        />
        <rect
          x="56"
          y="212"
          width="224"
          height="12"
          rx="1"
          fill="#fff"
          fillOpacity=".1"
        />
        <rect
          x="12"
          y="202"
          width="32"
          height="12"
          rx="1"
          fill="#fff"
          fillOpacity=".1"
        />
        <rect
          x="56"
          y="256"
          width="224"
          height="12"
          rx="1"
          fill="#fff"
          fillOpacity=".1"
        />
        <rect
          x="56"
          y="276"
          width="224"
          height="12"
          rx="1"
          fill="#fff"
          fillOpacity=".1"
        />
        <path fill="#fff" fillOpacity=".1" d="M56 303h236v1H56z" />
        <rect
          x="12"
          y="266"
          width="32"
          height="12"
          rx="1"
          fill="#fff"
          fillOpacity=".1"
        />
        <path fill="transparent" d="M0 304h292v64H0z" />
        <rect
          x="56"
          y="320"
          width="224"
          height="12"
          rx="1"
          fill="#fff"
          fillOpacity=".1"
        />
        <rect
          x="56"
          y="340"
          width="224"
          height="12"
          rx="1"
          fill="#fff"
          fillOpacity=".1"
        />
        <path fill="#fff" fillOpacity=".1" d="M56 367h236v1H56z" />
        <rect
          x="12"
          y="330"
          width="32"
          height="12"
          rx="1"
          fill="#fff"
          fillOpacity=".1"
        />
        <path fill="transparent" d="M0 368h292v64H0z" />
        <rect
          x="56"
          y="384"
          width="224"
          height="12"
          rx="1"
          fill="#fff"
          fillOpacity=".1"
        />
        <rect
          x="56"
          y="404"
          width="224"
          height="12"
          rx="1"
          fill="#fff"
          fillOpacity=".1"
        />
        <path fill="#fff" fillOpacity=".1" d="M56 431h236v1H56z" />
        <rect
          x="12"
          y="394"
          width="32"
          height="12"
          rx="1"
          fill="#fff"
          fillOpacity=".1"
        />
        <path fill="transparent" d="M0 432h292v64H0z" />
        <rect
          x="56"
          y="448"
          width="224"
          height="12"
          rx="1"
          fill="#fff"
          fillOpacity=".1"
        />
        <rect
          x="56"
          y="468"
          width="224"
          height="12"
          rx="1"
          fill="#fff"
          fillOpacity=".1"
        />
        <path fill="#fff" fillOpacity=".1" d="M56 495h236v1H56z" />
        <rect
          x="12"
          y="458"
          width="32"
          height="12"
          rx="1"
          fill="#fff"
          fillOpacity=".1"
        />
        <path fill="transparent" d="M0 496h292v64H0z" />
        <rect
          x="56"
          y="512"
          width="224"
          height="12"
          rx="1"
          fill="#fff"
          fillOpacity=".1"
        />
        <rect
          x="56"
          y="532"
          width="224"
          height="12"
          rx="1"
          fill="#fff"
          fillOpacity=".1"
        />
        <path fill="#fff" fillOpacity=".1" d="M56 559h236v1H56z" />
        <rect
          x="12"
          y="522"
          width="32"
          height="12"
          rx="1"
          fill="#fff"
          fillOpacity=".1"
        />
        <path fill="transparent" d="M0 592h292v48H0z" />
        <path fill="#fff" fillOpacity=".2" d="M0 639h292v1H0z" />
        <rect
          x="12"
          y="610"
          width="154"
          height="12"
          rx="1"
          fill="#fff"
          fillOpacity=".1"
        />
        <path fill="transparent" d="M0 640h292v64H0z" />
        <rect
          x="56"
          y="656"
          width="224"
          height="12"
          rx="1"
          fill="#fff"
          fillOpacity=".1"
        />
        <rect
          x="56"
          y="676"
          width="224"
          height="12"
          rx="1"
          fill="#fff"
          fillOpacity=".1"
        />
        <path fill="#fff" fillOpacity=".1" d="M56 703h236v1H56z" />
        <rect
          x="12"
          y="666"
          width="32"
          height="12"
          rx="1"
          fill="#fff"
          fillOpacity=".1"
        />
        <path fill="transparent" d="M0 704h292v64H0z" />
        <rect
          x="56"
          y="720"
          width="224"
          height="12"
          rx="1"
          fill="#fff"
          fillOpacity=".1"
        />
        <rect
          x="56"
          y="740"
          width="224"
          height="12"
          rx="1"
          fill="#fff"
          fillOpacity=".1"
        />
        <path fill="#fff" fillOpacity=".1" d="M56 767h236v1H56z" />
        <rect
          x="12"
          y="730"
          width="32"
          height="12"
          rx="1"
          fill="#fff"
          fillOpacity=".1"
        />
        <path fill="transparent" d="M0 768h292v64H0z" />
        <rect
          x="56"
          y="784"
          width="224"
          height="12"
          rx="1"
          fill="#fff"
          fillOpacity=".1"
        />
        <rect
          x="56"
          y="804"
          width="224"
          height="12"
          rx="1"
          fill="#fff"
          fillOpacity=".1"
        />
        <path fill="#fff" fillOpacity=".1" d="M56 831h236v1H56z" />
        <rect
          x="12"
          y="794"
          width="32"
          height="12"
          rx="1"
          fill="#fff"
          fillOpacity=".1"
        />
        <path fill="transparent" d="M0 832h292v64H0z" />
        <rect
          x="56"
          y="848"
          width="224"
          height="12"
          rx="1"
          fill="#fff"
          fillOpacity=".1"
        />
        <rect
          x="56"
          y="868"
          width="224"
          height="12"
          rx="1"
          fill="#fff"
          fillOpacity=".1"
        />
        <path fill="#fff" fillOpacity=".1" d="M56 895h236v1H56z" />
        <rect
          x="12"
          y="858"
          width="32"
          height="12"
          rx="1"
          fill="#fff"
          fillOpacity=".1"
        />
        <path fill="transparent" d="M0 896h292v64H0z" />
        <rect
          x="56"
          y="912"
          width="224"
          height="12"
          rx="1"
          fill="#fff"
          fillOpacity=".1"
        />
        <rect
          x="56"
          y="932"
          width="224"
          height="12"
          rx="1"
          fill="#fff"
          fillOpacity=".1"
        />
        <rect
          x="12"
          y="922"
          width="32"
          height="12"
          rx="1"
          fill="#fff"
          fillOpacity=".1"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h292v1416H0z" />
        </clipPath>
      </defs>
    </svg>
  </LoadingMaskAnimation>
);

export default LHNMask;
