// @flow

import styled, { css } from "styled-components";
import buildColor from "@fdr/static-ui/ColorPalette";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 8px;
  background-color: ${buildColor("white")};
  ${(props) =>
    props.isBalanceVisible &&
    css`
      border-bottom: 1px solid ${buildColor("grey", "L2")};
    `}
`;

export const LastLogin = styled.div`
  display: flex;
  flex-direction: column-reverse;
  color: ${buildColor("grey", "D3")};
  text-align: right;
  font-size: 10px;
  line-height: 125%;
  padding-right: 16px;
  padding-left: 16px;
`;

export const ToggleWrapper = styled.div`
  ${(props) =>
    props.isCollapsed &&
    css`
      border-top: 1px solid ${buildColor("grey", "L2")};
    `}
`;
