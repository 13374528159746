import { SeoArea } from "@tvg/design-system";
import React, { memo } from "react";
import { useSelector } from "react-redux";
import { getSeoFooterContent, getSeoFooterTitle } from "../../redux/selectors";

const SeoFooter = () => {
  const title = useSelector(getSeoFooterTitle);
  const content = useSelector(getSeoFooterContent);

  return <SeoArea title={title} message={content} />;
};

export default memo(SeoFooter);
