import React, { memo } from "react";
import SectionHeader from "../SectionHeader";
import TrackGroupHeader from "../TrackGroupHeader";
import TrackRowTitle from "../TrackRowTitle";
import TrackRowContent from "../TrackRowContent";
import { Container } from "./styled-components";
import { Props } from "./types";

const TracksAZLoading = ({ numGroups = 3, numRowsPerGroup = 5 }: Props) => (
  <Container data-qa-label="tracksAZ-loadingMask">
    <SectionHeader />
    <TrackGroupHeader />
    <TrackRowTitle />
    <TrackRowContent />
    {[...Array(numGroups)].map((numGroup) => (
      <>
        <TrackGroupHeader key={numGroup} />
        {[...Array(numRowsPerGroup)].map((val) => (
          <TrackRowTitle key={val} />
        ))}
      </>
    ))}
  </Container>
);

export default memo(TracksAZLoading);
