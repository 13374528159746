// @flow

import React, {
  // $FlowFixMe
  useMemo
} from "react";
import { connect } from "react-redux";
import { type Dispatch } from "redux";
import { graphql } from "@apollo/client/react/hoc";
import { get, noop, attempt } from "lodash";
import AccountTransactions from "@fdr/atomic-ui/_templates/AccountTransactions";
import { openExternalLink } from "@tvg/sh-utils/mobileUtils";
import type { Props } from "@tvg/account-transactions/src/types";
import ApolloOptions from "@tvg/account-transactions/src/graphql/options.graph";
import AccountTransactionsQuery from "@tvg/account-transactions/src/graphql/queries/AccountTransactions.graphql";
import { getAccountNumber, getBalance } from "@urp/store-selectors";

export const onExternalLinkCallback = (url: string, event: Event): void => {
  event.preventDefault();
  event.stopPropagation();
  openExternalLink(url);
};

const AccountTransactionsComp = (props: Props) =>
  useMemo(
    () => (
      <AccountTransactions
        transactions={props.transactions}
        transactionHistoryToggle={props.transactionHistory}
        onExternalLinkCallback={onExternalLinkCallback}
        balance={props.balance}
        changePage={props.changePage}
        page={props.page}
        totalPages={props.totalPages}
        isLoading={props.isLoading}
        onClose={props.onClose}
        globalFDRSupportLink={props.globalFDRSupportLink}
      />
    ),
    [props]
  );

AccountTransactionsComp.defaultProps = {
  transactionHistory: false,
  accountId: 0,
  gasClient: noop,
  transactions: [],
  balance: 0,
  page: 1,
  totalPages: 1,
  changePage: noop,
  paymentOptions: {},
  wagerTypes: {},
  isLoading: true,
  globalFDRSupportLink: ""
};

export default connect(
  (store) => ({
    transactionHistory: get(store, "capi.featureToggles.transactionHistory"),
    paymentOptions: attempt(() =>
      JSON.parse(get(store, "capi.messages.paymentOptionsDescription"))
    ),
    wagerTypes: attempt(() =>
      JSON.parse(get(store, "capi.messages.wagerTypesFormatter"))
    ),
    accountId: getAccountNumber(store),
    balance: getBalance(store),
    globalFDRSupportLink: get(store, "capi.messages.globalFDRSupportLink", "")
  }),
  (dispatch: Dispatch<>) => ({ dispatch })
)(graphql(AccountTransactionsQuery, ApolloOptions)(AccountTransactionsComp));
