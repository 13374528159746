// @flow

import styled from "styled-components";
import buildColor from "@fdr/static-ui/ColorPalette";

export const ToggleButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  padding: 8px 16px;
  color: ${buildColor("blue")};
  font-size: 14px;
  line-height: 125%;
  width: 100%;
  cursor: pointer;
  background-color: ${buildColor("white")};

  svg {
    fill: ${buildColor("blue")};
  }
`;

export const IconAnimated = styled.div`
  transition: transform 0.2s ease-in-out;
  transform: ${(props) => (props.isOpen ? "rotate(0deg)" : "rotate(-180deg)")};
  height: 17px;
  width: 17px;
`;
