// @flow
import mediatorChannels from "@tvg/mediator";
import { get } from "lodash";
import pushToDataLayer from "../gtmUtils";

type HandicapingGTMTypeEvents =
  | "HANDICAPPING:SELECT_FAVORITE_DATA_TYPE"
  | "HANDICAPPING:SELECT_DATA_TYPE"
  | "HANDICAPPING:OPEN_MODAL"
  | "HANDICAPPING:CLOSE_MODAL"
  | "HANDICAPPING_SORT:SELECT_FAVORITE_DATA_TYPE"
  | "HANDICAPPING_SORT:SELECT_DATA_TYPE"
  | "HANDICAPPING_SORT:OPEN_MODAL"
  | "HANDICAPPING_SORT:CLOSE_MODAL";

type HandicapingGTMType = {
  type: HandicapingGTMTypeEvents,
  payload: {
    type?: string
  }
};

export default () => {
  mediatorChannels.base.subscribe(
    "HANDICAPPING:SELECT_FAVORITE_DATA_TYPE",
    (data: HandicapingGTMType) => {
      pushToDataLayer({
        event: "siteClick",
        gaEventCategory: "Site Click",
        gaEventAction: "Set Default Handicapping View",
        gaEventLabel: get(data, "payload.type"),
        module: "Handicapping PP Modal",
        tag: undefined,
        microApp: "non-Microapp"
      });
    }
  );

  mediatorChannels.base.subscribe(
    "HANDICAPPING:SELECT_DATA_TYPE",
    (data: HandicapingGTMType) => {
      pushToDataLayer({
        event: "siteClick",
        gaEventCategory: "Site Click",
        gaEventAction: "Select Handicapping View",
        gaEventLabel: get(data, "payload.type"),
        module: "Handicapping PP Modal",
        tag: undefined,
        microApp: "non-Microapp"
      });
    }
  );

  mediatorChannels.base.subscribe("HANDICAPPING:OPEN_MODAL", () => {
    pushToDataLayer({
      event: "siteClick",
      gaEventCategory: "Site Click",
      gaEventAction: "Open",
      gaEventLabel: "Handicapping PP Modal",
      module: "Race Card",
      tag: undefined,
      microApp: "non-Microapp"
    });
  });

  mediatorChannels.base.subscribe("HANDICAPPING:CLOSE_MODAL", () => {
    pushToDataLayer({
      event: "siteClick",
      gaEventCategory: "Site Click",
      gaEventAction: "Close",
      gaEventLabel: "Handicapping PP Modal",
      module: "Handicapping Selector",
      tag: undefined,
      microApp: "non-Microapp"
    });
  });

  mediatorChannels.base.subscribe(
    "HANDICAPPING_SORT:SELECT_FAVORITE_DATA_TYPE",
    (data: HandicapingGTMType) => {
      pushToDataLayer({
        event: "siteClick",
        gaEventCategory: "Site Click",
        gaEventAction: "Set Default Sort View",
        gaEventLabel: get(data, "payload.type"),
        module: "Sort Handicapping Modal",
        tag: undefined,
        microApp: "non-Microapp"
      });
    }
  );

  mediatorChannels.base.subscribe(
    "HANDICAPPING_SORT:SELECT_DATA_TYPE",
    (data: HandicapingGTMType) => {
      pushToDataLayer({
        event: "siteClick",
        gaEventCategory: "Site Click",
        gaEventAction: "Sort Handicapping View",
        gaEventLabel: get(data, "payload.type"),
        module: "Sort Handicapping Modal",
        tag: undefined,
        microApp: "non-Microapp"
      });
    }
  );

  mediatorChannels.base.subscribe("HANDICAPPING_SORT:OPEN_MODAL", () => {
    pushToDataLayer({
      event: "siteClick",
      gaEventCategory: "Site Click",
      gaEventAction: "Open",
      gaEventLabel: "Sort Handicapping Modal",
      module: "Racecard",
      tag: undefined,
      microApp: "non-Microapp"
    });
  });

  mediatorChannels.base.subscribe("HANDICAPPING_SORT:CLOSE_MODAL", () => {
    pushToDataLayer({
      event: "siteClick",
      gaEventCategory: "Site Click",
      gaEventAction: "Close",
      gaEventLabel: "Sort Handicapping Modal",
      module: "Racecard",
      tag: undefined,
      microApp: "non-Microapp"
    });
  });
};
