// @flow

import React, { PureComponent } from "react";
import styled, { keyframes } from "styled-components";
import color from "@fdr/static-ui/ColorPalette";

const pulseAnimation = keyframes`
  0% {
    fill: ${color("grey", "100")};
  }

  50% {
    fill: ${color("grey", "000")};
  }

  100% {
    fill: ${color("grey", "100")};
  }
`;

const SVG = styled.svg`
  display: block;
  width: 100%;

  rect.pulse {
    animation: ${pulseAnimation} 1.25s infinite;
  }
`;

const Container = styled.div`
  margin-bottom: 3px;
`;

export default class raceDataMask extends PureComponent<*> {
  render() {
    return (
      <Container>
        <SVG
          xmlns="http://www.w3.org/2000/svg"
          width="268"
          height="14"
          viewBox="0 0 268 8"
        >
          <rect
            className="pulse"
            width="268"
            height="14"
            x="12"
            y="149"
            fill="#294B75"
            fillRule="evenodd"
            rx="2"
            transform="translate(-12 -149)"
          />
        </SVG>
      </Container>
    );
  }
}
