import styled, { css } from "styled-components";

export const Block = styled.section`
  width: 100%;
  background-color: ${({ theme }) => theme.colorTokens.background.surface};

  :empty {
    display: none;
  }
`;

export const Section = styled(Block)`
  border: ${({ theme }) => `1px solid ${theme.colorTokens.border.default}`};
  border-left: 0;
  border-right: 0;
  padding: ${({ theme }) => theme.space["space-5"]};
`;

export const FiltersContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.space["space-5"]};
  flex: 1;
  > :first-child {
    padding: 0;
  }
`;

export const InputsWrapper = styled.div<{
  hidden: boolean;
  isAllFiltersEnabled: boolean;
}>`
  display: flex;
  justify-content: space-around;
  width: 100%;
  gap: ${({ theme }) => theme.space["space-5"]};

  > div {
    flex: 1;
  }

  ${({ isAllFiltersEnabled }) =>
    !isAllFiltersEnabled &&
    css`
      flex-wrap: wrap;
    `};
  ${({ hidden }) =>
    hidden &&
    css`
      visibility: hidden;
    `}
`;
