import { useContext, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useQuery } from "@apollo/client";
import type { ApolloClient, NormalizedCacheObject } from "@apollo/client";
import { format } from "date-fns";
import { get } from "lodash";

import ApolloContext from "@tvg/utils/apolloContext";
import { getAccountNumber, getIsLogged } from "@urp/store-selectors";
import {
  setActiveBetsInfo,
  setActiveWagerAmountMyBetsStandalone,
  setCounterMyBetsStandalone,
  setFutureCounterMyBetsStandalone,
  setFutureWageredAmountMyBetsStandalone,
  setRacesActiveBets,
  setRacesActiveBetsStandalone,
  setSelectedTabMyBetsStandalone
} from "../redux/actions";
import {
  getActiveWageredAmountCounter,
  getFutureWageredAmountCounter,
  getIsMyBetsOpen,
  getRedirectWithoutActiveBetsToggle,
  getTodayActiveCounter,
  getTodayFutureCounter
} from "../redux/selectors";
import { ActiveTabEnum, WagerHistoryProps } from "../utils/types";

// @ts-ignore
import GET_ACTIVE_BETS from "../graphql/queries/activeBetsQuery.graphql";
// @ts-ignore
import SUBSCRIBE_ACTIVE_BETS from "../graphql/subscriptions/activeBetsSubscription.graphql";
import { getRacesActiveBets } from "../graphql/optionsActive.graph";

const useActiveBets = (enableSubscription: boolean = true) => {
  // SELECTORS
  const accountNumber = useSelector(getAccountNumber);
  const isLogged = useSelector(getIsLogged);
  const totalActiveBets = useSelector(getTodayActiveCounter);
  const activeWageredAmount = useSelector(getActiveWageredAmountCounter);
  const totalFutureBets = useSelector(getTodayFutureCounter);
  const futureWageredAmount = useSelector(getFutureWageredAmountCounter);
  const shouldRedirectWithoutActiveBets = useSelector(
    getRedirectWithoutActiveBetsToggle
  );
  const isMyBetsOpen = useSelector(getIsMyBetsOpen);

  const dispatch = useDispatch();

  const date = format(new Date(), "yyyy-MM-dd");

  const { behgClient } =
    useContext<{ behgClient?: ApolloClient<NormalizedCacheObject> }>(
      ApolloContext
    );
  const { loading, error, subscribeToMore, data } = useQuery<WagerHistoryProps>(
    GET_ACTIVE_BETS,
    {
      fetchPolicy: "cache-and-network",
      nextFetchPolicy: "cache-first",
      client: behgClient,
      ssr: false,
      skip: !accountNumber || !isLogged,
      variables: {
        startDate: date,
        endDate: date,
        accountId: parseInt(accountNumber, 10) || 0,
        status: ["PENDING"]
      }
    }
  );

  const racesActiveBets = useMemo(
    () => (data && !error ? getRacesActiveBets(data?.wagerHistory) : {}),
    [data]
  );

  useEffect(() => {
    if (data && !error && racesActiveBets) {
      const { wagerHistory } = data;
      const totalActiveBetsRes = get(wagerHistory, "totals.totalBets", 0);
      const activeWageredAmountRes = get(
        wagerHistory,
        "totals.totalAmount",
        50
      );
      const totalFutureBetsRes = get(
        wagerHistory,
        "futureWagersList.totals.totalCount",
        0
      );
      const futureWageredAmountRes = get(
        wagerHistory,
        "futureWagersList.totals.totalAmount",
        0
      );

      // MyBets actions
      dispatch(
        setActiveBetsInfo({
          totalBets: totalActiveBetsRes,
          totalAmount: activeWageredAmountRes
        })
      );
      dispatch(setRacesActiveBets(racesActiveBets));

      // MyBetsStandalone actions
      dispatch(setRacesActiveBetsStandalone(racesActiveBets));
      dispatch(setCounterMyBetsStandalone(totalActiveBetsRes));
      dispatch(setActiveWagerAmountMyBetsStandalone(activeWageredAmountRes));
      dispatch(setFutureCounterMyBetsStandalone(totalFutureBetsRes));
      dispatch(setFutureWageredAmountMyBetsStandalone(futureWageredAmountRes));
    }
  }, [data, racesActiveBets]);

  useEffect(() => {
    if (!!subscribeToMore && !!accountNumber && enableSubscription) {
      const unsubscribe = subscribeToMore({
        variables: {
          accountId: parseInt(accountNumber, 10),
          status: ["PENDING"]
        },
        document: SUBSCRIBE_ACTIVE_BETS
      });

      return () => {
        unsubscribe();
      };
    }

    return undefined;
  }, [subscribeToMore, accountNumber]);

  useEffect(() => {
    if (shouldRedirectWithoutActiveBets && isMyBetsOpen) {
      if (totalActiveBets > 0) {
        dispatch(setSelectedTabMyBetsStandalone(ActiveTabEnum.ACTIVE));
      } else {
        dispatch(setSelectedTabMyBetsStandalone(ActiveTabEnum.SETTLED));
      }
    }
  }, [totalActiveBets, isMyBetsOpen]);

  // If user logs off, set everything to default
  useEffect(() => {
    if (!isLogged || !accountNumber) {
      // MyBets actions
      dispatch(
        setActiveBetsInfo({
          totalBets: 0,
          totalAmount: 0
        })
      );
      dispatch(setRacesActiveBets({}));

      // MyBetsStandalone actions
      dispatch(setCounterMyBetsStandalone(0));
      dispatch(setActiveWagerAmountMyBetsStandalone(0));
      dispatch(setFutureCounterMyBetsStandalone(0));
      dispatch(setFutureWageredAmountMyBetsStandalone(0));
      dispatch(setRacesActiveBetsStandalone({}));
    }
  }, [isLogged, accountNumber]);

  return {
    racesActiveBets,
    totalActiveBets,
    activeWageredAmount,
    totalFutureBets,
    futureWageredAmount,
    loading,
    error
  };
};

export default useActiveBets;
