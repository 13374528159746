// @flow
import styled from "styled-components";
import buildColor from "@fdr/static-ui/ColorPalette";
import { rgba } from "polished";

export const Switch = styled.label`
  width: 51px;
  height: 31px;
  background-color: ${(props) =>
    props.isOn ? buildColor("green", "500") : buildColor("grey")};
  border-radius: 31px;
  display: flex;
  padding: 2px;
  position: relative;
  cursor: pointer;
  transition: background-color ease 0.3s;

  > input {
    display: none;
  }
`;

export const Slider = styled.span`
  width: 27px;
  height: 27px;
  background-color: ${buildColor("white", "100")};
  border-radius: 50%;
  position: absolute;
  transition: left ease 0.3s;
  left: ${(props) => (props.isOn ? "20px" : "3px")};
  box-shadow: 0 0 2px ${rgba(buildColor("black", "100"), 0.05)},
    0 2px 1px ${rgba(buildColor("black", "100"), 0.0510643)},
    0 2px 2px ${rgba(buildColor("black", "100"), 0.1)},
    0 2px 2px ${rgba(buildColor("black", "100"), 0.05)};
`;
