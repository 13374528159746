import { SetStateAction, useState, Dispatch } from "react";
import type { Location } from "react-router-dom";
import { get } from "lodash";
import { useLocation } from "react-router";
import { useSelector } from "react-redux";
import tvgConf from "@tvg/conf";
import mediator from "@tvg/mediator";
import { useIsomorphicLayoutEffect } from "@tvg/custom-hooks";
import {
  getAccountNumber,
  getBalance,
  getIsLogged,
  getResidenceState
} from "@urp/store-selectors";
import { getReturningUser } from "../store/selectors/general";

const sendPageEvents = (
  lastRouteEntered: string | null,
  accountNumber: string,
  isLogged: boolean,
  balance: number,
  privateBrowser: string | null,
  homeState: string,
  registrationStatus: string | null,
  isContentCardsInboxShown: boolean,
  promoCode: string | null | undefined,
  sport: string | null,
  returningUser: boolean,
  location: Location,
  setLastRouteEntered: Dispatch<SetStateAction<string | null>>,
  overrideRouteChange?: boolean
) => {
  if (location.pathname !== lastRouteEntered || overrideRouteChange) {
    mediator.base.dispatch({
      type: "PAGE_VIEW",
      payload: {
        siteVersion: tvgConf().gaConfig().siteVersion,
        productVersion: tvgConf().gaConfig().productVersion,
        state: homeState,
        accountId: accountNumber || undefined,
        logged: isLogged ? "Logged In" : "Logged Out",
        registrationStatus,
        page:
          get(location, "pathname") +
          get(location, "search", "") +
          get(location, "hash", ""),
        sport,
        privateBrowser,
        graphVersion: "v2",
        balance: isLogged ? balance : undefined,
        ...(isContentCardsInboxShown ? { promoCode } : {}),
        isReturningUser:
          returningUser || (isLogged && homeState && accountNumber && balance)
      }
    });
  }

  setLastRouteEntered(location.pathname);
};

const usePageViewEventDispatch = (
  options = {
    dispatchOnLocationChange: true
  }
) => {
  const [waitForUserInfo, setWaitForUserInfo] = useState(false);
  const [lastRouteEntered, setLastRouteEntered] = useState<string | null>(null);
  const location = useLocation();
  const isLogged = useSelector(getIsLogged);
  const homeState = useSelector(getResidenceState);
  const accountNumber = useSelector(getAccountNumber);
  const balance = useSelector(getBalance);
  const returningUser = useSelector(getReturningUser);
  const storageUserInfo =
    typeof window !== "undefined" ? sessionStorage.getItem("userId") : null;
  const isNotOnProgramPage =
    !location.pathname.includes("racetracks") ||
    (location.pathname.includes("racetracks") && location.search === "");
  const isNotOnLiveTv = !location.pathname.startsWith("/live");

  let registrationStatus: string | null = null;
  let privateBrowser: string | null = null;
  let promoCode: string | null | undefined;
  let sport: string | null;
  let isContentCardsInboxShown: boolean = false;

  if (typeof window !== "undefined") {
    registrationStatus = localStorage.getItem("userLoginOnce")
      ? "Registered"
      : "Unregistered";

    privateBrowser =
      localStorage.getItem("privateMode") === "true" ? "Yes" : "No";

    if (location.pathname.includes("racetracks")) {
      sport = "Horse Racing";
    } else if (location.pathname.includes("greyhounds")) {
      sport = "Greyhounds Racing";
    }

    isContentCardsInboxShown =
      location &&
      location.pathname.indexOf("/racetracks") !== 0 &&
      location.pathname.indexOf("/live") !== 0;

    if (isContentCardsInboxShown) {
      promoCode = localStorage.getItem("promoCode");

      if (!promoCode) {
        promoCode = undefined;
      } else {
        localStorage.removeItem("promoCode");
      }
    }
  }

  useIsomorphicLayoutEffect(() => {
    if (storageUserInfo && (!isLogged || !accountNumber)) {
      setWaitForUserInfo(true);
    }
  }, []);

  const dispatchEvent = () =>
    isNotOnProgramPage &&
    isNotOnLiveTv &&
    sendPageEvents(
      lastRouteEntered,
      accountNumber,
      isLogged,
      balance,
      privateBrowser,
      homeState,
      registrationStatus,
      isContentCardsInboxShown,
      promoCode,
      sport,
      returningUser,
      location,
      setLastRouteEntered
    );

  useIsomorphicLayoutEffect(() => {
    if (
      options.dispatchOnLocationChange &&
      (!storageUserInfo || (storageUserInfo && isLogged && accountNumber))
    ) {
      dispatchEvent();
    } else {
      setLastRouteEntered("");
    }
  }, [options.dispatchOnLocationChange, location.pathname]);

  useIsomorphicLayoutEffect(() => {
    if (waitForUserInfo && balance && homeState && accountNumber && isLogged) {
      dispatchEvent();
      setWaitForUserInfo(false);
    }
  }, [waitForUserInfo, balance, homeState, accountNumber, isLogged]);

  return { dispatchEvent };
};

export default usePageViewEventDispatch;
