import { RaceTypeCodeEnum } from "@tvg/ts-types/Race";
import {
  RaceTracksFilterActionTypes,
  ActionType
} from "../actions/raceTracksFilter";
import { TrackFilterValueEnum } from "../../types";

export interface RaceTracksFilterState {
  raceTypes: RaceTypeCodeEnum[];
  regions: string[];
  trackTypes: TrackFilterValueEnum[];
  races: string[];
  distances: string[];
  tempState: RaceTracksFilterState | null;
}

export const initialState: RaceTracksFilterState = {
  raceTypes: [],
  regions: [],
  trackTypes: [],
  races: [],
  distances: [],
  tempState: null
};

const raceTracksFilterReducer = (
  state = initialState,
  action: RaceTracksFilterActionTypes
): RaceTracksFilterState => {
  switch (action.type) {
    case ActionType.RACE_TYPE_FILTER:
      return { ...state, raceTypes: action.payload };

    case ActionType.REGION_FILTER:
      return { ...state, regions: action.payload };
    case ActionType.DISTANCE_FILTER:
      return { ...state, distances: action.payload };
    case ActionType.RACE_FILTER:
      return { ...state, races: action.payload };
    case ActionType.TRACK_TYPE_FILTER:
      return { ...state, trackTypes: action.payload };
    case ActionType.CLEAR_FILTERS:
      return initialState;
    case ActionType.START_SEARCH_MODE:
      return { ...initialState, tempState: state };
    case ActionType.END_SEARCH_MODE:
      return state.tempState ? state.tempState : initialState;
    default:
      return state;
  }
};

export default raceTracksFilterReducer;
