// @flow
import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import buildColor from "@fdr/static-ui/ColorPalette";
import { buildText } from "../../_atoms/Text";
import Tag from "../../_atoms/Tag";

export const AccountLinkBaseStyles = css`
  display: flex;
  align-items: center;
  text-decoration: none;
  width: 100%;
  height: 100%;

  &[disabled] {
    pointer-events: none;
    cursor: default;

    & > span {
      color: ${buildColor("grey", "D4")};
    }
  }

  > svg {
    &:first-child {
      margin-right: 12px;
    }

    &:last-child {
      margin-left: auto;
    }
  }
`;

export const AccountLinkGroup = styled(Link)`
  ${AccountLinkBaseStyles};

  &:focus {
    outline: none;
  }

  &::-moz-focus-inner {
    border: 0;
  }
`;

AccountLinkGroup.displayName = "AccountLinkGroup";

export const AccountExternalLink = styled.a`
  ${AccountLinkBaseStyles};
`;

AccountExternalLink.displayName = "AccountExternalLink";

const LinkTextStyles = buildText({
  tag: "span",
  fontSize: 14,
  color: buildColor("blue", "D1"),
  qaLabel: "account-link-text"
});

export const LinkText = styled(LinkTextStyles).attrs(({ qaLabel }) => ({
  "data-qa-label": qaLabel
}))`
  line-height: 125%;
  display: flex;
  align-items: center;
`;

export const AccountHighlightedText = styled(Tag)`
  margin-left: 8px;
`;
