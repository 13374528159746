// @flow
// $FlowFixMe
import React, { memo } from "react";
import formatCurrency from "@tvg/formatter/currency";
import type { UnaryFn } from "@tvg/types/Functional";
import Icon from "@fdr/static-ui/Icons";
import { eyeShow, eyeHide } from "@fdr/static-ui/Icons/icons";
import Button from "../../_atoms/Button";
import Label from "../../_atoms/Label";
import {
  AccountBalancesSummaryWrapper,
  Balances,
  BalanceItem,
  BalanceValue,
  ShowLabel,
  ToggleVisibility
} from "./styled-components";

export type Balance = {
  label: string,
  value: number
};

type Props = {
  balances: Balance[],
  isVisible: boolean,
  onToggle: UnaryFn<SyntheticEvent<HTMLButtonElement>, void>,
  qaLabel?: string
};

export const AccountBalancesSummary = memo(
  ({
    balances = [],
    isVisible,
    onToggle,
    qaLabel = "account-balance-summary"
  }: Props) => {
    return (
      <AccountBalancesSummaryWrapper
        isVisible={isVisible}
        data-qa-label={qaLabel}
      >
        {isVisible && (
          <Balances data-qa-label={`${qaLabel}-balances`}>
            {balances.map((balance) => {
              const balanceQaLabel = `${qaLabel}-${balance.label
                .toLowerCase()
                .trim()
                .replace(/ /g, "-")}`;

              return (
                <BalanceItem data-qa-label={balanceQaLabel} key={balance.label}>
                  <BalanceValue data-qa-label={`${balanceQaLabel}-value`}>
                    {formatCurrency(balance.value)}
                  </BalanceValue>
                  <Label qaLabel={`${balanceQaLabel}-text`}>
                    {balance.label}
                  </Label>
                </BalanceItem>
              );
            })}
          </Balances>
        )}

        <ToggleVisibility isVisible={isVisible}>
          <Button
            type="secondary"
            size="medium"
            onClick={onToggle}
            qaLabel={`${qaLabel}-toggle-hide`}
          >
            <Icon icon={eyeHide} size={16} qaLabel={`${qaLabel}-hide`} />
          </Button>
        </ToggleVisibility>

        <ToggleVisibility isVisible={!isVisible} isStretched>
          <Button
            type="secondary"
            size="medium"
            onClick={onToggle}
            qaLabel={`${qaLabel}-toggle-show`}
            isStretched
          >
            <ShowLabel>
              <Icon icon={eyeShow} size={16} qaLabel={`${qaLabel}-show`} />
              <span>Show</span>
            </ShowLabel>
          </Button>
        </ToggleVisibility>
      </AccountBalancesSummaryWrapper>
    );
  }
);

export default AccountBalancesSummary;
