// @flow
import mediatorChannels from "@tvg/mediator";
import pushToDataLayer from "../gtmUtils";

export default () => {
  mediatorChannels.base.subscribe("FOOTER_CLICK_HOME", (data): void => {
    pushToDataLayer({
      event: "navigation",
      gaEventCategory: "Navigation",
      gaEventAction: "Navigated to",
      gaEventLabel: "Home",
      menu: undefined,
      module: "Footer",
      sport: undefined,
      microApp: "non-Microapp",
      tag: undefined,
      destinationUrl: data.payload.destinationUrl
    });
  });

  mediatorChannels.base.subscribe("FOOTER_CLICK_TRACKS", (data): void => {
    pushToDataLayer({
      event: "navigation",
      gaEventCategory: "Navigation",
      gaEventAction: "Navigated to",
      gaEventLabel: "Tracks",
      menu: undefined,
      module: "Footer",
      sport: undefined,
      microApp: "non-Microapp",
      tag: undefined,
      destinationUrl: data.payload.destinationUrl
    });
  });

  mediatorChannels.base.subscribe("FOOTER_CLICK_LIVE", (data): void => {
    pushToDataLayer({
      event: "navigation",
      gaEventCategory: "Navigation",
      gaEventAction: "Navigated to",
      gaEventLabel: "TVGTV",
      menu: undefined,
      module: "Footer",
      sport: undefined,
      microApp: "non-Microapp",
      tag: undefined,
      destinationUrl: data.payload.destinationUrl
    });
  });

  mediatorChannels.base.subscribe("FOOTER_CLICK_PROMOS", (data): void => {
    pushToDataLayer({
      event: "navigation",
      gaEventCategory: "Navigation",
      gaEventAction: "Navigated to",
      gaEventLabel: "Promos Modal",
      menu: undefined,
      module: "Footer",
      sport: undefined,
      microApp: "non-Microapp",
      tag: undefined,
      destinationUrl: data.payload.destinationUrl
    });
  });

  mediatorChannels.base.subscribe("FOOTER_CLICK_BETS", (data): void => {
    pushToDataLayer({
      event: "navigation",
      gaEventCategory: "Navigation",
      gaEventAction: "Navigated to",
      gaEventLabel: "My Bets Modal",
      menu: undefined,
      module: "Footer",
      sport: undefined,
      microApp: "non-Microapp",
      tag: undefined,
      destinationUrl: data.payload.destinationUrl
    });
  });

  mediatorChannels.base.subscribe("FOOTER_CLICK_MORE", (data): void => {
    pushToDataLayer({
      event: "navigation",
      gaEventCategory: "Navigation",
      gaEventAction: "Navigated to",
      gaEventLabel: "More",
      menu: undefined,
      module: "Footer",
      sport: undefined,
      microApp: "non-Microapp",
      tag: undefined,
      destinationUrl: data.payload.destinationUrl
    });
  });

  mediatorChannels.base.subscribe("FOOTER_CLICK_ACCOUNT", (data): void => {
    pushToDataLayer({
      event: "navigation",
      gaEventCategory: "Navigation",
      gaEventAction: "Navigated to",
      gaEventLabel: "Account",
      menu: undefined,
      module: "Footer",
      sport: undefined,
      microApp: "non-Microapp",
      tag: undefined,
      destinationUrl: data.payload.destinationUrl
    });
  });

  mediatorChannels.base.subscribe("FOOTER_CLICK_PICKS", (data): void => {
    pushToDataLayer({
      event: "navigation",
      gaEventCategory: "Navigation",
      gaEventAction: "Navigated to",
      gaEventLabel: "Talent Picks",
      menu: undefined,
      module: "Footer",
      sport: undefined,
      microApp: "non-Microapp",
      tag: undefined,
      destinationUrl: data.payload.destinationUrl
    });
  });

  mediatorChannels.base.subscribe("FOOTER_CLICK_LINKS", (data): void => {
    pushToDataLayer({
      event: "navigation",
      gaEventCategory: "Navigation",
      gaEventAction: "Footer Navigation Link Clicked",
      gaEventLabel: data.payload.linkName,
      module: "Footer",
      destinationUrl: data.payload.destinationUrl,
      tag: undefined,
      menu: undefined
    });
  });
};
