const menuItems = [
  {
    name: "Racing",
    route: "/races",
    options: {
      greyhoundsName: "Horse Racing"
    },
    subMenus: [
      {
        name: "Racing Schedule",
        description: "Today’s racing from across the world",
        route: "/races",
        icon: "horse",
        subQaLabel: "RacingSchedule_ListItem-FindOutMoreBtn"
      },
      {
        name: "Racing Results",
        description: "All results and payouts",
        route: "/results",
        icon: "result",
        subQaLabel: "RacingResults_ListItem-FindOutMoreBtn"
      },
      {
        name: "Track Information List",
        description: "Information for every track we race",
        route: "/racetracks",
        icon: "tracks",
        subQaLabel: "TrackInformationList_ListItem-FindOutMoreBtn"
      }
    ],
    qaLabel: "racingButton"
  },
  {
    name: "Handicapping",
    route: "/handicapping/handicapping-store",
    subMenus: [
      {
        name: "Handicapping Store",
        description: "Buy PP’s from DRF, Equibase, Trackmaster and more",
        route: "/handicapping/handicapping-store",
        icon: "store",
        subQaLabel: "HandicappingStore_ListItem-FindOutMoreBtn"
      },
      {
        name: "Free Past Performances",
        description: "Perfect opportunity to improve your chances",
        route: "/handicapping/free-pp",
        icon: "fileDownload",
        subQaLabel: "FreePastPerformances_ListItem-FindOutMoreBtn"
      },
      {
        name: "Race Replays",
        description: "Watch the replays from resulted races",
        route: "/handicapping/race-replays",
        icon: "replay",
        subQaLabel: "RaceReplays_ListItem-FindOutMoreBtn"
      },
      {
        name: "TVG Picks",
        description: "Unsure what to bet? Our expert team can help",
        route: "/handicapping/talent-picks",
        icon: "tvgPicks",
        subQaLabel: "TVGPicks_ListItem-FindOutMoreBtn"
      },
      {
        name: "Racing News",
        description: "Get the latest news and race previews",
        route: "https://news.tvg.com/",
        icon: "edit",
        subQaLabel: "RacingNews_ListItem-FindOutMoreBtn",
        options: {
          target: "newWindow"
        }
      },
      {
        name: "Horse Racing 101",
        description: "Get to know the basics",
        route: "/promos/horse-racing-101/",
        icon: "infoOutline",
        subQaLabel: "HorseRacing101_ListItem-FindOutMoreBtn",
        options: {
          target: "newWindow"
        }
      },

      {
        name: "Horse Betting Guide",
        description: "How to bet like the experts",
        route: "/promos/horse-racing-betting-guide/",
        icon: "infoOutline",
        subQaLabel: "HorseBettingGuide_ListItem-FindOutMoreBtn",
        options: {
          target: "newWindow"
        }
      }
    ],
    qaLabel: "handicappingButton"
  },
  {
    name: "Promotions",
    route:
      "/promos/?utm_source=fullsite&utm_medium=headerlink&utm_campaign=Promos%20Header%20Link",
    subMenus: [
      {
        name: "Live Promotions",
        description: "Check out our latest and greatest promotions",
        route:
          "/promos/?utm_source=fullsite&utm_medium=headerlink&utm_campaign=Promos%20Header%20Link",
        icon: "promo",
        subQaLabel: "LivePromotions_ListItem-FindOutMoreBtn"
      },
      {
        name: "Wager Rewards",
        description: "Turn wagering points into cash!",
        route: "/wager-rewards",
        options: {
          brandsException: ["pabets"],
          profileTypeException: ["PORT-PA"]
        },
        icon: "cup",
        subQaLabel: "WagerRewards_ListItem-FindOutMoreBtn"
      },
      {
        name: "Refer Friends & Get a Bonus",
        description: "Share the excitement of Racing with your friends!",
        route: "/referral",
        icon: "referFriend",
        subQaLabel: "ReferFriends&GetaBonus_ListItem-FindOutMoreBtn"
      }
    ],
    qaLabel: "promotionsButton"
  }
];

export default menuItems;
