// @flow

import React, { PureComponent } from "react";
import styled, { keyframes } from "styled-components";
import color from "../ColorPalette";

const pulseAnimation = keyframes`
  0% {
    fill: ${color("grey", "100")};
  }

  50% {
    fill: ${color("grey", "000")};
  }

  100% {
    fill: ${color("grey", "100")};
  }
`;

const SVG = styled.svg`
  display: block;

  rect.pulse {
    animation: ${pulseAnimation} 1.25s infinite;
  }
`;

const Container = styled.div`
  overflow: hidden;
  width: 100%;
`;

export default class RacecardMask extends PureComponent<*> {
  render() {
    return (
      <Container>
        <SVG
          xmlns="http://www.w3.org/2000/svg"
          width="1024"
          height="778"
          viewBox="0 0 1024 778"
        >
          <g fill="none" fillRule="evenodd">
            <g transform="translate(0 694)">
              <rect width="1024" height="84" fill="#FFF" />
              <rect
                className="pulse"
                width="1024"
                height="1"
                x="55"
                y="83"
                fill="#F0F3F8"
              />
              <rect
                className="pulse"
                width="96"
                height="8"
                x="55"
                y="38"
                fill="#F0F3F8"
                rx="2"
              />
              <rect
                className="pulse"
                width="72"
                height="8"
                x="55"
                y="58"
                fill="#F0F3F8"
                rx="2"
              />
              <rect
                className="pulse"
                width="128"
                height="12"
                x="55"
                y="14"
                fill="#F0F3F8"
                rx="2"
              />
            </g>
            <g transform="translate(0 610)">
              <rect width="1024" height="84" fill="#FFF" />
              <rect width="1024" height="1" x="55" y="83" fill="#F0F3F8" />
              <rect
                className="pulse"
                width="72"
                height="8"
                x="55"
                y="58"
                fill="#F0F3F8"
                rx="2"
              />
              <rect
                className="pulse"
                width="64"
                height="8"
                x="55"
                y="38"
                fill="#F0F3F8"
                rx="2"
              />
              <rect
                className="pulse"
                width="88"
                height="12"
                x="55"
                y="14"
                fill="#F0F3F8"
                rx="2"
              />
            </g>
            <g transform="translate(0 526)">
              <rect width="1024" height="84" fill="#FFF" />
              <rect width="1024" height="1" x="55" y="83" fill="#F0F3F8" />
              <rect
                className="pulse"
                width="72"
                height="8"
                x="55"
                y="58"
                fill="#F0F3F8"
                rx="2"
              />
              <rect
                className="pulse"
                width="64"
                height="8"
                x="55"
                y="38"
                fill="#F0F3F8"
                rx="2"
              />
              <rect
                className="pulse"
                width="88"
                height="12"
                x="55"
                y="14"
                fill="#F0F3F8"
                rx="2"
              />
            </g>
            <g transform="translate(0 442)">
              <rect width="1024" height="84" fill="#FFF" />
              <rect width="1024" height="1" x="55" y="83" fill="#F0F3F8" />
              <rect
                className="pulse"
                width="96"
                height="8"
                x="55"
                y="38"
                fill="#F0F3F8"
                rx="2"
              />
              <rect
                className="pulse"
                width="72"
                height="8"
                x="55"
                y="58"
                fill="#F0F3F8"
                rx="2"
              />
              <rect
                className="pulse"
                width="128"
                height="12"
                x="55"
                y="14"
                fill="#F0F3F8"
                rx="2"
              />
            </g>
            <g transform="translate(0 358)">
              <rect width="1024" height="84" fill="#FFF" />
              <rect width="1024" height="1" x="55" y="83" fill="#F0F3F8" />
              <rect
                className="pulse"
                width="72"
                height="8"
                x="55"
                y="58"
                fill="#F0F3F8"
                rx="2"
              />
              <rect
                className="pulse"
                width="64"
                height="8"
                x="55"
                y="38"
                fill="#F0F3F8"
                rx="2"
              />
              <rect
                className="pulse"
                width="88"
                height="12"
                x="55"
                y="14"
                fill="#F0F3F8"
                rx="2"
              />
            </g>
            <g transform="translate(0 274)">
              <rect width="1024" height="84" fill="#FFF" />
              <rect width="1024" height="1" x="55" y="83" fill="#F0F3F8" />
              <rect
                className="pulse"
                width="72"
                height="8"
                x="55"
                y="58"
                fill="#F0F3F8"
                rx="2"
              />
              <rect
                className="pulse"
                width="64"
                height="8"
                x="55"
                y="38"
                fill="#F0F3F8"
                rx="2"
              />
              <rect
                className="pulse"
                width="88"
                height="12"
                x="55"
                y="14"
                fill="#F0F3F8"
                rx="2"
              />
            </g>
            <g transform="translate(0 190)">
              <rect width="1024" height="84" fill="#FFF" />
              <rect width="1024" height="1" x="55" y="83" fill="#F0F3F8" />
              <rect
                className="pulse"
                width="72"
                height="8"
                x="55"
                y="58"
                fill="#F0F3F8"
                rx="2"
              />
              <rect
                className="pulse"
                width="96"
                height="8"
                x="55"
                y="38"
                fill="#F0F3F8"
                rx="2"
              />
              <rect
                className="pulse"
                width="128"
                height="12"
                x="55"
                y="14"
                fill="#F0F3F8"
                rx="2"
              />
            </g>
            <g transform="translate(0 107)">
              <rect width="1024" height="84" fill="#FFF" />
              <rect width="1024" height="1" x="55" y="83" fill="#F0F3F8" />
              <rect
                className="pulse"
                width="72"
                height="8"
                x="55"
                y="58"
                fill="#F0F3F8"
                rx="2"
              />
              <rect
                className="pulse"
                width="64"
                height="8"
                x="55"
                y="38"
                fill="#F0F3F8"
                rx="2"
              />
              <rect
                className="pulse"
                width="88"
                height="12"
                x="55"
                y="14"
                fill="#F0F3F8"
                rx="2"
              />
            </g>
            <rect width="1024" height="107" fill="#FFF" />
            <rect width="1024" height="1" x="55" y="106" fill="#F0F3F8" />
            <rect
              className="pulse"
              width="104"
              height="8"
              x="55"
              y="82"
              fill="#F0F3F8"
              rx="2"
            />
            <rect
              className="pulse"
              width="72"
              height="8"
              x="55"
              y="58"
              fill="#F0F3F8"
              rx="2"
            />
            <rect
              className="pulse"
              width="64"
              height="8"
              x="55"
              y="38"
              fill="#F0F3F8"
              rx="2"
            />
            <rect
              className="pulse"
              width="88"
              height="12"
              x="55"
              y="14"
              fill="#F0F3F8"
              rx="2"
            />
          </g>
        </SVG>
      </Container>
    );
  }
}
