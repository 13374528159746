// @flow
import mediatorChannels from "@tvg/mediator";
import { get } from "lodash";
import pushToDataLayer from "../gtmUtils";

type VideoGTMEventsType =
  | "VIDEO:OPEN"
  | "VIDEO:CLOSE"
  | "VIDEO:MUTE"
  | "VIDEO:FULL_SCREEN"
  | "VIDEO:PLAY_VIDEO"
  | "VIDEO:STOP_VIDEO"
  | "VIDEO:SUCCESS"
  | "VIDEO:SWITCH_STREAMS"
  | "VIDEO:NAVIGATE"
  | "VIDEO:ERROR";

type VideoOpenCloseGTM = {
  type: VideoGTMEventsType,
  payload: {
    module: string
  }
};

type VideoGTM = {
  type: VideoGTMEventsType,
  payload: {
    error?: string,
    trackName: string,
    raceNumber: string,
    runnerType: string,
    videoType: string, // e.g 'Live Stream' or 'Live Video' or 'Race Replay'
    videoProvider: string, // e.g 'RCN'
    videoType: "Live" | "Replay"
  }
};

export default () => {
  mediatorChannels.base.subscribe(
    "VIDEO:OPEN",
    (data: VideoOpenCloseGTM): void => {
      pushToDataLayer({
        event: "video",
        gaEventAction: "Open",
        gaEventCategory: "Video",
        gaEventLabel: "Video",
        microApp: "non-Microapp",
        module: get(data, "payload.module"),
        raceNumber: get(data, "payload.raceNumber"),
        sport: "Horse Racing",
        trackName: get(data, "payload.trackName"),
        videoPosition: undefined,
        videoProvider: get(data, "payload.videoProvider"),
        videoType: get(data, "payload.videoType")
      });
    }
  );

  mediatorChannels.base.subscribe(
    "VIDEO:CLOSE",
    (data: VideoOpenCloseGTM): void => {
      pushToDataLayer({
        event: "video",
        gaEventAction: "Close",
        gaEventCategory: "Video",
        gaEventLabel: "Video",
        microApp: "non-Microapp",
        module: get(data, "payload.module"),
        raceNumber: get(data, "payload.raceNumber"),
        sport: "Horse Racing",
        trackName: get(data, "payload.trackName"),
        videoPosition: undefined,
        videoProvider: get(data, "payload.videoProvider"),
        videoType: get(data, "payload.videoType")
      });
    }
  );

  mediatorChannels.base.subscribe("VIDEO:MUTE", (data: VideoGTM): void => {
    pushToDataLayer({
      event: "video",
      gaEventCategory: "Video",
      gaEventAction: `${
        get(data, "payload.muted", true) ? "Mute" : "Unmute"
      } Video`,
      gaEventLabel: undefined,
      trackName: get(data, "payload.trackName"),
      raceNumber: get(data, "payload.raceNumber"),
      sport: `${get(data, "payload.runnerType")} Racing`,
      module: "Video",
      videoType: get(data, "payload.videoType"),
      videoPosition: undefined,
      videoProvider: get(data, "payload.videoProvider")
    });
  });

  mediatorChannels.base.subscribe(
    "VIDEO:FULL_SCREEN",
    (data: VideoGTM): void => {
      pushToDataLayer({
        event: "video",
        gaEventCategory: "Video",
        gaEventAction: `${
          get(data, "payload.fullScreen", true) ? "Open" : "Close"
        } Full Screen from ${
          get(data, "payload.tilt", false) ? "tilt" : "button"
        }`,
        gaEventLabel: undefined,
        trackName: get(data, "payload.trackName"),
        raceNumber: get(data, "payload.raceNumber"),
        sport: `${get(data, "payload.runnerType")} Racing`,
        module: "Video",
        videoType: get(data, "payload.videoType"),
        videoPosition: undefined,
        videoProvider: get(data, "payload.videoProvider")
      });
    }
  );

  mediatorChannels.base.subscribe(
    "VIDEO:PLAY_VIDEO",
    (data: VideoGTM): void => {
      pushToDataLayer({
        event: "video",
        gaEventCategory: "Video",
        gaEventAction: "Play Video",
        gaEventLabel: undefined,
        trackName: get(data, "payload.trackName"),
        raceNumber: get(data, "payload.raceNumber"),
        sport: `${get(data, "payload.runnerType")} Racing`,
        module: "Video",
        videoType: get(data, "payload.videoType"),
        videoPosition: undefined,
        videoProvider: get(data, "payload.videoProvider")
      });
    }
  );

  mediatorChannels.base.subscribe(
    "VIDEO:STOP_VIDEO",
    (data: VideoGTM): void => {
      pushToDataLayer({
        event: "video",
        gaEventCategory: "Video",
        gaEventAction: "Stop Video",
        gaEventLabel: undefined,
        trackName: get(data, "payload.trackName"),
        raceNumber: get(data, "payload.raceNumber"),
        sport: `${get(data, "payload.runnerType")} Racing`,
        module: "Video",
        videoType: get(data, "payload.videoType"),
        videoPosition: undefined,
        videoProvider: get(data, "payload.videoProvider")
      });
    }
  );

  mediatorChannels.base.subscribe("VIDEO:SUCCESS", (data: VideoGTM): void => {
    pushToDataLayer({
      event: "video",
      gaEventCategory: "Video",
      gaEventAction: "Video Success",
      gaEventLabel: undefined,
      trackName: get(data, "payload.trackName"),
      raceNumber: get(data, "payload.raceNumber"),
      sport: `${get(data, "payload.runnerType")} Racing`,
      module: "Video",
      videoType: get(data, "payload.videoType"),
      videoPosition: undefined,
      videoProvider: get(data, "payload.videoProvider")
    });
  });

  mediatorChannels.base.subscribe("VIDEO:ERROR", (data: VideoGTM): void => {
    pushToDataLayer({
      event: "video",
      gaEventCategory: "Video",
      gaEventAction: "Video Error",
      gaEventLabel: get(data, "payload.error"),
      trackName: get(data, "payload.trackName"),
      raceNumber: get(data, "payload.raceNumber"),
      sport: `${get(data, "payload.runnerType")} Racing`,
      module: "Video",
      videoType: get(data, "payload.videoType"),
      videoPosition: undefined,
      videoProvider: get(data, "payload.videoProvider")
    });
  });

  mediatorChannels.base.subscribe(
    "VIDEO:SWITCH_STREAMS",
    (data: VideoGTM): void => {
      pushToDataLayer({
        event: "video",
        gaEventCategory: "Video",
        gaEventAction: `Switch to ${
          get(data, "payload.switchToLive", false) ? "live" : "replay"
        }`,
        gaEventLabel: undefined,
        trackName: get(data, "payload.trackName"),
        raceNumber: get(data, "payload.raceNumber"),
        sport: `${get(data, "payload.runnerType")} Racing`,
        module: "Video",
        videoType: get(data, "payload.videoType"),
        videoPosition: undefined,
        videoProvider: get(data, "payload.videoProvider")
      });
    }
  );

  mediatorChannels.base.subscribe("VIDEO:NAVIGATE", (data: VideoGTM): void => {
    pushToDataLayer({
      event: "video",
      gaEventCategory: "Video",
      gaEventAction: `Jump ${
        get(data, "payload.forward", true) ? "forward" : "backward"
      }`,
      gaEventLabel: undefined,
      trackName: get(data, "payload.trackName"),
      raceNumber: get(data, "payload.raceNumber"),
      sport: `${get(data, "payload.runnerType")} Racing`,
      module: "Video",
      videoType: get(data, "payload.videoType"),
      videoPosition: undefined,
      videoProvider: get(data, "payload.videoProvider")
    });
  });
};
