// @flow
import mediatorChannels from "@tvg/mediator";
import { get } from "lodash";
import pushToDataLayer from "../gtmUtils";

type MorePageGTMEventsType = "MORE_PAGE:BALANCE_SHOWN";

type MorePageGTM = {
  type: MorePageGTMEventsType,
  payload: {
    isBalanceShown?: "0" | "1"
  }
};

type MoreLinkType = {
  type: string,
  payload: {
    linkName: string,
    url: string,
    menu: string,
    module: string
  }
};

type OpenContentLink = {
  type: string,
  payload: {
    itemOpened: string
  }
};

type CloseContentLink = {
  type: string,
  payload: {
    destinationMenu: string,
    module: string
  }
};

type ToggleSwitch = {
  type: string,
  payload: {
    action: "Toggle On" | "Toggle Off",
    field: string
  }
};

type PreferencesSwitch = {
  type: string,
  payload: {
    isExpanded: boolean
  }
};

type SupportGTMEventTypes = "SUPPORT_MODAL_TOGGLE" | "SUPPORT_MODAL_BUTTONS";

type ToggleModal = {
  type: SupportGTMEventTypes,
  payload: {
    modalToggle: string
  }
};

type ButtonLink = {
  type: SupportGTMEventTypes,
  payload: {
    buttonType: string
  }
};

type WithdrawalAndDepositButton = {
  payload: {
    destinationUrl: string,
    eventLabel: string,
    isEvenTimeout?: boolean
  }
};

export default () => {
  mediatorChannels.base.subscribe(
    "MORE_PAGE:BALANCE_SHOWN",
    (data: MorePageGTM) => {
      pushToDataLayer({
        event: "siteClick",
        gaEventCategory: "Site Click",
        gaEventAction:
          get(data, "payload.isBalanceShown", "0") === "0" ? "Hide" : "Show",
        gaEventLabel: "Balance",
        module: "More",
        tag: undefined,
        microApp: "non-Microapp"
      });
    }
  );

  mediatorChannels.base.subscribe("MORE_NAVIGATION", (data: MoreLinkType) => {
    pushToDataLayer({
      event: "navigation",
      gaEventCategory: "Navigation",
      gaEventAction: "Navigated To",
      gaEventLabel: `${get(data, "payload.linkName")}`,
      menu: `${get(data, "payload.menu", "More")}`,
      module: "More",
      sport: undefined,
      microApp: "non-Microapp",
      tag: undefined,
      destinationUrl: get(data, "payload.url")
    });
  });

  mediatorChannels.base.subscribe(
    "MORE_OPEN_CONTENT",
    (data: OpenContentLink) => {
      pushToDataLayer({
        event: "siteClick",
        gaEventCategory: "Site Click",
        gaEventAction: "Open",
        gaEventLabel: `${get(data, "payload.itemOpened")}`,
        module: "More",
        microApp: "non-Microapp",
        tag: undefined
      });
    }
  );

  mediatorChannels.base.subscribe(
    "MORE_CLOSE_CONTENT",
    (data: CloseContentLink) => {
      pushToDataLayer({
        event: "siteClick",
        gaEventCategory: "Site Click",
        gaEventAction: "Go Back To",
        gaEventLabel: `${get(data, "payload.destinationMenu")}`,
        module: `More - ${get(data, "payload.module")}`,
        microApp: "non-Microapp",
        tag: undefined
      });
    }
  );

  mediatorChannels.base.subscribe(
    "MORE_SWITCH_TOGGLE",
    (data: ToggleSwitch) => {
      pushToDataLayer({
        event: "siteClick",
        gaEventCategory: "Site Click",
        gaEventAction: data.payload.action,
        gaEventLabel: data.payload.field,
        module: "More",
        tag: undefined,
        microApp: "non-Microapp"
      });
    }
  );

  mediatorChannels.base.subscribe(
    // FR-197
    "MORE_PAGE:PREFERENCES_SECTION",
    (data: PreferencesSwitch) => {
      pushToDataLayer({
        event: "siteClick",
        gaEventCategory: "Site Click",
        gaEventAction: data.payload.isExpanded ? "Collapse" : "Expand",
        gaEventLabel: "preferences",
        module: "More",
        tag: undefined,
        microApp: undefined
      });
    }
  );

  mediatorChannels.base.subscribe(
    "SUPPORT_MODAL_TOGGLE",
    (data: ToggleModal) => {
      pushToDataLayer({
        event: "siteClick",
        gaEventCategory: "site click",
        gaEventAction: get(data, "payload.modalToggle", "open"),
        gaEventLabel: "support",
        module: "support modal",
        tag: undefined,
        microApp: undefined
      });
    }
  );

  mediatorChannels.base.subscribe(
    "SUPPORT_MODAL_BUTTONS",
    (data: ButtonLink) => {
      pushToDataLayer({
        event: "siteClick",
        gaEventCategory: "site click",
        gaEventAction: "select",
        gaEventLabel: get(data, "payload.buttonType", "phone"),
        module: "support modal",
        tag: undefined,
        microApp: undefined
      });
    }
  );

  mediatorChannels.base.subscribe(
    "MORE_PAGE:WITHDRAWAL_AND_DEPOSIT_BUTTON",
    (data: WithdrawalAndDepositButton) => {
      pushToDataLayer({
        destinationUrl: get(data, "payload.destinationUrl"),
        event: "navigation",
        gaEventAction: "Navigated To",
        gaEventCategory: "Navigation",
        gaEventLabel: get(data, "payload.eventLabel"),
        menu: "More",
        module: "More",
        tag: undefined,
        ...(get(data, "payload.isEvenTimeout", true)
          ? { evenTimeout: 2000 }
          : {})
      });
    }
  );

  // When a user clicks the Inbox icon
  mediatorChannels.base.subscribe(
    "MORE_PAGE:HEADER_SECTION_NOTIFICATIONS_BUTTON",
    () => {
      pushToDataLayer({
        event: "siteClick",
        gaEventAction: "Open",
        gaEventCategory: "Site Click",
        gaEventLabel: "TVG Inbox Notifications",
        menu: "More",
        module: "More"
      });
    }
  );
};
