// @flow

// Example of event, dispatched from ios side:
// mediatorChannels.ios.dispatch({"type":"GTM_EVENT","payload":{"message":"Ask biometric data","gtm":{"TOUCH_ID":"ATTEMPT"}}});

import { has } from "lodash";
import mediatorChannels from "@tvg/mediator";

type GTMModule = { TOUCH_ID?: string, FACE_ID?: string };

type GTMEventData = {
  type: string,
  payload: { message: string, gtm: GTMModule }
};

type GTMpushData = {
  action: string,
  label?: string,
  module: string
};

const pushGTM = (data: GTMpushData): void => {
  if (typeof window !== "undefined" && window.dataLayer) {
    window.dataLayer.push({
      event: "login",
      gaEventCategory: "Login",
      gaEventAction: data.action,
      gaEventLabel: data.label,
      module: data.module,
      microApp: "non-Microapp",
      accountId: undefined
    });
  }
};

export default () => {
  mediatorChannels.ios.subscribe("GTM_EVENT", (data: GTMEventData) => {
    let base = {
      key: "TOUCH_ID",
      module: "Touch Id"
    };

    if (has(data.payload.gtm, "FACE_ID")) {
      base = {
        key: "FACE_ID",
        module: "Face Id"
      };
    }

    switch (data.payload.gtm[base.key]) {
      case "ATTEMPT":
        pushGTM({ action: `${base.module} Attempt`, module: base.module });
        break;
      case "SUCCESS":
        pushGTM({ action: `${base.module} Success`, module: base.module });
        break;
      case "ERROR":
        pushGTM({
          action: `${base.module} Error`,
          module: base.module,
          label: data.payload.message
        });
        break;
      case "PROMPT":
        pushGTM({
          action: "Saw",
          module: base.module,
          label: `QUICK ${base.module.toUpperCase()} LOGIN`
        });
        break;
      case "YES_PROMPT":
        pushGTM({
          action: "Clicked",
          module: base.module,
          label: data.payload.message
        });
        break;
      case "SKIP_PROMPT":
        pushGTM({
          action: "Skip",
          module: base.module,
          label: `QUICK ${base.module.toUpperCase()} LOGIN`
        });
        break;
      case "NO_PROMPT":
        pushGTM({
          action: "Skip",
          module: base.module,
          label: `QUICK ${base.module.toUpperCase()} LOGIN`
        });
        break;
      default:
        break;
    }
  });
};
