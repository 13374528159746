import styled from "styled-components";

const proximaNova = "Proxima nova, sans-serif";

export const Body = styled.div`
  background-color: ${({ theme }) => theme.colorTokens.content.onDark};
  padding: 16px 16px 40px 16px;
`;

export const Title = styled.span`
  color: ${({ theme }) => theme.colorTokens.content.neutral};
  font-family: ${proximaNova};
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 125%;
`;

export const Description = styled.span`
  color: ${({ theme }) => theme.colorTokens.content.neutral};
  font-family: ${proximaNova};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%;
`;

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 24px;
`;

export const ModalHeader = styled.div`
  padding: 18px;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ theme }) => theme.colorTokens.content.onDark};
  border-radius: 8px 8px 0 0;
  border-bottom: 1px solid
    ${({ theme }) => theme.colorTokens.component.input.base};
`;

export const ModalPlaceholder = styled.div`
  width: 50px;
`;

export const ModalTitle = styled.span`
  font-size: 16px;
  font-family: ${proximaNova};
  font-weight: 700;
  color: ${({ theme }) => theme.colorTokens.background.secondary};
`;

export const ModalClose = styled.span`
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%;
  font-family: ${proximaNova};
  color: ${({ theme }) => theme.colorTokens.content.link};
  width: 50px;
  text-align: end;
`;
