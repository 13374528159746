import service from "@tvg/api";
import tvgConf from "@tvg/conf";
import Cookies from "universal-cookie";

/* eslint-disable */

(function () {
  const cookies = new Cookies();
  window._pxAppId = `PX${tvgConf().config("perimeterxId")}`;
  // Custom parameters
  window._pxParam1 = `${__TVG_GLOBALS__.DEVICE}`;
  window._pxParam2 = `${__TVG_GLOBALS__.PRODUCT}`;
  window._pxParam3 = `${__TVG_GLOBALS__.BRAND}`;
  window._pxParam4 = `${cookies.get("_gid") || ""}`;
  let p = document.getElementsByTagName("script")[0],
    s = document.createElement("script");
  s.async = 1;
  s.src = `https://client.px-cloud.net/PX${tvgConf().config(
    "perimeterxId"
  )}/main.min.js`; //Change url to the given by the backend`
  p.parentNode.insertBefore(s, p);
})();
