// @flow

import React, { PureComponent, type Node } from "react";
import {
  type TextEnum,
  type AlignmentEnum,
  type TextData,
  buildTextFn
} from "./styled-components";

export const buildText = (textDataInit?: TextData) => buildTextFn(textDataInit);

type Props = {
  /**
   * Selects the desired tag: 'label', 'span', 'p', 'div', 'strong'
   */
  tag: TextEnum,
  /**
   * children of the Text component
   */
  children: Node,
  /**
   * Size of font
   */
  fontSize: number,
  /**
   * For bold text
   */
  bold: boolean,
  /**
   * For bold text
   */
  align: AlignmentEnum,
  /**
   * Color of the text
   */
  color: string,
  /**
   * Transform text in uppercase
   */
  uppercase: boolean,
  /**
   * Transform text in capitalize
   */
  capitalize: boolean,
  /**
   * To make text ellipsis inside the wrapper
   */
  ellipsis: boolean,
  /**
   * ClassName to add new style rules based on this _atom
   */
  className: string,
  /**
   * qaLabel for test
   */
  qaLabel: string
};

export default class Text extends PureComponent<Props> {
  static defaultProps = {
    tag: "span",
    children: null,
    fontSize: -1,
    bold: false,
    color: "inherit",
    align: "inherit",
    uppercase: false,
    capitalize: false,
    ellipsis: false,
    className: "",
    qaLabel: ""
  };

  render() {
    const Txt = buildText({
      tag: this.props.tag,
      fontSize: this.props.fontSize,
      color: this.props.color,
      bold: this.props.bold,
      align: this.props.align,
      uppercase: this.props.uppercase,
      capitalize: this.props.capitalize,
      ellipsis: this.props.ellipsis,
      qaLabel: this.props.qaLabel
    });

    return <Txt className={this.props.className}>{this.props.children}</Txt>;
  }
}
