import styled from "styled-components";

export const IconButtonListWrapper = styled.ul`
  display: flex;
  align-items: center;

  @media print {
    display: none;
  }
`;

export const IconList = styled.li``;

export const SupportHyperlink = styled.a``;
