import React, { FC, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { InputCheckboxSelect } from "@tvg/design-system/web";
import { Button } from "@tvg/design-system/";
import { TABS_NAMES, useRaceTracksFilter } from "@urp/lib-racetracks";
import { getIsLogged } from "@urp/store-selectors";
import { get } from "lodash";
import { getRaceTracksIsSearchActive } from "@urp/lib-racetracks/src/redux/selectors";
import { buildQaLabel } from "@tvg/test-utils/utils";
import { FiltersContainer, InputsWrapper, Section } from "./styled-component";
import RaceTracksSearch from "../RaceTracksSearch";
import { COMP_WIDTH_PLUS_ERROR_MARGIN } from "./constants";
import { getMappedProps } from "./utils";
import FilterTags from "../FilterTags";
import type { FilterKey, MappedFilterProps, Props } from "./types";
import Drawer from "./Drawer";
import FilterGroup from "./FilterGroup";
import {
  getFiltersConfiguration,
  getIsAllFiltersFeatureEnabled
} from "../../redux/selectors";
import {
  ACTION,
  CONTAINER,
  CONTENT,
  FILTER,
  SECTION
} from "../../utils/constants";
import { FilterValueTypes } from "../../types";
import FiltersDrawerFooter from "./FiltersDrawerFooter";

const TracksFilter: FC<Props> = ({ currentTab, qaLabel = "", loading }) => {
  const filterContainerRef = useRef<HTMLDivElement | null>(null);

  const [isOpen, setIsOpen] = useState(false);

  const isTracksSearchActive = useSelector(getRaceTracksIsSearchActive);
  const [numVisibleFilters, setNumVisibleFilters] = useState(0);
  const isLogged = useSelector(getIsLogged);
  const filtersConfig = useSelector(getFiltersConfiguration);
  const isAllFiltersEnabled = useSelector(getIsAllFiltersFeatureEnabled);

  const [drawerFilters, setDrawerFilters] = useState<
    Record<FilterKey, FilterValueTypes[]> | {}
  >({});

  const {
    handleRaceTypeChange,
    handleRegionChange,
    handleTrackChange,
    handleDistanceFilter,
    handleRaceFilter,
    raceTypesFilter,
    regionsFilter,
    trackTypesFilter,
    racesFilter,
    distancesFilter,
    handleClearFilters
  } = useRaceTracksFilter();

  /**
   * Will set the amount of visible filters depending on the width of its container
   */
  useEffect(() => {
    if (isAllFiltersEnabled) {
      const element = filterContainerRef.current;
      if (!element) return () => {};

      const observer = new ResizeObserver((entries) => {
        entries.forEach((entry) => {
          const { width } = entry.contentRect;
          const roundedValue = Math.floor(width / COMP_WIDTH_PLUS_ERROR_MARGIN);
          setNumVisibleFilters(roundedValue);
        });
      });

      observer.observe(element);

      return () => {
        observer.disconnect();
      };
    }
    return () => {};
  }, [filterContainerRef.current?.clientWidth, isAllFiltersEnabled]);

  if (loading) {
    return null;
  }

  const builtFilters = filtersConfig
    .filter(({ visible }) => visible)
    .reduce<MappedFilterProps[]>((acc, { id, label }) => {
      const filterProps = getMappedProps(id, {
        currentTab,
        handleDistanceFilter,
        handleRaceFilter,
        handleRaceTypeChange,
        handleRegionChange,
        handleTrackChange,
        isLogged,
        distancesFilter,
        racesFilter,
        raceTypesFilter,
        regionsFilter,
        trackTypesFilter,
        qaLabel
      });

      if (filterProps)
        acc.push({
          ...filterProps,
          label
        });

      return acc;
    }, []);

  const buildInputCheckboxFilters = builtFilters.map(
    (
      { onChange, options, qaLabel: mapQaLabel, selectedValue, label },
      index
    ) => (
      <InputCheckboxSelect
        qaLabel={mapQaLabel}
        key={`${label}-${index.toString()}`}
        selectList={options}
        onSelectCheckbox={onChange}
        selectedValues={selectedValue}
        label={label}
      />
    )
  );
  // Cuts down the array of all filters with the state stored
  const renderFilters = isAllFiltersEnabled
    ? buildInputCheckboxFilters.splice(0, numVisibleFilters)
    : buildInputCheckboxFilters;

  // If we dont have filters (this stops the button from rendering when the page is loading)
  // or our array of buildInputCheckboxFilters (result of splicing) has something, we should stop the button
  // from rendering
  const areCardsHidden =
    numVisibleFilters !== 0 && buildInputCheckboxFilters.length > 0;

  const onClose = () => {
    setDrawerFilters({});
    setIsOpen(false);
  };

  const onApplyAllFilters = () => {
    Object.keys(drawerFilters).forEach((filterName) => {
      const filter = get(drawerFilters, filterName, []);
      switch (filterName) {
        case "distancesFilter":
          handleDistanceFilter(filter, currentTab);
          break;
        case "raceTypesFilter":
          handleRaceTypeChange(filter, currentTab);
          break;
        case "racesFilter":
          handleRaceFilter(filter, currentTab);
          break;
        case "regionsFilter":
          handleRegionChange(filter, currentTab);
          break;
        case "trackTypesFilter":
          handleTrackChange(filter, currentTab);
          break;
        default:
          break;
      }
    });
    onClose();
  };

  const renderFilterGroupList = builtFilters.map(
    (
      { options, qaLabel: mapQaLabel, selectedValue, label = "", filterKey },
      index
    ) => (
      <FilterGroup
        onChange={(values) =>
          setDrawerFilters((prevValue) => ({
            ...prevValue,
            [filterKey]: values
          }))
        }
        options={options}
        selectedValues={[
          ...selectedValue,
          ...get(drawerFilters, filterKey, [])
        ]}
        title={label}
        qaLabel={mapQaLabel}
        key={`${label}-${index.toString()}`}
      />
    )
  );

  return (
    <Section data-qa-label={buildQaLabel([qaLabel, FILTER, SECTION])}>
      <FiltersContainer
        data-qa-label={buildQaLabel([qaLabel, FILTER, CONTAINER])}
      >
        <RaceTracksSearch
          analyticsEnrichment={{ activeTab: TABS_NAMES.TODAY }}
          qaLabel={buildQaLabel([qaLabel, FILTER])}
        />
        <InputsWrapper
          hidden={isTracksSearchActive}
          ref={filterContainerRef}
          data-qa-label={buildQaLabel([qaLabel, FILTER, CONTAINER, CONTENT])}
          isAllFiltersEnabled={isAllFiltersEnabled}
        >
          {renderFilters}
        </InputsWrapper>
        {areCardsHidden && isAllFiltersEnabled && (
          <Button
            qaLabel={buildQaLabel([qaLabel, FILTER, ACTION])}
            onPress={() => {
              setIsOpen(true);
            }}
            variant="secondary"
            icon="filters"
          >
            Filters
          </Button>
        )}
      </FiltersContainer>
      <FilterTags currentTab={currentTab} />
      {isAllFiltersEnabled && (
        <Drawer
          isOpen={isOpen}
          onClose={onClose}
          title="Filter by"
          qaLabel={buildQaLabel([qaLabel, FILTER])}
          footer={
            <FiltersDrawerFooter
              hasFiltersApplied={Object.keys(drawerFilters).some(
                (key) => get(drawerFilters, key, []).length > 0
              )}
              onApply={onApplyAllFilters}
              onClear={() => {
                handleClearFilters();
                onClose();
              }}
            />
          }
        >
          {renderFilterGroupList}
        </Drawer>
      )}
    </Section>
  );
};

export default TracksFilter;
