import React from "react";
import { get } from "lodash";
import { Countries, PageHeader } from "@tvg/design-system";
import {
  TRACKS_PATHS,
  useFavoriteWithRedux,
  useSeoTracks
} from "@urp/lib-racetracks";
import { Flag, Paragraph } from "@tvg/design-system/web";
import { Module } from "@tvg/amplitude/modules/raceCell/types";
import { getIsLogged, getAccountNumber } from "@urp/store-selectors";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import withApolloContext from "../../hoc/withApolloContext";
import { Container, Favorite } from "./styled-components";
import { ApolloContextProps } from "../../types";
import TrackDetailsContent from "../TrackDetailsContent";
import { getTrackSubtitle } from "./utils";
import TrackIllustration from "../TrackIllustration";
import TrackRaces from "../TrackRaces";
import { sendTrackDetailsBackEvent } from "../../amplitude";
import { getEnableSeoRaceTracks } from "../../redux/selectors";

const TRACK_CODE_IDX = 3;

const TrackDetails = ({ fcpClient, rdaClient }: ApolloContextProps) => {
  const navigate = useNavigate();
  const location = useLocation();

  const { results } = useSeoTracks(fcpClient);

  const pathParts = location.pathname.split("/");
  const trackCode = get(pathParts, `${TRACK_CODE_IDX}`);
  const track = results.find((item) => item.code === trackCode);

  const enableSeoRaceTracks = useSelector(getEnableSeoRaceTracks);
  const isLogged = useSelector(getIsLogged);
  const accountNumber = useSelector(getAccountNumber);

  const { isFavorite, toggleFavorite } = useFavoriteWithRedux(
    isLogged,
    accountNumber,
    Module.TIL
  );

  if (!enableSeoRaceTracks || !track) return null;

  const onBack = () => {
    sendTrackDetailsBackEvent({ destinationUrl: TRACKS_PATHS.TIL });
    navigate(-1);
  };

  return (
    <Container>
      <Favorite
        trackName={track.name}
        isFavorite={isFavorite(track.code)}
        toggleFavorite={() => toggleFavorite(track.code, track.name)}
      />
      <PageHeader
        title={track.name}
        onBack={onBack}
        titlePosition="center"
        subtitleType="element"
        subtitle={
          <>
            <Flag
              country={track.location.country as Countries}
              size="s"
              qaLabel=""
              paddingRight="4px"
            />
            <Paragraph qaLabel="" color="content.subtle" fontFamily="regular">
              {getTrackSubtitle(track)}
            </Paragraph>
          </>
        }
      />
      <TrackIllustration track={track} />
      <TrackRaces track={track} client={rdaClient} />
      <TrackDetailsContent trackCode={track.code} />
    </Container>
  );
};

export default withApolloContext<ApolloContextProps>(TrackDetails);
