// @flow

import styled from "styled-components";

export type TextEnum = "label" | "span" | "div" | "p" | "strong";
export type AlignmentEnum = "inherit" | "left" | "center" | "right" | "justify";

export type TextData = {
  tag?: TextEnum,
  fontSize?: number,
  color?: string,
  bold?: boolean,
  align?: AlignmentEnum,
  uppercase?: boolean,
  ellipsis?: boolean,
  qaLabel?: string
};

// eslint-disable-next-line import/prefer-default-export
export const buildTextFn = (props?: TextData) => {
  const defaultProp = {
    tag: "span",
    fontSize: -1,
    color: "inherit",
    bold: false,
    align: "inherit",
    uppercase: false,
    capitalize: false,
    ellipsis: false,
    qaLabel: ""
  };

  const textData = { ...defaultProp, ...props };

  const TextAtom = styled[textData.tag].attrs({
    ...(textData.qaLabel !== ""
      ? {
          "data-qa-label": textData.qaLabel
        }
      : {})
  })`
    font-size: ${textData.fontSize >= 0 && `${textData.fontSize}px`};
    font-weight: ${textData.bold && "bold"};
    color: ${textData.color !== "inherit" && textData.color};
    text-transform: ${(textData.uppercase && "uppercase") ||
    (textData.capitalize && "capitalize")};
    text-align: ${textData.align !== "inherit" && textData.align};
  `;

  return !textData.ellipsis
    ? TextAtom
    : styled(TextAtom)`
        display: block;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      `;
};
