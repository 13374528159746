// @flow

import mediator from "@tvg/mediator";
import type { RacePanelLink } from "@tvg/types/Race";
import { get } from "lodash";
import pushToDataLayer, { processTrackName } from "../gtmUtils";

type UpcomingRaceClick = {
  type: "UPCOMING_RACE_CLICK",
  payload: {
    race: RacePanelLink,
    isRDA: boolean,
    url: string,
    optedInPromos: { string: boolean }
  }
};

const processNonRDATag = (data: UpcomingRaceClick): string => {
  const hasPromo = !!get(data, "payload.race.promos.length", 0);
  let promoTag = hasPromo ? "promo" : "no promo";

  if (hasPromo) {
    const optedIn = get(
      data.payload,
      `optedInPromos.${get(
        data.payload,
        "race.promos[0].rootParentPromoID",
        0
      )}`,
      false
    );
    promoTag += optedIn ? " optin" : "";
  }

  const hasPicks = !!get(data.payload, "race.talentPicks.length", 0);
  const picksTag = hasPicks ? "picks" : "no picks";
  const betsTag =
    get(data.payload, "race.betsCount", 0) > 0 ? "bets" : "no bets";
  return `${promoTag} - ${betsTag} - ${picksTag}`;
};

const processRDATag = (data: UpcomingRaceClick): string => {
  let promoTag = "no promo";
  const userPromos = get(data, "payload.race.userPromotions.length", 0);
  if (userPromos) {
    promoTag = get(data, "payload.race.userPromotions[0].optedIn", false)
      ? "promo optin"
      : "promo";
  }
  const picksTag = get(data, "payload.race.talentPicks.length", 0)
    ? "picks"
    : "no picks";
  const betsTag =
    get(data.payload, "race.betsCount", 0) > 0 ? "bets" : "no bets";

  return `${promoTag} - ${betsTag} - ${picksTag}`;
};

const processEventLabel = (data: UpcomingRaceClick): string =>
  `${processTrackName(get(data, "payload.race.trackName"))} - R${get(
    data,
    "payload.race.raceNumber"
  )} - ${get(data, "payload.race.mtp")}`;

export default () => {
  mediator.base.subscribe("UPCOMING_RACE_CLICK", (data: UpcomingRaceClick) => {
    const tag = get(data, "payload.isRDA", false)
      ? processRDATag(data)
      : processNonRDATag(data);
    const gaEventLabel = processEventLabel(data);
    pushToDataLayer({
      event: "navigation",
      gaEventCategory: "Navigation",
      gaEventAction: "Navigated To",
      gaEventLabel,
      module: "upcoming races",
      tag,
      menu: undefined,
      sport: get(data, "payload.race.isGreyhound", false)
        ? "Greyhounds Racing"
        : "Horse Racing",
      destinationUrl: get(data.payload, "url", "")
    });
  });

  mediator.base.subscribe(
    "UPCOMING_CONTAINER_SWIPE",
    (data: {
      type: "UPCOMING_CONTAINER_SWIPE",
      payload: { swipeDirection: number }
    }) => {
      const swipeValue = get(data.payload, "swipeDirection", 1);
      const swipeDirectionLiteral = swipeValue > 0 ? "right" : "left";
      pushToDataLayer({
        event: "siteClick",
        gaEventCategory: "Site Click",
        gaEventAction: swipeDirectionLiteral,
        gaEventLabel: undefined,
        module: "upcoming races",
        tag: undefined,
        microApp: undefined
      });
    }
  );
};
