// @flow
import mediatorChannels from "@tvg/mediator";
import type { HomepageTrackListRaceGTM } from "@tvg/types/HompeageTrackList";
import { get } from "lodash";
import pushToDataLayer from "../gtmUtils";

export default () => {
  mediatorChannels.base.subscribe(
    "HOMEPAGE_TRACK_LIST_NAVIGATION",
    (data: HomepageTrackListRaceGTM) => {
      pushToDataLayer({
        event: "navigation",
        gaEventCategory: "Navigation",
        gaEventAction: "Navigated To",
        gaEventLabel: `${get(data, "payload.trackName")} - R${get(
          data,
          "payload.number"
        )} - ${get(data, "payload.mtp")}`,
        module: "home page tl",
        tag: undefined,
        menu: undefined,
        sport: get(data, "payload.isGreyhound", false)
          ? "Greyhounds Racing"
          : "Horse Racing",
        destinationUrl: get(data, "payload.url")
      });
    }
  );
};
