import { usePrevious } from "@tvg/custom-hooks";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { gql, useQuery } from "@apollo/client";
import mtpStatusAction from "@tvg/mtp-update/src/actions";
import { getDateRace, getMtpStatus } from "../store/selectors/general";

const useRefreshApp = () => {
  const raceDate = useSelector(getDateRace);
  const mtpStatus = useSelector(getMtpStatus);
  const previous = usePrevious(raceDate);
  const dispatch = useDispatch();

  const { data } = useQuery(
    gql`
      query getRaceDate {
        raceDate
      }
    `,
    {
      pollInterval: 300000,
      fetchPolicy: "network-only",
      ssr: false
    }
  );

  useEffect(() => {
    if (data?.raceDate && raceDate !== data.raceDate) {
      dispatch(mtpStatusAction(mtpStatus, data.raceDate));
    }
  }, [data?.raceDate]);

  useEffect(() => {
    if (raceDate && previous && previous !== raceDate) {
      window.location.reload();
    }
  }, [raceDate]);
};

export default useRefreshApp;
