import React, { useContext } from "react";
import { TvgConfContext } from "../../../utils/tvgConfProvider";
import { useQaLabel } from "../../../hooks/useQaLabel";
import { Header } from "../../typography/header";
import { StyledComponent, TextContainer } from "./styled-components";
import { SectionHeaderProps } from "./types";

export const SectionHeader = ({
  qaLabel = "section-header",
  title,
  ...rest
}: SectionHeaderProps) => {
  const viewTestProps = useQaLabel(qaLabel);
  const { device } = useContext(TvgConfContext);
  const isDesktop = device === "desktop";

  return (
    <StyledComponent isDesktop={isDesktop} {...viewTestProps} {...rest}>
      <TextContainer>
        <Header tag="h2" qaLabel="pages-header" fontFamily="bold">
          {title}
        </Header>
      </TextContainer>
    </StyledComponent>
  );
};

export { SectionHeaderProps };
export default SectionHeader;
