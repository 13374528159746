import { groupBy } from "lodash";
import { Track } from "@tvg/ts-types/Track";
import { FilterOptions } from "@urp/lib-racetracks/src/types";

export const getRaceTracksByLetter = (races: Track[]) =>
  groupBy(races, (item) => item.name[0]);

export const getWidthCells = (
  lastStep: number,
  childNodes: NodeListOf<ChildNode> | undefined,
  offset: number
) => {
  let totalWidth = 0;
  if (childNodes) {
    childNodes.forEach((node, idx) => {
      const elem = node as HTMLElement;
      if (idx < lastStep) totalWidth += elem.scrollWidth + offset;
    });
  }

  return totalWidth;
};

export const countryToEmojiFlag = (countryCode: string) => {
  const exceptionList = ["ARE", "DNK", "IRE", "SWE"];

  const country = exceptionList.includes(countryCode)
    ? countryCode.charAt(0).toUpperCase() +
      countryCode.charAt(countryCode.length - 1).toUpperCase()
    : countryCode;

  const codePoints = country
    .substring(0, 2)
    .toUpperCase()
    .split("")
    .map((char) => 127397 + char.charCodeAt(0));
  return String.fromCodePoint(...codePoints);
};

export const getTrackLocation = (content: string) => {
  // TODO: refactor using regex later
  let address = "";
  const indexOfAddress = content.search("Address");
  address = indexOfAddress !== -1 ? content.substr(indexOfAddress) : "";

  const indexOfParagraphEnd = address.search("</p>");
  address =
    indexOfParagraphEnd !== -1 ? address.substr(0, indexOfParagraphEnd) : "";

  const indexOfHeadingEnd = address.search("</h2>");
  address =
    indexOfHeadingEnd !== -1 ? address.substr(indexOfHeadingEnd + 5) : "";

  address = address.replace(/<.*>/g, "");

  return address.trim();
};
export const isFilterApplied = (
  filterOptions: FilterOptions | undefined
): boolean => {
  if (filterOptions) {
    const {
      raceTypesFilter,
      regionsFilter,
      distancesFilter,
      racesFilter,
      trackTypesFilter
    } = filterOptions;
    return !!(
      raceTypesFilter?.length ||
      regionsFilter?.length ||
      distancesFilter?.length ||
      racesFilter?.length ||
      trackTypesFilter?.length
    );
  }
  return false;
};
