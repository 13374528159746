// @flow

import React, { PureComponent } from "react";
import styled, { keyframes } from "styled-components";
import color from "../ColorPalette";

const pulseAnimation = keyframes`
  0% {
    fill: ${color("grey", "100")};
  }

  50% {
    fill: ${color("grey", "000")};
  }

  100% {
    fill: ${color("grey", "100")};
  }
`;

const SVG = styled.svg`
  display: block;

  rect.pulse {
    animation: ${pulseAnimation} 1.25s infinite;
  }
`;

const Container = styled.div`
  overflow: hidden;
  width: 100%;
`;

export default class RaceLog extends PureComponent<*> {
  render() {
    return (
      <Container>
        <SVG
          xmlns="http://www.w3.org/2000/svg"
          width="414"
          height="704"
          viewBox="0 0 414 704"
        >
          <g fill="none" fillRule="evenodd">
            <g transform="translate(0 -299)">
              <rect width="414" height="1031" fill="#F0F3F8" />
              <rect width="414" height="88" y="299" fill="#FFF" />
              <rect
                width="401"
                className="pulse"
                height="1"
                x="13"
                y="386"
                fill="#CFD6DB"
              />
              <rect
                width="156"
                className="pulse"
                height="12"
                x="12"
                y="337"
                fill="#F0F3F8"
                rx="2"
              />
              <rect
                width="132"
                className="pulse"
                height="12"
                x="12"
                y="359"
                fill="#F0F3F8"
                rx="2"
              />
              <rect
                width="196"
                className="pulse"
                height="12"
                x="12"
                y="315"
                fill="#F0F3F8"
                rx="2"
              />
              <rect width="414" height="88" y="651" fill="#FFF" />
              <rect
                width="401"
                className="pulse"
                height="1"
                x="13"
                y="738"
                fill="#CFD6DB"
              />
              <rect
                width="156"
                className="pulse"
                height="12"
                x="12"
                y="689"
                fill="#F0F3F8"
                rx="2"
              />
              <rect
                width="132"
                className="pulse"
                height="12"
                x="12"
                y="711"
                fill="#F0F3F8"
                rx="2"
              />
              <rect
                width="196"
                className="pulse"
                height="12"
                x="12"
                y="667"
                fill="#F0F3F8"
                rx="2"
              />
              <rect width="414" height="88" y="387" fill="#FFF" />
              <rect
                width="401"
                className="pulse"
                height="1"
                x="13"
                y="474"
                fill="#CFD6DB"
              />
              <rect
                width="116"
                className="pulse"
                height="12"
                x="12"
                y="425"
                fill="#F0F3F8"
                rx="2"
              />
              <rect
                width="164"
                className="pulse"
                height="12"
                x="12"
                y="447"
                fill="#F0F3F8"
                rx="2"
              />
              <rect
                width="220"
                className="pulse"
                height="12"
                x="12"
                y="403"
                fill="#F0F3F8"
                rx="2"
              />
              <rect width="414" height="88" y="739" fill="#FFF" />
              <rect
                width="401"
                className="pulse"
                height="1"
                x="13"
                y="826"
                fill="#CFD6DB"
              />
              <rect
                width="116"
                className="pulse"
                height="12"
                x="12"
                y="777"
                fill="#F0F3F8"
                rx="2"
              />
              <rect
                width="164"
                className="pulse"
                height="12"
                x="12"
                y="799"
                fill="#F0F3F8"
                rx="2"
              />
              <rect
                width="220"
                className="pulse"
                height="12"
                x="12"
                y="755"
                fill="#F0F3F8"
                rx="2"
              />
              <rect width="414" height="88" y="475" fill="#FFF" />
              <rect
                width="401"
                className="pulse"
                height="1"
                x="13"
                y="562"
                fill="#CFD6DB"
              />
              <rect
                width="156"
                className="pulse"
                height="12"
                x="12"
                y="513"
                fill="#F0F3F8"
                rx="2"
              />
              <rect
                width="132"
                className="pulse"
                height="12"
                x="12"
                y="535"
                fill="#F0F3F8"
                rx="2"
              />
              <rect
                width="140"
                className="pulse"
                height="12"
                x="12"
                y="491"
                fill="#F0F3F8"
                rx="2"
              />
              <rect width="414" height="88" y="563" fill="#FFF" />
              <rect
                width="401"
                className="pulse"
                height="1"
                x="13"
                y="650"
                fill="#CFD6DB"
              />
              <rect
                width="188"
                className="pulse"
                height="12"
                x="12"
                y="601"
                fill="#F0F3F8"
                rx="2"
              />
              <rect
                width="132"
                className="pulse"
                height="12"
                x="12"
                y="623"
                fill="#F0F3F8"
                rx="2"
              />
              <rect
                width="164"
                className="pulse"
                height="12"
                x="12"
                y="579"
                fill="#F0F3F8"
                rx="2"
              />
              <rect width="414" height="88" y="827" fill="#FFF" />
              <rect
                width="401"
                className="pulse"
                height="1"
                x="13"
                y="914"
                fill="#CFD6DB"
              />
              <rect
                width="156"
                className="pulse"
                height="12"
                x="12"
                y="865"
                fill="#F0F3F8"
                rx="2"
              />
              <rect
                width="132"
                className="pulse"
                height="12"
                x="12"
                y="887"
                fill="#F0F3F8"
                rx="2"
              />
              <rect
                width="140"
                className="pulse"
                height="12"
                x="12"
                y="843"
                fill="#F0F3F8"
                rx="2"
              />
            </g>
            <rect width="414" height="88" y="616" fill="#FFF" />
            <rect
              width="401"
              className="pulse"
              height="1"
              x="13"
              y="703"
              fill="#CFD6DB"
            />
            <rect
              width="188"
              className="pulse"
              height="12"
              x="12"
              y="654"
              fill="#F0F3F8"
              rx="2"
            />
            <rect
              width="132"
              className="pulse"
              height="12"
              x="12"
              y="676"
              fill="#F0F3F8"
              rx="2"
            />
            <rect
              width="164"
              className="pulse"
              height="12"
              x="12"
              y="632"
              fill="#F0F3F8"
              rx="2"
            />
          </g>
        </SVG>
      </Container>
    );
  }
}
