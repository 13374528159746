import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { attempt } from "lodash";
import {
  TVG_PAGE_CONFIG,
  JURISDICTION_LOCAL_STORAGE_ITEM
} from "@tvg/amplitude/constants";
import { useIsomorphicLayoutEffect, usePrevious } from "@tvg/custom-hooks";
import {
  GlobalPropertiesObject,
  UserCustomProperties,
  amplitudeInit,
  trackingInit,
  setAmplitudeUserId,
  setAmplitudeUserProperties,
  setAmplitudeAnonymize,
  buildGlobalProperties
} from "@tvg/amplitude";
import {
  getAuthTokenCookie,
  getUserSessionData
} from "@tvg/sh-utils/sessionUtils";
import { isFDR } from "@tvg/utils/generalUtils";
import {
  getAccountNumber,
  getBalance,
  getIsLogged,
  getResidenceState
} from "@urp/store-selectors";
import { getPageTitle } from "../store/selectors/general";

const useAmplitude = () => {
  const balance = useSelector(getBalance);
  const isLogged = useSelector(getIsLogged);
  const accountNumber = useSelector(getAccountNumber);
  const homeState: string = useSelector(getResidenceState);
  const pageTitle: string = useSelector(getPageTitle);
  const globalPropsRef = useRef<GlobalPropertiesObject>();
  const previousPath = useRef<string>("");
  const location = useLocation();
  const previousLocation = usePrevious(location);
  const jurisdictionLocalStorageItem = attempt(() =>
    localStorage.getItem(JURISDICTION_LOCAL_STORAGE_ITEM)
  );
  const jurisdiction =
    jurisdictionLocalStorageItem &&
    typeof jurisdictionLocalStorageItem === "string"
      ? jurisdictionLocalStorageItem
      : homeState;

  useEffect(() => {
    if (previousLocation && location.pathname !== previousLocation.pathname) {
      previousPath.current = previousLocation.pathname;
    }
  }, [location.pathname, previousLocation?.pathname]);

  useEffect(() => {
    globalPropsRef.current = buildGlobalProperties({
      balance,
      jurisdiction,
      isLogged,
      location,
      pageTitle,
      pageConfig: TVG_PAGE_CONFIG
    });
  }, [isLogged, balance, JSON.stringify(location), pageTitle]);

  const getGlobalProperties = () => globalPropsRef.current;

  useIsomorphicLayoutEffect(() => {
    amplitudeInit({ getGlobalProperties, extra: { prevPath: previousPath } });
    trackingInit();
  }, []);

  useEffect(() => {
    // When user logs out
    if (!isLogged) setAmplitudeAnonymize();
  }, [isLogged]);

  useEffect(() => {
    if (isFDR()) {
      const authToken = getAuthTokenCookie();
      const { fdUserId } = getUserSessionData(authToken);
      setAmplitudeUserId(fdUserId);
      return;
    }

    if (accountNumber) setAmplitudeUserId(accountNumber);
  }, [accountNumber, getAuthTokenCookie()]);

  useEffect(() => {
    if (homeState) {
      setAmplitudeUserProperties({
        [UserCustomProperties.USER_LOCATION]: jurisdiction || homeState
      });
    }
  }, [homeState]);
};

export default useAmplitude;
