import styled from "styled-components";
import FavoriteIcon from "../FavoriteIcon";

export const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 12px;
  background-color: ${({ theme }) => theme.colorTokens.background.layer};
  width: 100%;
  position: relative;
`;

export const Favorite = styled(FavoriteIcon)`
  position: absolute;
  z-index: 1;
  top: 14px;
  right: 6px;
`;
