import React from "react";
import { useTransition, config, animated } from "@react-spring/web";
import {
  breakpoints,
  IconButton,
  Logo,
  LogoProps,
  useMediaQuery,
  WebScrollbar
} from "@tvg/design-system";
import LHN from "@tvg/lhn";
import {
  Container,
  GlobalStyle,
  Overlay,
  ContentContainer,
  Header,
  Wrapper,
  LHNContainer
} from "./styled-components";
import MenuItemsComp from "./menuItems";

export interface HamburgerMenuProps {
  isOpen: boolean;
  logoBrand?: LogoProps["brand"];
  onClose: () => void;
}

const AnimatedContainer = animated(Container);

const HamburgerMenu = ({
  isOpen,
  logoBrand = "tvg",
  onClose
}: HamburgerMenuProps) => {
  const isDesktopMinXl = useMediaQuery(breakpoints.desktop.min.xl);
  const isDesktopMinXxl = useMediaQuery(breakpoints.desktop.min.xxl);
  const isIntermediumMediaQuery = isDesktopMinXl && !isDesktopMinXxl;

  const menuTransition = useTransition(isOpen, {
    config: isOpen ? { ...config.stiff } : { duration: 300 },
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 }
  });

  return menuTransition(
    (styles, isMenuOpen) =>
      isMenuOpen &&
      !isDesktopMinXxl && (
        <>
          <GlobalStyle />
          <AnimatedContainer style={styles} data-qa-label="hamburger-menu">
            <ContentContainer data-qa-label="hamburger-menu-container">
              <Header data-qa-label="hamburger-menu-header">
                {!isIntermediumMediaQuery && (
                  <IconButton
                    variant="tertiary_dark"
                    iconName="close"
                    qaLabel="close-menu-button"
                    isActive={false}
                    onPress={onClose}
                    mr="space-5"
                  />
                )}
                <Logo isDarkMode brand={logoBrand} />
              </Header>
              <Wrapper data-qa-label="hamburger-menu-wrapper">
                <WebScrollbar
                  component="nav"
                  isDark
                  mr="12px"
                  data-qa-label="hamburger-menu-nav"
                >
                  {!isIntermediumMediaQuery && (
                    <MenuItemsComp onClickMenuItem={onClose} />
                  )}
                </WebScrollbar>
                <LHNContainer isIntermediumMediaQuery>
                  <LHN
                    changeCollapsedMediaQuery={isIntermediumMediaQuery}
                    isInsideHamburgerMenu={true}
                    onClickRaceEntry={onClose}
                    isPositionFixed={false}
                    showCollapseButton
                  />
                </LHNContainer>
              </Wrapper>
            </ContentContainer>
            <Overlay onClick={onClose} />
          </AnimatedContainer>
        </>
      )
  );
};

export default HamburgerMenu;
