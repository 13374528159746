// @flow

import React, { PureComponent } from "react";
import type { Device } from "@tvg/types/Device";
import styled from "styled-components";
import color from "@fdr/static-ui/ColorPalette";

const Container = styled.div`
  width: 100%;
  padding: 12px;
  background-color: white;
`;

type Props = {
  device: Device
};

export default class TopRacesMask extends PureComponent<Props> {
  static defaultProps = {
    device: "mobile"
  };

  render() {
    return (
      <Container>
        <svg
          width="1194"
          height="329"
          viewBox="0 0 1194 329"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g opacity="0.94">
            <rect
              x="0.5"
              y="0.5"
              width="293"
              height="328"
              rx="4.5"
              fill="white"
            />
            <g filter="url(#filter0_i_5_1528)">
              <path
                d="M1 5C1 2.79086 2.79086 1 5 1H289C291.209 1 293 2.79086 293 5V93H1V5Z"
                fill="white"
              />
              <path d="M13 13H113V25H13V13Z" fill={color("grey", "000")} />
              <rect
                x="13"
                y="37"
                width="45"
                height="12"
                fill={color("grey", "000")}
              />
              <circle cx="23" cy="71" r="10" fill={color("grey", "000")} />
              <rect
                x="37"
                y="65"
                width="21"
                height="12"
                fill={color("grey", "000")}
              />
              <rect
                x="264"
                y="62.5"
                width="17"
                height="17"
                fill={color("grey", "000")}
              />
              <rect
                x="222"
                y="62.5"
                width="17"
                height="17"
                fill={color("grey", "000")}
              />
              <rect
                x="243"
                y="62.5"
                width="17"
                height="17"
                fill={color("grey", "000")}
              />
            </g>
            <rect
              width="66"
              height="67"
              transform="translate(1 93)"
              fill="white"
            />
            <rect
              x="13"
              y="105"
              width="42"
              height="42"
              fill={color("grey", "000")}
            />
            <g filter="url(#filter1_i_5_1528)">
              <rect
                width="226"
                height="67"
                transform="translate(67 93)"
                fill="white"
              />
              <rect
                x="67"
                y="108.5"
                width="120"
                height="12"
                fill={color("grey", "000")}
              />
              <rect
                x="67"
                y="132.5"
                width="160"
                height="12"
                fill={color("grey", "000")}
              />
              <rect
                x="260"
                y="108.5"
                width="21"
                height="12"
                fill={color("grey", "000")}
              />
            </g>
            <rect
              width="66"
              height="67"
              transform="translate(1 160)"
              fill="white"
            />
            <rect
              x="13"
              y="172"
              width="42"
              height="42"
              fill={color("grey", "000")}
            />
            <g filter="url(#filter2_i_5_1528)">
              <rect
                width="226"
                height="67"
                transform="translate(67 160)"
                fill="white"
              />
              <rect
                x="67"
                y="175.5"
                width="120"
                height="12"
                fill={color("grey", "000")}
              />
              <rect
                x="67"
                y="199.5"
                width="160"
                height="12"
                fill={color("grey", "000")}
              />
              <rect
                x="260"
                y="175.5"
                width="21"
                height="12"
                fill={color("grey", "000")}
              />
            </g>
            <rect
              width="66"
              height="67"
              transform="translate(1 227)"
              fill="white"
            />
            <rect
              x="13"
              y="239"
              width="42"
              height="42"
              fill={color("grey", "000")}
            />
            <rect
              width="226"
              height="67"
              transform="translate(67 227)"
              fill="white"
            />
            <rect
              x="67"
              y="242.5"
              width="120"
              height="12"
              fill={color("grey", "000")}
            />
            <rect
              x="67"
              y="266.5"
              width="160"
              height="12"
              fill={color("grey", "000")}
            />
            <rect
              x="260"
              y="242.5"
              width="21"
              height="12"
              fill={color("grey", "000")}
            />
            <g filter="url(#filter3_i_5_1528)">
              <path
                d="M1 294H293V324C293 326.209 291.209 328 289 328H5C2.79086 328 1 326.209 1 324V294Z"
                fill="#F5F8FC"
              />
              <rect
                x="13"
                y="305"
                width="160"
                height="12"
                fill={color("grey", "000")}
              />
              <path
                d="M274.146 306.647C273.951 306.842 273.951 307.159 274.146 307.354L277.792 311L274.146 314.646C273.951 314.841 273.951 315.158 274.146 315.353C274.341 315.548 274.658 315.548 274.853 315.353L278.853 311.353C278.951 311.255 278.999 311.127 278.999 310.999C278.999 310.871 278.95 310.743 278.853 310.645L274.853 306.645C274.658 306.452 274.342 306.452 274.146 306.647Z"
                fill={color("grey", "000")}
              />
            </g>
            <rect
              x="0.5"
              y="0.5"
              width="293"
              height="328"
              rx="4.5"
              stroke="#CFD6DB"
            />
          </g>
          <g opacity="0.94">
            <rect
              x="600.5"
              y="0.5"
              width="293"
              height="328"
              rx="4.5"
              fill="white"
            />
            <g filter="url(#filter4_i_5_1528)">
              <path
                d="M601 5C601 2.79086 602.791 1 605 1H889C891.209 1 893 2.79086 893 5V93H601V5Z"
                fill="white"
              />
              <path d="M613 13H713V25H613V13Z" fill={color("grey", "000")} />
              <rect
                x="613"
                y="37"
                width="45"
                height="12"
                fill={color("grey", "000")}
              />
              <circle cx="623" cy="71" r="10" fill={color("grey", "000")} />
              <rect
                x="637"
                y="65"
                width="21"
                height="12"
                fill={color("grey", "000")}
              />
              <rect
                x="864"
                y="62.5"
                width="17"
                height="17"
                fill={color("grey", "000")}
              />
              <rect
                x="822"
                y="62.5"
                width="17"
                height="17"
                fill={color("grey", "000")}
              />
              <rect
                x="843"
                y="62.5"
                width="17"
                height="17"
                fill={color("grey", "000")}
              />
            </g>
            <rect
              width="66"
              height="67"
              transform="translate(601 93)"
              fill="white"
            />
            <rect
              x="613"
              y="105"
              width="42"
              height="42"
              fill={color("grey", "000")}
            />
            <g filter="url(#filter5_i_5_1528)">
              <rect
                width="226"
                height="67"
                transform="translate(667 93)"
                fill="white"
              />
              <rect
                x="667"
                y="108.5"
                width="120"
                height="12"
                fill={color("grey", "000")}
              />
              <rect
                x="667"
                y="132.5"
                width="160"
                height="12"
                fill={color("grey", "000")}
              />
              <rect
                x="860"
                y="108.5"
                width="21"
                height="12"
                fill={color("grey", "000")}
              />
            </g>
            <rect
              width="66"
              height="67"
              transform="translate(601 160)"
              fill="white"
            />
            <rect
              x="613"
              y="172"
              width="42"
              height="42"
              fill={color("grey", "000")}
            />
            <g filter="url(#filter6_i_5_1528)">
              <rect
                width="226"
                height="67"
                transform="translate(667 160)"
                fill="white"
              />
              <rect
                x="667"
                y="175.5"
                width="120"
                height="12"
                fill={color("grey", "000")}
              />
              <rect
                x="667"
                y="199.5"
                width="160"
                height="12"
                fill={color("grey", "000")}
              />
              <rect
                x="860"
                y="175.5"
                width="21"
                height="12"
                fill={color("grey", "000")}
              />
            </g>
            <rect
              width="66"
              height="67"
              transform="translate(601 227)"
              fill="white"
            />
            <rect
              x="613"
              y="239"
              width="42"
              height="42"
              fill={color("grey", "000")}
            />
            <rect
              width="226"
              height="67"
              transform="translate(667 227)"
              fill="white"
            />
            <rect
              x="667"
              y="242.5"
              width="120"
              height="12"
              fill={color("grey", "000")}
            />
            <rect
              x="667"
              y="266.5"
              width="160"
              height="12"
              fill={color("grey", "000")}
            />
            <rect
              x="860"
              y="242.5"
              width="21"
              height="12"
              fill={color("grey", "000")}
            />
            <g filter="url(#filter7_i_5_1528)">
              <path
                d="M601 294H893V324C893 326.209 891.209 328 889 328H605C602.791 328 601 326.209 601 324V294Z"
                fill="#F5F8FC"
              />
              <rect
                x="613"
                y="305"
                width="160"
                height="12"
                fill={color("grey", "000")}
              />
              <path
                d="M874.146 306.647C873.951 306.842 873.951 307.159 874.146 307.354L877.792 311L874.146 314.646C873.951 314.841 873.951 315.158 874.146 315.353C874.341 315.548 874.658 315.548 874.853 315.353L878.853 311.353C878.951 311.255 878.999 311.127 878.999 310.999C878.999 310.871 878.95 310.743 878.853 310.645L874.853 306.645C874.658 306.452 874.342 306.452 874.146 306.647Z"
                fill={color("grey", "000")}
              />
            </g>
            <rect
              x="600.5"
              y="0.5"
              width="293"
              height="328"
              rx="4.5"
              stroke="#CFD6DB"
            />
          </g>
          <g opacity="0.94">
            <rect
              x="300.5"
              y="0.5"
              width="293"
              height="328"
              rx="4.5"
              fill="white"
            />
            <g filter="url(#filter8_i_5_1528)">
              <path
                d="M301 5C301 2.79086 302.791 1 305 1H589C591.209 1 593 2.79086 593 5V93H301V5Z"
                fill="white"
              />
              <path d="M313 13H413V25H313V13Z" fill={color("grey", "000")} />
              <rect
                x="313"
                y="37"
                width="45"
                height="12"
                fill={color("grey", "000")}
              />
              <circle cx="323" cy="71" r="10" fill={color("grey", "000")} />
              <rect
                x="337"
                y="65"
                width="21"
                height="12"
                fill={color("grey", "000")}
              />
              <rect
                x="564"
                y="62.5"
                width="17"
                height="17"
                fill={color("grey", "000")}
              />
              <rect
                x="522"
                y="62.5"
                width="17"
                height="17"
                fill={color("grey", "000")}
              />
              <rect
                x="543"
                y="62.5"
                width="17"
                height="17"
                fill={color("grey", "000")}
              />
            </g>
            <rect
              width="66"
              height="67"
              transform="translate(301 93)"
              fill="white"
            />
            <rect
              x="313"
              y="105"
              width="42"
              height="42"
              fill={color("grey", "000")}
            />
            <g filter="url(#filter9_i_5_1528)">
              <rect
                width="226"
                height="67"
                transform="translate(367 93)"
                fill="white"
              />
              <rect
                x="367"
                y="108.5"
                width="120"
                height="12"
                fill={color("grey", "000")}
              />
              <rect
                x="367"
                y="132.5"
                width="160"
                height="12"
                fill={color("grey", "000")}
              />
              <rect
                x="560"
                y="108.5"
                width="21"
                height="12"
                fill={color("grey", "000")}
              />
            </g>
            <rect
              width="66"
              height="67"
              transform="translate(301 160)"
              fill="white"
            />
            <rect
              x="313"
              y="172"
              width="42"
              height="42"
              fill={color("grey", "000")}
            />
            <g filter="url(#filter10_i_5_1528)">
              <rect
                width="226"
                height="67"
                transform="translate(367 160)"
                fill="white"
              />
              <rect
                x="367"
                y="175.5"
                width="120"
                height="12"
                fill={color("grey", "000")}
              />
              <rect
                x="367"
                y="199.5"
                width="160"
                height="12"
                fill={color("grey", "000")}
              />
              <rect
                x="560"
                y="175.5"
                width="21"
                height="12"
                fill={color("grey", "000")}
              />
            </g>
            <rect
              width="66"
              height="67"
              transform="translate(301 227)"
              fill="white"
            />
            <rect
              x="313"
              y="239"
              width="42"
              height="42"
              fill={color("grey", "000")}
            />
            <rect
              width="226"
              height="67"
              transform="translate(367 227)"
              fill="white"
            />
            <rect
              x="367"
              y="242.5"
              width="120"
              height="12"
              fill={color("grey", "000")}
            />
            <rect
              x="367"
              y="266.5"
              width="160"
              height="12"
              fill={color("grey", "000")}
            />
            <rect
              x="560"
              y="242.5"
              width="21"
              height="12"
              fill={color("grey", "000")}
            />
            <g filter="url(#filter11_i_5_1528)">
              <path
                d="M301 294H593V324C593 326.209 591.209 328 589 328H305C302.791 328 301 326.209 301 324V294Z"
                fill="#F5F8FC"
              />
              <rect
                x="313"
                y="305"
                width="160"
                height="12"
                fill={color("grey", "000")}
              />
              <path
                d="M574.146 306.647C573.951 306.842 573.951 307.159 574.146 307.354L577.792 311L574.146 314.646C573.951 314.841 573.951 315.158 574.146 315.353C574.341 315.548 574.658 315.548 574.853 315.353L578.853 311.353C578.951 311.255 578.999 311.127 578.999 310.999C578.999 310.871 578.95 310.743 578.853 310.645L574.853 306.645C574.658 306.452 574.342 306.452 574.146 306.647Z"
                fill={color("grey", "000")}
              />
            </g>
            <rect
              x="300.5"
              y="0.5"
              width="293"
              height="328"
              rx="4.5"
              stroke="#CFD6DB"
            />
          </g>
          <g opacity="0.94">
            <rect
              x="900.5"
              y="0.5"
              width="293"
              height="328"
              rx="4.5"
              fill="white"
            />
            <g filter="url(#filter12_i_5_1528)">
              <path
                d="M901 5C901 2.79086 902.791 1 905 1H1189C1191.21 1 1193 2.79086 1193 5V93H901V5Z"
                fill="white"
              />
              <path d="M913 13H1013V25H913V13Z" fill={color("grey", "000")} />
              <rect
                x="913"
                y="37"
                width="45"
                height="12"
                fill={color("grey", "000")}
              />
              <circle cx="923" cy="71" r="10" fill={color("grey", "000")} />
              <rect
                x="937"
                y="65"
                width="21"
                height="12"
                fill={color("grey", "000")}
              />
              <rect
                x="1164"
                y="62.5"
                width="17"
                height="17"
                fill={color("grey", "000")}
              />
              <rect
                x="1122"
                y="62.5"
                width="17"
                height="17"
                fill={color("grey", "000")}
              />
              <rect
                x="1143"
                y="62.5"
                width="17"
                height="17"
                fill={color("grey", "000")}
              />
            </g>
            <rect
              width="66"
              height="67"
              transform="translate(901 93)"
              fill="white"
            />
            <rect
              x="913"
              y="105"
              width="42"
              height="42"
              fill={color("grey", "000")}
            />
            <g filter="url(#filter13_i_5_1528)">
              <rect
                width="226"
                height="67"
                transform="translate(967 93)"
                fill="white"
              />
              <rect
                x="967"
                y="108.5"
                width="120"
                height="12"
                fill={color("grey", "000")}
              />
              <rect
                x="967"
                y="132.5"
                width="160"
                height="12"
                fill={color("grey", "000")}
              />
              <rect
                x="1160"
                y="108.5"
                width="21"
                height="12"
                fill={color("grey", "000")}
              />
            </g>
            <rect
              width="66"
              height="67"
              transform="translate(901 160)"
              fill="white"
            />
            <rect
              x="913"
              y="172"
              width="42"
              height="42"
              fill={color("grey", "000")}
            />
            <g filter="url(#filter14_i_5_1528)">
              <rect
                width="226"
                height="67"
                transform="translate(967 160)"
                fill="white"
              />
              <rect
                x="967"
                y="175.5"
                width="120"
                height="12"
                fill={color("grey", "000")}
              />
              <rect
                x="967"
                y="199.5"
                width="160"
                height="12"
                fill={color("grey", "000")}
              />
              <rect
                x="1160"
                y="175.5"
                width="21"
                height="12"
                fill={color("grey", "000")}
              />
            </g>
            <rect
              width="66"
              height="67"
              transform="translate(901 227)"
              fill="white"
            />
            <rect
              x="913"
              y="239"
              width="42"
              height="42"
              fill={color("grey", "000")}
            />
            <rect
              width="226"
              height="67"
              transform="translate(967 227)"
              fill="white"
            />
            <rect
              x="967"
              y="242.5"
              width="120"
              height="12"
              fill={color("grey", "000")}
            />
            <rect
              x="967"
              y="266.5"
              width="160"
              height="12"
              fill={color("grey", "000")}
            />
            <rect
              x="1160"
              y="242.5"
              width="21"
              height="12"
              fill={color("grey", "000")}
            />
            <g filter="url(#filter15_i_5_1528)">
              <path
                d="M901 294H1193V324C1193 326.209 1191.21 328 1189 328H905C902.791 328 901 326.209 901 324V294Z"
                fill="#F5F8FC"
              />
              <rect
                x="913"
                y="305"
                width="160"
                height="12"
                fill={color("grey", "000")}
              />
              <path
                d="M1174.15 306.647C1173.95 306.842 1173.95 307.159 1174.15 307.354L1177.79 311L1174.15 314.646C1173.95 314.841 1173.95 315.158 1174.15 315.353C1174.34 315.548 1174.66 315.548 1174.85 315.353L1178.85 311.353C1178.95 311.255 1179 311.127 1179 310.999C1179 310.871 1178.95 310.743 1178.85 310.645L1174.85 306.645C1174.66 306.452 1174.34 306.452 1174.15 306.647Z"
                fill={color("grey", "000")}
              />
            </g>
            <rect
              x="900.5"
              y="0.5"
              width="293"
              height="328"
              rx="4.5"
              stroke="#CFD6DB"
            />
          </g>
          <defs>
            <filter
              id="filter0_i_5_1528"
              x="1"
              y="1"
              width="292"
              height="92"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="BackgroundImageFix"
                result="shape"
              />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="-1" />
              <feComposite
                in2="hardAlpha"
                operator="arithmetic"
                k2="-1"
                k3="1"
              />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.811765 0 0 0 0 0.839216 0 0 0 0 0.858824 0 0 0 1 0"
              />
              <feBlend
                mode="normal"
                in2="shape"
                result="effect1_innerShadow_5_1528"
              />
            </filter>
            <filter
              id="filter1_i_5_1528"
              x="67"
              y="93"
              width="226"
              height="67"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="BackgroundImageFix"
                result="shape"
              />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="-1" />
              <feComposite
                in2="hardAlpha"
                operator="arithmetic"
                k2="-1"
                k3="1"
              />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.811765 0 0 0 0 0.839216 0 0 0 0 0.858824 0 0 0 1 0"
              />
              <feBlend
                mode="normal"
                in2="shape"
                result="effect1_innerShadow_5_1528"
              />
            </filter>
            <filter
              id="filter2_i_5_1528"
              x="67"
              y="160"
              width="226"
              height="67"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="BackgroundImageFix"
                result="shape"
              />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="-1" />
              <feComposite
                in2="hardAlpha"
                operator="arithmetic"
                k2="-1"
                k3="1"
              />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.811765 0 0 0 0 0.839216 0 0 0 0 0.858824 0 0 0 1 0"
              />
              <feBlend
                mode="normal"
                in2="shape"
                result="effect1_innerShadow_5_1528"
              />
            </filter>
            <filter
              id="filter3_i_5_1528"
              x="1"
              y="294"
              width="292"
              height="34"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="BackgroundImageFix"
                result="shape"
              />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="1" />
              <feComposite
                in2="hardAlpha"
                operator="arithmetic"
                k2="-1"
                k3="1"
              />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.811765 0 0 0 0 0.839216 0 0 0 0 0.858824 0 0 0 1 0"
              />
              <feBlend
                mode="normal"
                in2="shape"
                result="effect1_innerShadow_5_1528"
              />
            </filter>
            <filter
              id="filter4_i_5_1528"
              x="601"
              y="1"
              width="292"
              height="92"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="BackgroundImageFix"
                result="shape"
              />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="-1" />
              <feComposite
                in2="hardAlpha"
                operator="arithmetic"
                k2="-1"
                k3="1"
              />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.811765 0 0 0 0 0.839216 0 0 0 0 0.858824 0 0 0 1 0"
              />
              <feBlend
                mode="normal"
                in2="shape"
                result="effect1_innerShadow_5_1528"
              />
            </filter>
            <filter
              id="filter5_i_5_1528"
              x="667"
              y="93"
              width="226"
              height="67"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="BackgroundImageFix"
                result="shape"
              />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="-1" />
              <feComposite
                in2="hardAlpha"
                operator="arithmetic"
                k2="-1"
                k3="1"
              />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.811765 0 0 0 0 0.839216 0 0 0 0 0.858824 0 0 0 1 0"
              />
              <feBlend
                mode="normal"
                in2="shape"
                result="effect1_innerShadow_5_1528"
              />
            </filter>
            <filter
              id="filter6_i_5_1528"
              x="667"
              y="160"
              width="226"
              height="67"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="BackgroundImageFix"
                result="shape"
              />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="-1" />
              <feComposite
                in2="hardAlpha"
                operator="arithmetic"
                k2="-1"
                k3="1"
              />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.811765 0 0 0 0 0.839216 0 0 0 0 0.858824 0 0 0 1 0"
              />
              <feBlend
                mode="normal"
                in2="shape"
                result="effect1_innerShadow_5_1528"
              />
            </filter>
            <filter
              id="filter7_i_5_1528"
              x="601"
              y="294"
              width="292"
              height="34"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="BackgroundImageFix"
                result="shape"
              />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="1" />
              <feComposite
                in2="hardAlpha"
                operator="arithmetic"
                k2="-1"
                k3="1"
              />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.811765 0 0 0 0 0.839216 0 0 0 0 0.858824 0 0 0 1 0"
              />
              <feBlend
                mode="normal"
                in2="shape"
                result="effect1_innerShadow_5_1528"
              />
            </filter>
            <filter
              id="filter8_i_5_1528"
              x="301"
              y="1"
              width="292"
              height="92"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="BackgroundImageFix"
                result="shape"
              />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="-1" />
              <feComposite
                in2="hardAlpha"
                operator="arithmetic"
                k2="-1"
                k3="1"
              />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.811765 0 0 0 0 0.839216 0 0 0 0 0.858824 0 0 0 1 0"
              />
              <feBlend
                mode="normal"
                in2="shape"
                result="effect1_innerShadow_5_1528"
              />
            </filter>
            <filter
              id="filter9_i_5_1528"
              x="367"
              y="93"
              width="226"
              height="67"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="BackgroundImageFix"
                result="shape"
              />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="-1" />
              <feComposite
                in2="hardAlpha"
                operator="arithmetic"
                k2="-1"
                k3="1"
              />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.811765 0 0 0 0 0.839216 0 0 0 0 0.858824 0 0 0 1 0"
              />
              <feBlend
                mode="normal"
                in2="shape"
                result="effect1_innerShadow_5_1528"
              />
            </filter>
            <filter
              id="filter10_i_5_1528"
              x="367"
              y="160"
              width="226"
              height="67"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="BackgroundImageFix"
                result="shape"
              />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="-1" />
              <feComposite
                in2="hardAlpha"
                operator="arithmetic"
                k2="-1"
                k3="1"
              />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.811765 0 0 0 0 0.839216 0 0 0 0 0.858824 0 0 0 1 0"
              />
              <feBlend
                mode="normal"
                in2="shape"
                result="effect1_innerShadow_5_1528"
              />
            </filter>
            <filter
              id="filter11_i_5_1528"
              x="301"
              y="294"
              width="292"
              height="34"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="BackgroundImageFix"
                result="shape"
              />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="1" />
              <feComposite
                in2="hardAlpha"
                operator="arithmetic"
                k2="-1"
                k3="1"
              />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.811765 0 0 0 0 0.839216 0 0 0 0 0.858824 0 0 0 1 0"
              />
              <feBlend
                mode="normal"
                in2="shape"
                result="effect1_innerShadow_5_1528"
              />
            </filter>
            <filter
              id="filter12_i_5_1528"
              x="901"
              y="1"
              width="292"
              height="92"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="BackgroundImageFix"
                result="shape"
              />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="-1" />
              <feComposite
                in2="hardAlpha"
                operator="arithmetic"
                k2="-1"
                k3="1"
              />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.811765 0 0 0 0 0.839216 0 0 0 0 0.858824 0 0 0 1 0"
              />
              <feBlend
                mode="normal"
                in2="shape"
                result="effect1_innerShadow_5_1528"
              />
            </filter>
            <filter
              id="filter13_i_5_1528"
              x="967"
              y="93"
              width="226"
              height="67"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="BackgroundImageFix"
                result="shape"
              />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="-1" />
              <feComposite
                in2="hardAlpha"
                operator="arithmetic"
                k2="-1"
                k3="1"
              />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.811765 0 0 0 0 0.839216 0 0 0 0 0.858824 0 0 0 1 0"
              />
              <feBlend
                mode="normal"
                in2="shape"
                result="effect1_innerShadow_5_1528"
              />
            </filter>
            <filter
              id="filter14_i_5_1528"
              x="967"
              y="160"
              width="226"
              height="67"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="BackgroundImageFix"
                result="shape"
              />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="-1" />
              <feComposite
                in2="hardAlpha"
                operator="arithmetic"
                k2="-1"
                k3="1"
              />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.811765 0 0 0 0 0.839216 0 0 0 0 0.858824 0 0 0 1 0"
              />
              <feBlend
                mode="normal"
                in2="shape"
                result="effect1_innerShadow_5_1528"
              />
            </filter>
            <filter
              id="filter15_i_5_1528"
              x="901"
              y="294"
              width="292"
              height="34"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="BackgroundImageFix"
                result="shape"
              />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="1" />
              <feComposite
                in2="hardAlpha"
                operator="arithmetic"
                k2="-1"
                k3="1"
              />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.811765 0 0 0 0 0.839216 0 0 0 0 0.858824 0 0 0 1 0"
              />
              <feBlend
                mode="normal"
                in2="shape"
                result="effect1_innerShadow_5_1528"
              />
            </filter>
          </defs>
        </svg>
      </Container>
    );
  }
}
