export const BASE_QA_LABEL = "tracks-az";
export const LETTER_BLOCK = "letter-block";
export const CONTAINER = "container";
export const TITLE = "title";
export const COLLAPSIBLE = "collapsible";
export const SECTION = "section";
export const LIST = "list";
export const CONTENT = "content";
export const SUMMARY = "summary";
export const ICON = "icon";
export const TRACK = "track";
export const RACE = "race";
export const RESULTED = "resulted";
export const FAVORITE = "favorite";
export const NON_FAVORITE = "non-favorite";
export const EMPTY = "empty";
export const HINT = "hint";
export const DRAWER = "drawer";
export const OVERLAY = "overlay";
export const CHECKBOX = "checkbox";
export const FILTER = "filter";
export const ACTION = "action";
export const LINK_ITEM = "link-item";
export const LOCATION = "location";
export const FOOTER = "footer";
