import React, { MutableRefObject, RefObject, useRef, useState } from "react";
import mediator from "@tvg/mediator";
import { SafeAreaProvider } from "react-native-safe-area-context";
import AccountMenu from "@urp/account-menu";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "@tvg/sh-lib-account-wallet/src/session/logout";
import { get } from "lodash";
import { useTransition, config, animated } from "@react-spring/web";
import parseJSONCapiMessage from "@tvg/utils/capiUtils";
import { useGwDeskMenuRoutes } from "@urp/account-menu/src/hooks";
import { useNavigate } from "react-router";
import { isAccountCompliantSelector } from "@tvg/sh-utils/sessionUtils";
import useOnClickOutside from "@tvg/design-system/src/hooks/useOnClickOutside";
import { isTransactionHistoryModalOpen } from "@urp/transaction-history/src/redux/selectors";
import {
  openTransactionHistoryModal,
  closeTransactionHistoryModal
} from "@urp/transaction-history/src/redux/actions";
import { isFDR } from "@tvg/utils/generalUtils";
import { TransactionHistoryModal } from "./MyAccountLinkSection/TransactionHistoryModal";
import {
  MainContainer,
  ContentContainer,
  Container
} from "./styled-components";

import MyAccountMenu from "./MyAccountMenu";
import MyAccountMainButton from "./MyAccountMainButton";

const MyAccountButton = () => {
  const [openMyMenu, setOpenMyMenu] = useState(false);
  const dispatch = useDispatch();
  const enableDepositOnSinglePage = useSelector((store) =>
    get(store, "capi.featureToggles.enableDepositOnSinglePage", false)
  );
  const isTransactionHistoryModalOpened = useSelector(
    isTransactionHistoryModalOpen
  );
  const toggles = useSelector((store) => get(store, "capi.featureToggles"));
  const transactionHistoryToggle = get(toggles, "transactionHistory", false);
  const ref: MutableRefObject<HTMLDivElement | undefined> = useRef();
  const navigate = useNavigate();
  const isAccountCompliant = useSelector(isAccountCompliantSelector);
  const disableGWWallet = useSelector((store) =>
    get(store, "capi.featureToggles.disableGWallet", true)
  );

  const deskMenuConfiguration = useSelector((store) =>
    parseJSONCapiMessage(store, "capi.messages.accountPanelMenuLinks", {
      mainLinks: [],
      userAccountLinks: []
    })
  );

  const updateMenuStatus = () => {
    if (!openMyMenu) {
      mediator.base.dispatch({
        type: "NAVIGATION:MENU_CLICK",
        payload: {
          action: "Account Panel Opened",
          module: "Global Header"
        }
      });
    }

    setOpenMyMenu(!openMyMenu);
  };

  if (isFDR()) {
    useOnClickOutside(ref as RefObject<HTMLElement>, () => {
      if (!isTransactionHistoryModalOpened) updateMenuStatus();
    });
  }

  const { deskAccountLinks } = isFDR()
    ? useGwDeskMenuRoutes(
        [
          ...deskMenuConfiguration.mainLinks,
          ...deskMenuConfiguration.userAccountLinks
        ],
        updateMenuStatus,
        navigate,
        isAccountCompliant,
        dispatch
      )
    : { deskAccountLinks: [] };

  const onTransactionHistoryClick = () =>
    dispatch(openTransactionHistoryModal());

  const changeMenuStateValue = (value: boolean) => {
    if (value) {
      mediator.base.dispatch({
        type: "NAVIGATION:MENU_CLICK",
        payload: {
          action: "Account Panel Opened",
          module: "Global Header"
        }
      });
    }

    requestAnimationFrame(() => {
      setOpenMyMenu(value);
    });
  };

  const AnimatedContent = animated(ContentContainer);
  const menuTransition = useTransition(openMyMenu, {
    config: openMyMenu ? { ...config.stiff } : { duration: 150 },
    from: { opacity: 0, transform: `translate3d(0px, 10px, 0px)` },
    enter: { opacity: 1, transform: `translate3d(0px, 0px, 0px)` },
    leave: { opacity: 0, transform: `translate3d(0px, 10px, 0px)` }
  });

  const renderMenu = () =>
    menuTransition(
      (styles, isMenuOpen) =>
        isMenuOpen && (
          <>
            <AnimatedContent data-qa-label="myAccountMenu" style={styles}>
              <MainContainer ref={ref as MutableRefObject<HTMLDivElement>}>
                <AccountMenu
                  feedbackUrl="mailto:support@tvg.com"
                  isExternalDeposit={enableDepositOnSinglePage}
                  device="device"
                  dispatch={dispatch}
                  onLogout={logout}
                  onTransactionHistoryClick={onTransactionHistoryClick}
                  deskAccountLinks={deskAccountLinks}
                />
              </MainContainer>
            </AnimatedContent>

            <TransactionHistoryModal
              isOpen={isTransactionHistoryModalOpened}
              onClose={() => {
                dispatch(closeTransactionHistoryModal());
              }}
              isTransactionHistoryToggleOn={transactionHistoryToggle}
            />
          </>
        )
    );

  const renderButton = () => (
    <Container>
      <MyAccountMainButton
        openMyMenu={openMyMenu}
        setOpenMyMenu={changeMenuStateValue}
      />
      {isFDR() && !disableGWWallet ? (
        renderMenu()
      ) : (
        <MyAccountMenu isOpen={openMyMenu} setIsOpen={changeMenuStateValue} />
      )}
    </Container>
  );

  return typeof window !== "undefined" ? (
    <SafeAreaProvider>{renderButton()}</SafeAreaProvider>
  ) : (
    renderButton()
  );
};

export default MyAccountButton;
