// @flow
import styled from "styled-components";
import buildColor from "@fdr/static-ui/ColorPalette";
import { AccountLinkBaseStyles } from "../../_molecules/AccountMenuLink/styled-components";
import {
  LinksGroupBaseStyles,
  HeaderBaseStyles
} from "../../_organisms/LinksGroup/styled-components";
import { buildText } from "../../_atoms/Text";

export const AccountSection = styled.section`
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${buildColor("grey", "100")};
`;

export const ActionsWrapper = styled.div`
  padding: 16px;
  display: flex;
  align-items: center;

  & button:first-of-type,
  a:first-of-type {
    margin-right: 9px;
  }
`;

export const LinksSection = styled.div`
  margin-bottom: 16px;
`;

export const AccountLinkGroup = styled.ul`
  ${LinksGroupBaseStyles};
`;

export const HeaderText = styled.div`
  ${HeaderBaseStyles};
`;

const LinkTextStyles = buildText({
  tag: "span",
  fontSize: 14,
  color: buildColor("blue"),
  qaLabel: "account-link-text"
});

export const LinkText = styled(LinkTextStyles)`
  line-height: 1;
  display: flex;
  align-items: center;
  margin: 16px 0;
`;

export const AccountLinkRow = styled.div`
  ${AccountLinkBaseStyles};
  cursor: pointer;
  justify-content: space-between;
  height: 48px;
  color: ${(props) => (props.isDanger ? buildColor("red") : "inherit")};

  ${LinkText} {
    color: ${(props) =>
      props.isDanger ? buildColor("red") : buildColor("blue")};
  }
`;

export const Footer = styled.div`
  padding: 16px 0 32px 0;
`;
