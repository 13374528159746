import styled from "styled-components";

export const RaceTracksContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  & > div > div::before {
    display: none;
  }
`;
