// @flow
import mediatorChannels from "@tvg/mediator";
import pushToDataLayer from "../gtmUtils";

export default () => {
  mediatorChannels.base.subscribe(
    "TUTORIALS:NAVIGATE",
    (data: { payload: { destination: string } }) => {
      pushToDataLayer({
        event: "navigation",
        gaEventCategory: "Navigation",
        gaEventAction: "Navigated to",
        gaEventLabel: "Tutorials",
        menu: "More",
        module: "More",
        sport: undefined,
        microApp: "non-MicroApp",
        tag: undefined,
        destinationUrl: data.payload.destination
      });
    }
  );

  mediatorChannels.base.subscribe(
    "TUTORIALS:OPEN",
    (data: { payload: { tutorialName: string } }) => {
      pushToDataLayer({
        event: "siteClick",
        gaEventCategory: "Site Click",
        gaEventAction: "Open",
        gaEventLabel: data.payload.tutorialName,
        module: "Tutorials",
        tag: undefined,
        microApp: undefined
      });
    }
  );
};
