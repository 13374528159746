// @flow
import React, { Component } from "react";
import { noop, get } from "lodash";
import { openExternalLink } from "@tvg/sh-utils/mobileUtils";
import { isTvg5 } from "@tvg/utils/generalUtils";
import { type Transaction } from "@tvg/types/Account";
import type { BinaryFn, UnaryFn, NullaryFn } from "@tvg/types/Functional";
import {
  warning,
  emptyFile,
  arrowLeft,
  arrowRight
} from "@fdr/static-ui/Icons/icons";
import Icon from "@fdr/static-ui/Icons";
import { TransactionHistoryMask } from "@fdr/static-ui/Masks";
import FullPageMessage from "../../_molecules/FullPageMessage";
import DefaultButton from "../../_atoms/ButtonDefault";
import Button from "../../_atoms/Button";

import TransactionHistoryList from "../../_organisms/TransactionHistoryList";

import {
  AccountTransactionsButtonContainer,
  AccountTransactionContainer,
  MessageIconContainer,
  IconContainer,
  TextContainer,
  MessageTextContainer,
  AccountExternalLink,
  TransactionHistoryContainer,
  TransactionsHeader,
  HeaderText,
  HeaderBalance,
  TransactionFooter,
  PageIndicator
} from "./styled-components";

export type Props = {
  onExternalLinkCallback: BinaryFn<string, Event, void>,
  transactionHistoryToggle: boolean,
  transactions: Transaction[],
  balance: number,
  page: number,
  totalPages: number,
  changePage: UnaryFn<number, void>,
  isLoading: boolean,
  onClose?: NullaryFn<void>,
  globalFDRSupportLink?: string
};

export default class AccountTransactions extends Component<Props> {
  static defaultProps = {
    onExternalLinkCallback: noop,
    transactionHistoryToggle: false,
    transactions: [],
    balance: 0,
    page: 1,
    totalPages: 1,
    changePage: noop,
    isLoading: true,
    onClose: noop,
    globalFDRSupportLink: ""
  };

  openLiveChat = (event: SyntheticEvent<HTMLButtonElement>) => {
    // $FlowFixMe: Casting SyntheticEvent to Event
    this.props.onExternalLinkCallback("/redirectEngine", event);
  };

  transactionsHistory = () => {
    const balance =
      get(this.props, "balance", 0) !== null
        ? get(this.props, "balance", 0).toFixed(2)
        : /* istanbul ignore next */
          Number(0).toFixed(2);

    return (
      <TransactionHistoryContainer>
        <TransactionsHeader>
          <HeaderText type="label">All Transactions</HeaderText>
          <HeaderBalance>
            <HeaderText type="label">Balance</HeaderText>
            <HeaderText type="value">${balance}</HeaderText>
          </HeaderBalance>
        </TransactionsHeader>
        <TransactionHistoryList transactions={this.props.transactions} />
        {this.props.totalPages > 1 && this.renderFooter()}
      </TransactionHistoryContainer>
    );
  };

  renderFooter = () => (
    <TransactionFooter>
      <DefaultButton
        isDisabled={this.props.page === 1}
        onClick={() => this.props.changePage(this.props.page - 1)}
      >
        <Icon icon={arrowLeft} size={20} /> Previous
      </DefaultButton>
      <PageIndicator>
        {this.props.page}/{this.props.totalPages}
      </PageIndicator>
      <DefaultButton
        isDisabled={this.props.page === this.props.totalPages}
        onClick={() => this.props.changePage(this.props.page + 1)}
      >
        Next <Icon icon={arrowRight} size={20} />
      </DefaultButton>
    </TransactionFooter>
  );

  renderEmptyCase = () => (
    <FullPageMessage textTitle="" textBody="" qaLabel="bet-container-messages">
      <MessageIconContainer>
        <IconContainer
          size={60}
          bgColor={{ name: "grey", value: 100 }}
          iconColor={{ name: "grey", value: 800 }}
        >
          <Icon
            icon={emptyFile}
            size={24}
            qaLabel="account-transaction-main-icon"
          />
        </IconContainer>
        <IconContainer
          size={20}
          bgColor={{ name: "blue", value: 300 }}
          iconColor={{ name: "white", value: 100 }}
          isBadge
        >
          <Icon
            icon={warning}
            size={12}
            qaLabel="account-transaction-main-icon"
          />
        </IconContainer>
      </MessageIconContainer>
      <MessageTextContainer>
        <TextContainer isBold>No transaction history</TextContainer>
        <TextContainer>
          Looks like you haven’t done any transactions in this account
        </TextContainer>
      </MessageTextContainer>
      <AccountTransactionsButtonContainer>
        <Button
          size="big"
          type="secondary"
          onClick={this.props.onClose}
          text="Return to Account"
          qaLabel="account-transactions-return-btn"
          isStretched
          isInternalLink
        />
      </AccountTransactionsButtonContainer>
    </FullPageMessage>
  );

  renderUnavailableState = () => (
    <FullPageMessage
      textTitle=""
      textBody=""
      qaLabel="transaction-container-messages"
    >
      <MessageIconContainer>
        <IconContainer
          size={60}
          bgColor={{ name: "grey", value: 100 }}
          iconColor={{ name: "grey", value: 800 }}
        >
          <Icon
            icon={emptyFile}
            size={24}
            qaLabel="account-transaction-main-icon"
          />
        </IconContainer>
        <IconContainer
          size={20}
          bgColor={{ name: "blue", value: 300 }}
          iconColor={{ name: "white", value: 100 }}
          isBadge
        >
          <Icon
            icon={warning}
            size={12}
            qaLabel="account-transaction-main-icon"
          />
        </IconContainer>
      </MessageIconContainer>
      <MessageTextContainer>
        <TextContainer isBold>Account transactions coming soon</TextContainer>
        <TextContainer>
          If you have any question about your account, please message our Live
          Support team here (linking to Live Person) or email&nbsp;
          <AccountExternalLink
            onTouchStart={noop}
            data-qa-label="account-transactions-mail-link"
            url="mailto:racing@fanduel.com"
            onClick={(e: Event) => {
              this.props.onExternalLinkCallback("mailto:racing@fanduel.com", e);
            }}
          >
            racing@fanduel.com
          </AccountExternalLink>
        </TextContainer>
      </MessageTextContainer>
      <AccountTransactionsButtonContainer>
        <Button
          size="big"
          type="primary"
          onClick={
            isTvg5()
              ? () => openExternalLink(this.props.globalFDRSupportLink)
              : this.openLiveChat
          }
          text="Message Us"
          qaLabel="account-transactions-messages-us-btn"
          isStretched
        />
        <Button
          size="big"
          type="secondary"
          onClick={this.props.onClose}
          text="Return to Account"
          qaLabel="account-transactions-return-btn"
          isStretched
          isInternalLink
        />
      </AccountTransactionsButtonContainer>
    </FullPageMessage>
  );

  renderTransationHistory = () => {
    if (this.props.isLoading) {
      return <TransactionHistoryMask />;
    }
    return this.props.transactions.length > 0
      ? this.transactionsHistory()
      : this.renderEmptyCase();
  };

  render() {
    return (
      <AccountTransactionContainer
        isEmpty={!this.props.transactions.length}
        isLoading={this.props.isLoading}
      >
        {this.props.transactionHistoryToggle
          ? this.renderTransationHistory()
          : this.renderUnavailableState()}
      </AccountTransactionContainer>
    );
  }
}
