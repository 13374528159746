// @flow

import styled, { css } from "styled-components";
import { fontCondensed, fontNormal } from "@fdr/static-ui/Typography";
import buildColor from "@fdr/static-ui/ColorPalette";

export const TagLabel = styled.span`
  font-family: ${fontCondensed};
  font-size: ${({ fontSize }) => fontSize}px;
  white-space: nowrap;
  text-align: center;
  letter-spacing: 1px;
  font-weight: normal;
  height: ${({ fontSize }) => fontSize + 5}px;
  padding: 0 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  text-transform: uppercase;
`;

export const PromoTagContainer = styled.div`
  background-color: ${buildColor("yellow", "500")};
  color: ${buildColor("grey", "D4")};
  ${({ isCollapsed }) =>
    isCollapsed &&
    css`
      min-width: 16px;
    `};
  height: ${({ size }) => size}px;
  flex-shrink: 1;
  border-radius: 2px;
`;

export const OptedInTagContainer = styled.div`
  background-color: ${buildColor("yellow", "500")};
  color: ${buildColor("grey", "D4")};
  height: ${({ size }) => size}px;
  flex-shrink: 1;
  border-radius: 2px;
  position: relative;

  &::before {
    content: "";
    background-color: ${buildColor("blue", "500")};
    border-radius: 50%;
    height: 8px;
    width: 8px;
    position: absolute;
    right: -1px;
    box-sizing: border-box;
    border: 1px solid ${buildColor("white", "100")};
    transform: translate(30%, -35%);
  }
`;

export const TransparentTagContainer = styled.div`
  background-color: ${buildColor("white", "00")};
  color: ${buildColor("navy", "700")};
  height: 16px;
  flex-shrink: 1;
  border-radius: 2px;
`;

export const CoupledTagContainer = styled.div`
  background-color: ${buildColor("grey", "100")};
  color: ${buildColor("navy", "700")};
  border-radius: 2px;
  font-family: ${fontNormal};
`;

export const EasyTagContainer = styled.div`
  width: fit-content;
  ${({ useNewLayout }) =>
    useNewLayout
      ? css`
          background-color: ${buildColor("green", "000")};
          color: ${buildColor("green", "800")};
          border-radius: 2px;
        `
      : css`
          background-color: ${buildColor("green", "D2")};
          border-radius: 2px;
          color: ${buildColor("white", "100")};
        `};
`;

export const HardTagContainer = styled.div`
  width: fit-content;
  ${({ useNewLayout }) =>
    useNewLayout
      ? css`
          background-color: ${buildColor("orange", "000")};
          color: ${buildColor("orange", "800")};
          border-radius: 2px;
        `
      : css`
          background-color: ${buildColor("orange", "500")};
          color: ${buildColor("navy", "700")};
        `};
`;

export const VeryHardTagContainer = styled.div`
  width: fit-content;
  ${({ useNewLayout }) =>
    useNewLayout
      ? css`
          background-color: ${buildColor("red", "000")};
          color: ${buildColor("red", "700")};
          border-radius: 2px;
        `
      : css`
          background-color: ${buildColor("red", "D2")};
          color: ${buildColor("white", "100")};
          border-radius: 2px;
        `};
`;

export const AverageTagContainer = styled.div`
  background-color: ${buildColor("orange", "D2")};
  color: ${buildColor("white", "100")};
  width: fit-content;
  border-radius: 2px;
`;
