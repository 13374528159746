import { useSelector } from "react-redux";
import { getIsLogged } from "@urp/store-selectors";
import { useMemo } from "react";
import useExperiments from "../../useExperiments";
import { DEPLOYMENT_KEY, EXPERIMENT_KEY } from "./constants";
import { VariantEnum, AutoPlayConfig } from "./types";
import { parseResponse } from "./utils";

export const useAutoPlayPPVideos = () => {
  const { getVariant, experiment } = useExperiments(DEPLOYMENT_KEY, {
    automaticExposureTracking: false
  });
  const isLogged = useSelector(getIsLogged);

  // Manually control exposure event
  const variant = useMemo(() => {
    const result = getVariant<VariantEnum, number>(EXPERIMENT_KEY);
    if (result?.value !== VariantEnum.OFF) experiment.exposure(EXPERIMENT_KEY);

    return result;
  }, [isLogged]);

  return parseResponse(variant);
};

export { AutoPlayConfig };
