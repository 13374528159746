import { get } from "lodash";
import { getPortByBrand } from "@tvg/utils/generalUtils";
import parseCapiMessage from "@tvg/utils/capiUtils";
import { ApplicationState, WelcomeModalMessage } from "../types";

export const getStoreUserData = (store: ApplicationState) =>
  get(store, "userData");

export const getFeatureToggles = (store: ApplicationState) =>
  get(store, "capi.featureToggles");

export const getStoreFeatures = (store: ApplicationState) =>
  get(store, "capi.features");

export const getMessages = (store: ApplicationState) =>
  get(store, "capi.messages");

export const getHasRequestedLogin = (store: ApplicationState) =>
  get(store, "userData.hasRequested", false);

export const getOptedInPromos = (store: ApplicationState) =>
  get(store, "userData.optedInPromos");

export const getReturningUser = (store: ApplicationState) =>
  get(store, "userData.returningUser");

export const getDefaultPromoId = (store: ApplicationState) =>
  get(store, "capi.messages.ReferAFriendDefaultPromoId");

export const getWagerProfile = (store: ApplicationState) =>
  get(store, "userData.user.profile", getPortByBrand());

export const getMtpStatus = (store: ApplicationState) =>
  get(store, "mtpStatus.mtpStatus");

export const getIsEmailReferralOpen = (store: ApplicationState) =>
  get(store, "app.isEmailReferralOpen", false);

export const getPageTitle = (store: ApplicationState) =>
  get(store, "app.pageTitle");

export const getReloadApp = (store: ApplicationState) =>
  get(store, "app.reloadApp");

export const getDateRace = (store: ApplicationState) =>
  get(store, "mtpStatus.raceDate");

export const getGeolocation = (store: ApplicationState) =>
  get(store, "geolocation");

export const getAmplitudeToggle = (store: ApplicationState) =>
  get(store, "capi.featureToggles.useAmplitude");

export const getWelcomeModalContent = (
  store: ApplicationState
): WelcomeModalMessage =>
  parseCapiMessage(store, "capi.messages.welcomeModalToNewUsers", {
    title: "Welcome to the new TVG!"
  });

export const enableURPWatchFDTV = (store: ApplicationState) =>
  get(store, "capi.featureToggles.enableURPWatchFDTV");

export const getSeoRacesTitle = (store: ApplicationState) =>
  get(store, "capi.messages.seoContentRacesTitle");

export const getSeoRacesContent = (store: ApplicationState) =>
  get(store, "capi.messages.seoContentRaces");

export const getURPRacingScheduleToggle = (store: ApplicationState) =>
  get(store, "capi.featureToggles.useURPRacingSchedule");
