// @flow
import { get } from "lodash";

import tvgConf from "@tvg/conf";
import mediator from "@tvg/mediator";
import pushToDataLayer, { getSiteVersion } from "../../gtmUtils";

type FormCompletionGTMEventsType =
  | "REGISTRATION:CLICK_CONTINUE_BUTTON"
  | "REGISTRATION:FORM_STEP_SUCCESS"
  | "REGISTRATION:REGISTRATION_ATTEMPT"
  | "REGISTRATION:REGISTRATION_COMPLETE"
  | "REGISTRATION:REGISTRATION_FAILURE";

type FormCompletionGTM = {
  type: FormCompletionGTMEventsType,
  payload: {
    module?: string,
    verificationStatus?: boolean,
    accountId?: number,
    loginStatus?: boolean,
    residenceState?: string,
    promoCode?: string,
    errorCode?: number
  }
};

export default () => {
  const siteVersion = getSiteVersion(tvgConf().product);

  // When a user clicks the continue button with intention of moving to the next step of the form
  mediator.base.subscribe(
    "REGISTRATION:CLICK_CONTINUE_BUTTON",
    (data: FormCompletionGTM) => {
      pushToDataLayer({
        event: "registration",
        gaEventCategory: "Registration",
        gaEventAction: "Form Step Complete Attempt",
        gaEventLabel: undefined,
        module: get(data, "payload.module", "")
      });
    }
  );

  // When a user clicks continue in the form and the form validates
  mediator.base.subscribe(
    "REGISTRATION:FORM_STEP_SUCCESS",
    (data: FormCompletionGTM) => {
      pushToDataLayer({
        event: "registration",
        gaEventCategory: "Registration",
        gaEventAction: "Form Step Success",
        gaEventLabel: get(data, "payload.module", ""),
        module: get(data, "payload.module", "")
      });
    }
  );

  // When a user clicks Finish Registration in the Review part of the form
  mediator.base.subscribe(
    "REGISTRATION:REGISTRATION_ATTEMPT",
    (data: FormCompletionGTM) => {
      pushToDataLayer({
        event: "registration",
        gaEventCategory: "Registration",
        gaEventAction: "Registration Attempt",
        gaEventLabel: undefined,
        module: get(data, "payload.module", "")
      });
    }
  );

  // When a user completes registration (verified or unverified) and an account id is returned
  mediator.base.subscribe(
    "REGISTRATION:REGISTRATION_COMPLETE",
    (data: FormCompletionGTM) => {
      pushToDataLayer({
        event: "registration",
        gaEventCategory: "Registration",
        gaEventAction: "Registration Finish",
        gaEventLabel: get(data, "payload.verificationStatus")
          ? "Registration Success Verified"
          : "Registration Unverified",
        siteVersion,
        module: "Registration",
        accountId: get(data, "payload.accountId"),
        registrationStatus: get(data, "payload.verificationStatus")
          ? "Registered"
          : "Unregistered",
        loginStatus: get(data, "payload.loginStatus")
          ? "Logged In"
          : "Logged Out",
        residenceState: get(data, "payload.residenceState", ""),
        promoCode: get(data, "payload.promoCode")
          ? get(data, "payload.promoCode")
          : undefined
      });
    }
  );

  // When registration fails due to: duplicate email, SSN failure (including already exists), invalid wagering age
  mediator.base.subscribe(
    "REGISTRATION:REGISTRATION_FAILURE",
    (data: FormCompletionGTM) => {
      pushToDataLayer({
        event: "registration",
        gaEventCategory: "Registration",
        gaEventAction: "Registration Failure",
        gaEventLabel: get(data, "payload.errorCode"),
        siteVersion,
        module: "Registration"
      });
    }
  );

  // When a user begins to type on the form
  mediator.base.subscribe("REGISTRATION:FORM_SIGN_UP_START", () => {
    pushToDataLayer({
      event: "registration",
      gaEventCategory: "Registration",
      gaEventAction: "Registration Sign Up Start",
      gaEventLabel: undefined,
      siteVersion,
      module: "Personal Details"
    });
  });
};
