import React from "react";
import { Button, IconButton } from "@tvg/design-system";
import { IconButtonContainer } from "./styled-components";

interface MoreButtonProps {
  showMore: boolean;
  isNavCollapsed: boolean;
  onClick: () => void;
}

const MoreButton = ({ showMore, isNavCollapsed, onClick }: MoreButtonProps) => (
  <>
    {isNavCollapsed ? (
      <IconButtonContainer>
        <IconButton
          variant="tertiary_dark"
          onPress={onClick}
          qaLabel="show-more-icon-button"
          iconName={showMore ? "chevronUp" : "chevronDown"}
        />
      </IconButtonContainer>
    ) : (
      <Button
        variant="tertiary_dark"
        onPress={onClick}
        qaLabel="show-more-button"
        icon={showMore ? "chevronUp" : "chevronDown"}
        iconPosition="end"
        mt="space-2"
        ml="50px"
      >
        {showMore ? "Show less" : "Show more"}
      </Button>
    )}
  </>
);

export default MoreButton;
