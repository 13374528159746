// @flow
import { get } from "lodash";

import tvgConf from "@tvg/conf";
import mediator from "@tvg/mediator";
import pushToDataLayer, { getSiteVersion } from "../../gtmUtils";

type TVGPromoCodeGTMEventsType =
  | "REGISTRATION:PROMO_CODE_ENTER_PROMO_CODE"
  | "REGISTRATION:PROMO_CODE_APPLY"
  | "REGISTRATION:PROMO_CODE_APPLIED"
  | "REGISTRATION:PROMO_CODE_APPLIED_AUTOMATICALLY"
  | "REGISTRATION:PROMO_CODE_REMOVED"
  | "REGISTRATION:PROMO_CODE_EDITED"
  | "REGISTRATION:PROMO_CODE_MODAL_CLOSED";

type TVGPromoCodeGTM = {
  type: TVGPromoCodeGTMEventsType,
  payload: {
    providerName?: string,
    module?: string,
    promoCode?: string,
    eventLabel?: string
  }
};

export default () => {
  const siteVersion = getSiteVersion(tvgConf().product);

  // When a user clicks "Enter Promo Code" in the registration form
  mediator.base.subscribe(
    "REGISTRATION:PROMO_CODE_ENTER_PROMO_CODE",
    (data: TVGPromoCodeGTM) => {
      pushToDataLayer({
        event: "registration",
        gaEventCategory: "Registration",
        gaEventAction: "Field Modal Opened",
        gaEventLabel: "Promo Code",
        module: get(data, "payload.module", ""),
        siteVersion
      });
    }
  );

  // When a user clicks "Apply"
  mediator.base.subscribe(
    "REGISTRATION:PROMO_CODE_APPLY",
    (data: TVGPromoCodeGTM) => {
      pushToDataLayer({
        event: "registration",
        gaEventCategory: "Registration",
        gaEventAction: "Promo Code Attempt",
        gaEventLabel: undefined,
        module: get(data, "payload.module", ""),
        siteVersion
      });
    }
  );

  // When a user clicks continue and applies a promo code to the form
  mediator.base.subscribe(
    "REGISTRATION:PROMO_CODE_APPLIED",
    (data: TVGPromoCodeGTM) => {
      pushToDataLayer({
        event: "registration",
        gaEventCategory: "Registration",
        gaEventAction: "Promo Code Success",
        gaEventLabel: get(data, "payload.eventLabel", ""),
        module: get(data, "payload.module", ""),
        promoCode: get(data, "payload.promoCode", "").toUpperCase(),
        siteVersion
      });
    }
  );

  // When a user lands on a registration form and a promo code gets applied automatically
  mediator.base.subscribe(
    "REGISTRATION:PROMO_CODE_APPLIED_AUTOMATICALLY",
    (data: TVGPromoCodeGTM) => {
      pushToDataLayer({
        event: "registration",
        gaEventCategory: "Registration",
        gaEventAction: "Promo Code Success",
        gaEventLabel: `Deeplink - ${get(data, "payload.promoCode", "")}`,
        module: get(data, "payload.module", ""),
        promoCode: get(data, "payload.promoCode", "").toUpperCase(),
        siteVersion
      });
    }
  );

  // When a user clicks continue in the promo code modal with no promo code selected/validated and removes a promo code from the form
  mediator.base.subscribe(
    "REGISTRATION:PROMO_CODE_REMOVED",
    (data: TVGPromoCodeGTM) => {
      pushToDataLayer({
        event: "registration",
        gaEventCategory: "Registration",
        gaEventAction: "Promo Code Removed",
        gaEventLabel: get(data, "payload.eventLabel", ""),
        module: get(data, "payload.module", ""),
        promoCode: get(data, "payload.promoCode", "").toUpperCase(),
        siteVersion
      });
    }
  );

  // When a user changes a promo code and clicks continue in the promo code modal
  mediator.base.subscribe(
    "REGISTRATION:PROMO_CODE_EDITED",
    (data: TVGPromoCodeGTM) => {
      pushToDataLayer({
        event: "registration",
        gaEventCategory: "Registration",
        gaEventAction: "Promo Code Edited",
        gaEventLabel: get(data, "payload.eventLabel", ""),
        module: get(data, "payload.module", ""),
        promoCode: get(data, "payload.promoCode", "").toUpperCase(),
        siteVersion
      });
    }
  );

  // When a user closes the promo code modal
  mediator.base.subscribe(
    "REGISTRATION:PROMO_CODE_MODAL_CLOSED",
    (data: TVGPromoCodeGTM) => {
      pushToDataLayer({
        event: "registration",
        gaEventCategory: "Registration",
        gaEventAction: "Field Modal Closed",
        gaEventLabel: "Promo Code",
        module: "Promo Code"
      });
    }
  );
};
