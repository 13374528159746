import React, { memo } from "react";
import { buildQaLabel } from "@tvg/test-utils/utils";
import TrackLetterBlock from "../TrackLetterBlock";
import { Props } from "./types";
import TracksSection from "../TracksSection";
import { LIST } from "../../utils/constants";

const TracksAZList = ({
  title,
  groupByLetter,
  onTrackClickHandler,
  qaLabel = "",
  checkRacePromos
}: Props) => (
  <TracksSection title={title} qaLabel={buildQaLabel([qaLabel, LIST])}>
    {Object.keys(groupByLetter)
      .sort()
      .map((key) => (
        <TrackLetterBlock
          key={key}
          letter={key}
          groupedRacesByTrack={groupByLetter[`${key}`]}
          onTrackClickHandler={onTrackClickHandler}
          qaLabel={buildQaLabel([qaLabel, LIST])}
          checkRacePromos={checkRacePromos}
        />
      ))}
  </TracksSection>
);

export default memo(TracksAZList);
