import React from "react";
import { noop } from "lodash";
import { Modal, ModalType } from "@tvg/design-system";
import type { NullaryFn } from "@tvg/ts-types/Functional";
import { isTvg5 } from "@tvg/utils/generalUtils";
import TransactionHistory from "@urp/transaction-history/src/components/TransactionHistory";

export const TransactionHistoryModal = ({
  isOpen,
  onClose,
  isTransactionHistoryToggleOn
}: {
  isOpen: boolean;
  onClose: NullaryFn<void>;
  isTransactionHistoryToggleOn?: boolean;
}) => {
  const baseProps = {
    title: "Transaction History",
    titlePosition: "center",
    isOpen,
    onClose,
    qaLabel: "modal-transaction-history",
    type: "lightbox" as ModalType,
    maxWidth: "60%",
    padding: isTransactionHistoryToggleOn ? 0 : "",
    shouldCloseOnEsc: false
  };

  const props = {
    ...baseProps,
    animation: "bottom"
  };

  return (
    <Modal {...props}>
      <TransactionHistory onClose={isTvg5() ? onClose : noop} />
    </Modal>
  );
};
