import React, { useEffect, useState } from "react";
import { easings, useSpring } from "@react-spring/web";
import { FdIcon } from "../FdIcon";
import {
  Wrapper,
  TitleSection,
  ContentWrapper,
  Title,
  SubTitle
} from "./styled-components";
import { ToastProps } from "./types";

export const toastAnimationDuration = 350;

export const Toast = ({
  qaLabel = "toast",
  type,
  title,
  subTitle,
  showToast,
  callback,
  noAnimation,
  offset = 0
}: ToastProps) => {
  const [isVisible, setIsVisible] = useState(showToast);
  const [visibility, setVisibility] = useState<"visible" | "hidden">("hidden");
  const animationControl = () => {
    if (isVisible) {
      return offset ? offset * -1 : -50;
    }
    return 0;
  };

  const toastAnimationConfig = () => ({
    y: animationControl(),
    opacity: isVisible ? 1 : 0,
    config: {
      duration: noAnimation && isVisible ? 0 : toastAnimationDuration,
      easing: easings.easeInOutSine
    }
  });

  const [toastStyles, toastApi] = useSpring(toastAnimationConfig, [isVisible]);

  useEffect(() => {
    if (isVisible) {
      setVisibility("visible");
    } else {
      setTimeout(() => setVisibility("hidden"), toastAnimationDuration);
    }
  }, [isVisible]);

  useEffect(() => {
    if (isVisible && type === "success") {
      setTimeout(() => setIsVisible(false), 5000);

      setTimeout(() => callback && callback(), 5000 + toastAnimationDuration);
    } else if (isVisible) {
      toastApi.start(toastAnimationConfig());
    } else {
      setTimeout(() => toastApi.start(toastAnimationConfig()), 5000);
    }
  }, [isVisible, type]);

  return visibility === "visible" ? (
    <Wrapper
      data-qa-label={qaLabel}
      style={toastStyles}
      visibility={visibility}
      offset={offset}
    >
      <ContentWrapper type={type}>
        <FdIcon name="pin" color="content.onDark" />
        <TitleSection>
          <Title>{title}</Title>
          <SubTitle>{subTitle}</SubTitle>
        </TitleSection>
      </ContentWrapper>
    </Wrapper>
  ) : null;
};

export default Toast;
