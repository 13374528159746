import React, { memo, FC } from "react";
import { FilterButton } from "./components";
import { FilterCellContainer } from "./styled-components";
import { FilterCellsProps } from "./types";
import { useQaLabel } from "../../hooks";

export const FilterCells: FC<FilterCellsProps> = memo(
  ({
    filterOptions,
    size,
    onChange,
    activeValue = "",
    qaLabel = "filter-cells",
    hasShadow,
    minWidth,
    isStretch,
    hasWidth = true,
    ...rest
  }) => {
    const qaLabelProps = useQaLabel(qaLabel);

    return (
      <FilterCellContainer
        {...rest}
        size={size}
        {...qaLabelProps}
        hasShadow={hasShadow}
        isStretch={isStretch}
        hasWidth={hasWidth}
      >
        {filterOptions.map(({ value, ...props }) => (
          <FilterButton
            {...props}
            key={value}
            size={size}
            isActive={activeValue === value}
            value={value}
            onClick={onChange}
            minWidth={minWidth}
            isStretch={isStretch}
            qaLabel={qaLabel}
            hasWidth={hasWidth}
          />
        ))}
      </FilterCellContainer>
    );
  }
);

export type { FilterCellsProps };
