// @flow
import styled, { css } from "styled-components";
import { fontBold, fontNormal, fontCondensed } from "@fdr/static-ui/Typography";
import buildColor from "@fdr/static-ui/ColorPalette";
import { opacityIn } from "@fdr/static-ui/Transitions";
import { breakpoints } from "@tvg/design-system";

export const AccountTransactionsButtonContainer = styled.div`
  padding: 12px;
  width: 100%;

  & > a:first-child:not(:only-child) {
    margin-bottom: 16px;
  }

  @media screen and ${breakpoints.tablet.min.sm} {
    display: flex;
    flex-flow: column;
    width: 40%;
    gap: 10px;
  }
`;

export const AccountTransactionContainer = styled.div`
  height: ${(props) =>
    props.isEmpty || props.isLoading ? "calc(100vh - 48px)" : "100%"};

  & > div {
    background-color: ${buildColor("white", "100")};
    height: 100%;
  }
`;

export const MessageIconContainer = styled.div`
  position: relative;
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  ${({ bgColor, size, iconColor }) => css`
    background-color: ${buildColor(bgColor.name, bgColor.value)};
    height: ${size}px;
    width: ${size}px;

    & > svg {
      fill: ${buildColor(iconColor.name, iconColor.value)};
      padding-left: 9px;
    }
  `}
  ${({ isBadge }) =>
    isBadge &&
    css`
      position: absolute;
      top: 0;
      right: 0;

      & > svg {
        padding-left: 0;
      }
    `}
`;

export const MessageTextContainer = styled.div`
  padding: 0 12px;

  & > div:first-child {
    margin: 12px 0 5px;
  }
`;

export const TextContainer = styled.div`
  font-size: 14px;
  letter-spacing: 0;
  text-align: center;
  ${({ isBold }) =>
    isBold
      ? css`
          font-family: ${fontBold};
          font-weight: 500;
          color: ${buildColor("navy", "700")};
        `
      : css`
          font-family: ${fontNormal};
          font-weight: normal;
          color: ${buildColor("grey", "800")};
        `}
`;

export const AccountExternalLink = styled.a`
  color: ${buildColor("blue", "500")};
  font-family: ${fontNormal};
  font-weight: normal;
  font-size: 14px;
  letter-spacing: 0;
  display: inline-block;
  margin-bottom: 2px;
`;

export const TransactionHistoryContainer = styled.section`
  overflow-x: hidden;
`;

export const TransactionsHeader = styled.div`
  background: ${buildColor("grey", "100")};
  height: 32px;
  display: flex;
  justify-content: space-between;
  padding: 9px 12px;
  border-bottom: 1px solid ${buildColor("grey", "300")};
  position: sticky;
  top: 0;
  z-index: 99;
  animation: 300ms ${opacityIn} ease;
`;

export const HeaderText = styled.div`
  font-family: ${fontCondensed};
  font-size: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
  ${({ type }) =>
    type === "label"
      ? css`
          color: ${buildColor("grey", "800")};
        `
      : css`
          color: ${buildColor("grey", "900")};
          margin-left: 4px;
        `}
`;

export const HeaderBalance = styled.div`
  display: flex;
`;

export const TransactionFooter = styled.div`
  height: 44px;
  background: ${buildColor("grey", "100")};
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 12px;
  align-content: center;

  & > button {
    color: ${buildColor("blue", "500")};
    display: flex;
    align-content: middle;
    line-height: 20px;
    font-size: 14px;
    font-family: ${fontNormal};
    min-width: 100px;

    & > svg {
      fill: ${buildColor("blue", "500")};
    }

    &:disabled {
      color: ${buildColor("grey", "800")};
      opacity: 1;

      & > svg {
        fill: ${buildColor("grey", "800")};
      }
    }
  }

  & > button:last-child {
    justify-content: flex-end;
  }
`;

export const PageIndicator = styled.div`
  color: ${buildColor("grey", "800")};
  font-family: ${fontNormal};
  font-size: 14px;
  line-height: 20px;
  text-align: center;
`;
