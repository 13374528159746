import React, { useCallback } from "react";
import { IconButton } from "@tvg/design-system";
import { MyAccountButtonContainer } from "./styled-components";

interface Props {
  openMyMenu: boolean;
  setOpenMyMenu: Function;
}

const MyAccountMainButton: React.FC<Props> = ({
  openMyMenu,
  setOpenMyMenu
}) => {
  const onPressOpenMyMenu = useCallback(() => {
    setOpenMyMenu(!openMyMenu);
  }, [openMyMenu, setOpenMyMenu]);

  return (
    <MyAccountButtonContainer>
      <IconButton
        qaLabel="myAccountButton"
        variant="tertiary_dark"
        size="m"
        iconName="user"
        isActive={openMyMenu}
        onPress={onPressOpenMyMenu}
        textTooltip={openMyMenu ? undefined : "Your Account"}
      />
    </MyAccountButtonContainer>
  );
};

export default MyAccountMainButton;
