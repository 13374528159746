import React, { memo } from "react";
import { Paragraph, Tag } from "@tvg/design-system";
import { useFavoriteWithRedux } from "@urp/lib-racetracks";
import { buildQaLabel } from "@tvg/test-utils/utils";
import { Container, InnerContainer } from "./styled-components";
import { Props } from "./types";
import { CONTAINER, RESULTED, SUMMARY, TRACK } from "../../utils/constants";
import FavoriteIcon from "../FavoriteIcon";

const CollapsibleSummary = ({
  trackCode,
  trackName,
  accountNumber,
  isUserLogged,
  isResulted,
  hasPromo,
  module,
  qaLabel = ""
}: Props) => {
  const { isFavorite: getIsFavorite, toggleFavorite } = useFavoriteWithRedux(
    isUserLogged,
    accountNumber,
    module
  );

  const isFavorite = getIsFavorite(trackCode);

  return (
    <Container
      data-qa-label={buildQaLabel([qaLabel, SUMMARY, CONTAINER, trackName])}
    >
      <InnerContainer>
        <FavoriteIcon
          isFavorite={isFavorite}
          toggleFavorite={() => toggleFavorite(trackCode, trackName)}
          trackName={trackName}
        />
        <Paragraph
          qaLabel={buildQaLabel([qaLabel, SUMMARY, TRACK, trackName])}
          fontFamily="bold"
        >
          {trackName}
        </Paragraph>
      </InnerContainer>
      {(isResulted && (
        <Paragraph
          qaLabel={buildQaLabel([qaLabel, SUMMARY, RESULTED, trackName])}
        >
          All resulted
        </Paragraph>
      )) ||
        (hasPromo && (
          <Tag variant="promo" label="promo" size="s" icon="promo" />
        ))}
    </Container>
  );
};

export default memo(CollapsibleSummary);
