// @flow

import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import buildColor from "@fdr/static-ui/ColorPalette";
import { fontNormal } from "@fdr/static-ui/Typography";
import { rgba } from "polished";
import type { ButtonSize, ButtonSizeParams, ButtonDisabledType } from "./types";

const disableFocus = `
  &:focus {
    outline: none;
  }

  &::-moz-focus-inner {
    border: 0;
  }
`;

const getButtonSize = (sized: ButtonSize) => {
  switch (sized) {
    case "noSize":
      return {
        size: "auto",
        padding: "0 12px",
        fontSize: "12px"
      };
    case "small":
      return {
        size: "24px",
        padding: "0 12px",
        fontSize: "12px"
      };
    case "medium":
      return {
        size: "34px",
        padding: "0 12px",
        fontSize: "14px"
      };
    case "video":
      return {
        size: "34px",
        padding: "0 16px",
        fontSize: "14px"
      };
    case "watch":
      return {
        size: "34px",
        padding: "0 10px",
        fontSize: "14px"
      };
    case "huge":
      return {
        size: "48px",
        padding: "0 20px",
        fontSize: "16px"
      };
    case "big":
      return {
        size: "44px",
        padding: "0 16px",
        fontSize: "16px"
      };
    default:
      return {
        size: "44px",
        padding: "0 16px",
        fontSize: "16px"
      };
  }
};

const getDisabledColors = (
  derbyLabelType?: ButtonDisabledType,
  $borderLess
) => {
  switch (derbyLabelType) {
    case "regular":
      return `
        background: ${buildColor("grey", "L3")};
        border: ${
          $borderLess ? "none" : `1px solid ${buildColor("grey", "L3")}`
        };
        color: ${buildColor("grey", "D3")};
      `;
    case "naked":
      return `
        background: none;
        border: ${
          $borderLess ? "none" : `1px solid ${buildColor("grey", "L3")}`
        };
        color: ${buildColor("grey")};
      `;
    default:
      return `
        border: ${
          $borderLess ? "none" : `1px solid ${buildColor("grey", "300")}`
        };
        background: ${buildColor("grey", "000")};
        color: ${buildColor("grey", "400")};
      `;
  }
};

const disabledStyling = (
  buttonSize: ButtonSizeParams,
  $paddingLess: boolean,
  $borderLess: boolean,
  $isStretched: boolean,
  derbyLabelType?: ButtonDisabledType
) => {
  return `
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    height: ${buttonSize.size};
    font-size: ${buttonSize.fontSize};
    font-family: ${fontNormal};
    padding: ${$paddingLess ? "0" : buttonSize.padding};
    text-decoration: none;
    outline: none;
    pointer-events: none;
    ${$isStretched ? "width: 100%;" : ""}
    ${getDisabledColors(derbyLabelType, $borderLess)}
  `;
};

const sharedStyles = (
  buttonSize: ButtonSizeParams,
  $paddingLess: boolean,
  $isStretched: boolean
) => `
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${buttonSize.size};
  border-radius: 4px;
  color: ${buildColor("white", "100")};
  font-size: ${buttonSize.fontSize};
  font-family: ${fontNormal};
  padding: ${$paddingLess ? "0" : buttonSize.padding}};
  text-decoration: none;
  outline: none;
  cursor: pointer;
  line-height: 100%;
  ${$isStretched ? "width: 100%;" : ""}
  ${disableFocus};
`;

const primaryButtonStyles = (
  $isDisabled,
  size,
  $paddingLess,
  $borderLess,
  $isStretched
) => {
  const buttonSize = getButtonSize(size);
  return !$isDisabled
    ? `
  ${sharedStyles(buttonSize, $paddingLess, $isStretched)}
  background: linear-gradient(180deg, ${buildColor("blue")} 0%, ${buildColor(
        "blue",
        "D1"
      )} 100%);

  &:focus {
    background: ${buildColor("blue", "D1")};
  }

  &:active {
    background: ${buildColor("blue", "D2")};
    box-shadow: inset 0 0 4px ${rgba(buildColor("grey", "D4"), 0.5)};
  }`
    : disabledStyling(
        buttonSize,
        $paddingLess,
        $borderLess,
        $isStretched,
        "regular"
      );
};

const secondaryButtonStyles = (
  $isDisabled,
  size,
  $paddingLess,
  $borderLess,
  $isStretched,
  $disabledType?: ButtonDisabledType,
  $isNightMode = false
) => {
  const buttonSize = getButtonSize(size);
  const getBorder = () => {
    if ($borderLess) {
      return "none";
    }
    const color = $isNightMode ? buildColor("white") : buildColor("blue");

    return `1px solid ${color}`;
  };

  return !$isDisabled
    ? `
    display: flex;
    justify-content: center;
    align-items: center;
    height: ${buttonSize.size};
    background: ${
      $isNightMode ? buildColor("white", "00") : buildColor("white")
    };
    border-radius: 4px;
    padding: ${$paddingLess ? "0" : buttonSize.padding}};
    color: ${buildColor("blue", "D1")};
    border: ${getBorder()};
    font-size: ${buttonSize.fontSize};
    font-family: ${fontNormal};
    text-decoration: none;
    outline: none;
    cursor: pointer;
    ${$isStretched ? "width: 100%;" : ""}
    ${disableFocus}

    &:active {
      background: ${buildColor("blue")};
      color: ${buildColor("white")};
      box-shadow: inset 0 0 4px ${rgba(buildColor("grey", "D4"), 0.5)};
    }`
    : disabledStyling(
        buttonSize,
        $paddingLess,
        $borderLess,
        $isStretched,
        $disabledType
      );
};

const getButtonBorder = (borderLess?: boolean, isNightMode?: boolean) => {
  if (borderLess) {
    return "";
  }

  return isNightMode ? buildColor("blue") : buildColor("grey", "L2");
};

const tertiaryButtonStyles = (
  $isDisabled,
  size,
  $paddingLess,
  $borderLess,
  $isStretched,
  $disabledType?: ButtonDisabledType,
  $isNightMode?: boolean
) => {
  const buttonSize = getButtonSize(size);

  return !$isDisabled
    ? `
    display: flex;
    justify-content: center;
    align-items: center;
    height: ${buttonSize.size};
    background: ${
      $isNightMode ? buildColor("white", "00") : buildColor("white")
    };
    border-radius: 4px;
    padding: ${$paddingLess ? "0" : buttonSize.padding}};
    color: ${$isNightMode ? buildColor("white") : buildColor("blue", "D1")};
    border: 1px solid ${getButtonBorder($isStretched, $isNightMode)};
    font-size: ${buttonSize.fontSize};
    font-family: ${fontNormal};
    text-decoration: none;
    outline: none;
    cursor: pointer;
    ${$isStretched ? "width: 100%;" : ""}
    ${disableFocus}

    &:active {
      background: ${buildColor("blue")};
      color: ${buildColor("white")};
      box-shadow: inset 0 0 4px ${rgba(buildColor("grey", "D4"), 0.5)};
    }`
    : disabledStyling(
        buttonSize,
        $paddingLess,
        $borderLess,
        $isStretched,
        $disabledType
      );
};

const bettingButtonStyles = (
  $isDisabled,
  size,
  $paddingLess,
  $borderLess,
  $isStretched
) => {
  const buttonSize = getButtonSize(size);
  return !$isDisabled
    ? `
  ${sharedStyles(buttonSize, $paddingLess, $isStretched)}
  background: linear-gradient(180deg, ${buildColor(
    "green",
    "L1"
  )} 0%, ${buildColor("green", "D1")} 100%);

  &:hover, &:focus {
    background: ${buildColor("green", "D1")};
  }

  &:active {
    background: ${buildColor("green", "D2")};
    box-shadow: inset 0 0 4px ${rgba(buildColor("grey", "D4"), 0.5)};
  }`
    : disabledStyling(
        buttonSize,
        $paddingLess,
        $borderLess,
        $isStretched,
        "regular"
      );
};

const optedInButtonStyles = (
  $isDisabled,
  size,
  $paddingLess,
  $borderLess,
  $isStretched
) => {
  const buttonSize = getButtonSize(size);
  return !$isDisabled
    ? `
  ${sharedStyles(buttonSize, $paddingLess, $isStretched)}
  background: ${buildColor("green", "500")};

  &:active {
    background: ${buildColor("green", "700")};
  }`
    : disabledStyling(buttonSize, $paddingLess, $borderLess, $isStretched);
};

const promoButtonStyles = (
  $isDisabled,
  size,
  $paddingLess,
  $borderLess,
  $isStretched
) => {
  const buttonSize = getButtonSize(size);
  return !$isDisabled
    ? `
  ${sharedStyles(buttonSize, $paddingLess, $isStretched)}
  background: ${buildColor("yellow", "500")};
  color: ${buildColor("navy", "900")};

  &:active {
    background: ${buildColor("yellow", "700")};
  }`
    : disabledStyling(buttonSize, $paddingLess, $borderLess, $isStretched);
};

const destructiveButtonStyles = (
  $isDisabled,
  size,
  $paddingLess,
  $borderLess,
  $isStretched
) => {
  const buttonSize = getButtonSize(size);
  return !$isDisabled
    ? `
  ${sharedStyles(buttonSize, $paddingLess, $isStretched)}
  background: linear-gradient(180deg, ${buildColor(
    "red",
    "L1"
  )} 0%, ${buildColor("red", "D1")} 100%);

  &:hover, &:focus {
    background: ${buildColor("red", "D1")};
  }

  &:active {
    background: ${buildColor("red", "D2")};
    box-shadow: inset 0 0 4px ${rgba(buildColor("grey", "D4"), 0.5)};
  }`
    : disabledStyling(
        buttonSize,
        $paddingLess,
        $borderLess,
        $isStretched,
        "regular"
      );
};

const fanDuelLoginButtonStyles = (
  size,
  $paddingLess,
  $borderLess,
  $isStretched
) => {
  const buttonSize = getButtonSize(size);
  return `
  ${sharedStyles(buttonSize, $paddingLess, $isStretched)}
  border: ${$borderLess ? "none" : `1px solid ${buildColor("white", "100")}`};
  background: ${buildColor("navy")};`;
};

const headerButtonStyles = (size, $paddingLess, $isStretched) => {
  const buttonSize = getButtonSize(size);
  return `
  ${sharedStyles(buttonSize, $paddingLess, $isStretched)}
  background: ${buildColor("navy")};
  color: ${buildColor("grey", "L3")};
  border: 1px solid ${buildColor("blue", "D2")};
 `;
};

export const PrimaryButton = styled.button`
  ${({ $isDisabled, size, $paddingLess, $borderLess, $isStretched }) =>
    primaryButtonStyles(
      $isDisabled,
      size,
      $paddingLess,
      $borderLess,
      $isStretched
    )}
`;

export const PrimaryLink = styled(Link)`
  ${({ $isDisabled, size, $paddingLess, $borderLess, $isStretched }) =>
    primaryButtonStyles(
      $isDisabled,
      size,
      $paddingLess,
      $borderLess,
      $isStretched
    )}
`;

export const PrimaryExternalLink = styled.a.attrs(({ url }) => ({
  href: url
}))`
  ${({ $isDisabled, size, $paddingLess, $borderLess, $isStretched }) =>
    primaryButtonStyles(
      $isDisabled,
      size,
      $paddingLess,
      $borderLess,
      $isStretched
    )}
`;

export const SecondaryButton = styled.button`
  ${({
    $isDisabled,
    size,
    $paddingLess,
    $borderLess,
    $isStretched,
    $isNightMode
  }) =>
    secondaryButtonStyles(
      $isDisabled,
      size,
      $paddingLess,
      $borderLess,
      $isStretched,
      "naked",
      $isNightMode
    )}
`;

export const SecondaryLink = styled(Link)`
  ${({ $isDisabled, size, $paddingLess, $borderLess, $isStretched }) =>
    secondaryButtonStyles(
      $isDisabled,
      size,
      $paddingLess,
      $borderLess,
      $isStretched
    )}
`;

export const SecondaryExternalLink = styled.a`
  ${({ $isDisabled, size, $paddingLess, $borderLess, $isStretched }) =>
    secondaryButtonStyles(
      $isDisabled,
      size,
      $paddingLess,
      $borderLess,
      $isStretched
    )}
`;

export const TertiaryButton = styled.button`
  ${({
    $isDisabled,
    size,
    $paddingLess,
    $borderLess,
    $isStretched,
    $isNightMode
  }) =>
    tertiaryButtonStyles(
      $isDisabled,
      size,
      $paddingLess,
      $borderLess,
      $isStretched,
      "default",
      $isNightMode
    )}
`;

export const TertiaryLink = styled(Link)`
  ${({
    $isDisabled,
    size,
    $paddingLess,
    $borderLess,
    $isStretched,
    $isNightMode
  }) =>
    tertiaryButtonStyles(
      $isDisabled,
      size,
      $paddingLess,
      $borderLess,
      $isStretched,
      "default",

      $isNightMode
    )}
`;

export const TertiaryExternalLink = styled.a`
  ${({
    $isDisabled,
    size,
    $paddingLess,
    $borderLess,
    $isStretched,
    $isNightMode
  }) =>
    tertiaryButtonStyles(
      $isDisabled,
      size,
      $paddingLess,
      $borderLess,
      $isStretched,
      "default",
      $isNightMode
    )}
`;

export const HeaderButton = styled.button`
  ${({ size, $paddingLess, $isStretched }) =>
    headerButtonStyles(size, $paddingLess, $isStretched)}
`;

export const HeaderLink = styled(Link)`
  ${({ size, $paddingLess, $isStretched }) =>
    headerButtonStyles(size, $paddingLess, $isStretched)}
`;

export const HeaderExternalLink = styled.a`
  ${({ size, $paddingLess, $isStretched }) =>
    headerButtonStyles(size, $paddingLess, $isStretched)}
`;

export const BettingButton = styled.button`
  ${({ $isDisabled, size, $paddingLess, $borderLess, $isStretched }) =>
    bettingButtonStyles(
      $isDisabled,
      size,
      $paddingLess,
      $borderLess,
      $isStretched
    )}
`;

export const BettingLink = styled(Link)`
  ${({ $isDisabled, size, $paddingLess, $borderLess, $isStretched }) =>
    bettingButtonStyles(
      $isDisabled,
      size,
      $paddingLess,
      $borderLess,
      $isStretched
    )}
`;

export const BettingExternalLink = styled.a`
  ${({ $isDisabled, size, $paddingLess, $borderLess, $isStretched }) =>
    bettingButtonStyles(
      $isDisabled,
      size,
      $paddingLess,
      $borderLess,
      $isStretched
    )}
`;

export const PromoButton = styled.button`
  ${({ $isDisabled, size, $paddingLess, $borderLess, $isStretched }) =>
    promoButtonStyles(
      $isDisabled,
      size,
      $paddingLess,
      $borderLess,
      $isStretched
    )}
`;

export const PromoLink = styled(Link)`
  ${({ $isDisabled, size, $paddingLess, $borderLess, $isStretched }) =>
    promoButtonStyles(
      $isDisabled,
      size,
      $paddingLess,
      $borderLess,
      $isStretched
    )}
`;

export const PromoExternalLink = styled.a`
  ${({ $isDisabled, size, $paddingLess, $borderLess, $isStretched }) =>
    promoButtonStyles(
      $isDisabled,
      size,
      $paddingLess,
      $borderLess,
      $isStretched
    )}
`;

export const OptedInButton = styled.button`
  ${({ $isDisabled, size, $paddingLess, $borderLess, $isStretched }) =>
    optedInButtonStyles(
      $isDisabled,
      size,
      $paddingLess,
      $borderLess,
      $isStretched
    )}
`;

export const OptedInLink = styled(Link)`
  ${({ $isDisabled, size, $paddingLess, $borderLess, $isStretched }) =>
    optedInButtonStyles(
      $isDisabled,
      size,
      $paddingLess,
      $borderLess,
      $isStretched
    )}
`;

export const OptedInExternalLink = styled.a`
  ${({ $isDisabled, size, $paddingLess, $borderLess, $isStretched }) =>
    optedInButtonStyles(
      $isDisabled,
      size,
      $paddingLess,
      $borderLess,
      $isStretched
    )}
`;

export const DestructiveButton = styled.button`
  ${({ $isDisabled, size, $paddingLess, $borderLess, $isStretched }) =>
    destructiveButtonStyles(
      $isDisabled,
      size,
      $paddingLess,
      $borderLess,
      $isStretched
    )}
`;

export const DestructiveLink = styled(Link)`
  ${({ $isDisabled, size, $paddingLess, $borderLess, $isStretched }) =>
    destructiveButtonStyles(
      $isDisabled,
      size,
      $paddingLess,
      $borderLess,
      $isStretched
    )}
`;

export const DestructiveExternalLink = styled.a`
  ${({ $isDisabled, size, $paddingLess, $borderLess, $isStretched }) =>
    destructiveButtonStyles(
      $isDisabled,
      size,
      $paddingLess,
      $borderLess,
      $isStretched
    )}
`;

export const FanDuelLoginButton = styled.button`
  ${({ size, $paddingLess, $borderLess, $isStretched }) =>
    fanDuelLoginButtonStyles(size, $paddingLess, $borderLess, $isStretched)}
`;

export const FanDuelLoginLink = styled(Link)`
  ${({ size, $paddingLess, $borderLess, $isStretched }) =>
    fanDuelLoginButtonStyles(size, $paddingLess, $borderLess, $isStretched)}
`;

export const FanDuelLoginExternalLink = styled.a`
  ${({ size, $paddingLess, $borderLess, $isStretched }) =>
    fanDuelLoginButtonStyles(size, $paddingLess, $borderLess, $isStretched)}
`;

export const SimpleLinkButton = styled.button`
  ${({ $isDisabled, size, $paddingLess, $borderLess, $isStretched }) =>
    secondaryButtonStyles(
      $isDisabled,
      size,
      $paddingLess,
      $borderLess,
      $isStretched
    )};
  background: none;

  &:active {
    background: none;
    box-shadow: none;
  }
`;

export const SimpleLinkLink = styled(Link)`
  ${({ $isDisabled, size, $paddingLess, $borderLess, $isStretched }) =>
    secondaryButtonStyles(
      $isDisabled,
      size,
      $paddingLess,
      $borderLess,
      $isStretched
    )};
  background: none;
  border: none;

  &:active {
    background: none;
    box-shadow: none;
  }
`;

export const SimpleLinkExternalLink = styled.a`
  ${({ $isDisabled, size, $paddingLess, $borderLess, $isStretched }) =>
    secondaryButtonStyles(
      $isDisabled,
      size,
      $paddingLess,
      $borderLess,
      $isStretched
    )};
  background: none;
  border: none;

  &:active {
    background: none;
    box-shadow: none;
  }
`;

const tabCommonStyle = `
  width: 100%;
  white-space: nowrap;
  background: none;
  border: none;
  font-size: 16px;

  &:active {
    background: none;
    box-shadow: none;
  }
`;

export const TabButton = styled.button`
  ${({ $isDisabled, size, $paddingLess, $borderLess, $isStretched }) =>
    secondaryButtonStyles(
      $isDisabled,
      size,
      $paddingLess,
      $borderLess,
      $isStretched
    )}
  ${tabCommonStyle};
  ${({ renderTag }) =>
    renderTag &&
    css`
      display: flex;
      flex-direction: column;
    `};
  line-height: 125%;
`;

export const TabLink = styled(Link)`
  ${({ $isDisabled, size, $paddingLess, $borderLess, $isStretched }) =>
    secondaryButtonStyles(
      $isDisabled,
      size,
      $paddingLess,
      $borderLess,
      $isStretched
    )}
  ${tabCommonStyle}
`;

export const TabExternalLink = styled.a`
  ${({ $isDisabled, size, $paddingLess, $borderLess, $isStretched }) =>
    secondaryButtonStyles(
      $isDisabled,
      size,
      $paddingLess,
      $borderLess,
      $isStretched
    )}
  ${tabCommonStyle}
`;

const subTabCommonStyle = `
  width: 100%;
  white-space: nowrap;
  color: ${buildColor("blue", "D1")};
  font-size: 14px;
  font-weight: normal;
  letter-spacing: 0;

  &:active {
    background: ${buildColor("white", "100")};
    color: ${buildColor("blue", "D1")};
    box-shadow: none;
  }
  ${disableFocus}
`;

export const SubTabButton = styled.button`
  ${({ $isDisabled, size, $paddingLess, $borderLess, $isStretched }) =>
    secondaryButtonStyles(
      $isDisabled,
      size,
      $paddingLess,
      $borderLess,
      $isStretched
    )}
  ${subTabCommonStyle}
`;

export const SubTabLink = styled(Link)`
  ${({ $isDisabled, size, $paddingLess, $borderLess, $isStretched }) =>
    secondaryButtonStyles(
      $isDisabled,
      size,
      $paddingLess,
      $borderLess,
      $isStretched
    )}
  ${subTabCommonStyle}
`;

export const SubTabExternalLink = styled.a`
  ${({ $isDisabled, size, $paddingLess, $borderLess, $isStretched }) =>
    secondaryButtonStyles(
      $isDisabled,
      size,
      $paddingLess,
      $borderLess,
      $isStretched
    )}
  ${subTabCommonStyle}
`;

export const ButtonLink = styled(Link)`
  color: ${buildColor("blue", "500")};
  font-family: ${fontNormal};
  font-size: 14px;
  height: 17px;
  text-decoration: none;
  ${disableFocus}

  &:active {
    color: ${buildColor("blue", "700")};
  }
`;
