// @flow

import mediatorChannels from "@tvg/mediator";
import { get } from "lodash";
import pushToDataLayer from "../gtmUtils";

type PoolsInfoGTMEventsType = "POOLS_INFO:OPEN_CLOSE";

type PoolsInfoGTM = {
  type: PoolsInfoGTMEventsType,
  payload: {
    open?: boolean,
    module: string,
    action?: "Expand" | "Collapse",
    section?: "Race changes" | "All changes",
    trackName?: string,
    raceNumber?: number
  }
};

export default () => {
  mediatorChannels.base.subscribe(
    "POOLS_INFO:OPEN_CLOSE",
    (data: PoolsInfoGTM) => {
      pushToDataLayer({
        event: "siteClick",
        gaEventCategory: "Site Click",
        gaEventAction: get(data, "payload.open") ? "Open" : "Close",
        gaEventLabel: "Pools & Info Modal",
        module: get(data, "payload.module"),
        tag: undefined,
        microApp: "non-Microapp"
      });
    }
  );

  mediatorChannels.base.subscribe(
    "POOLS_INFO:EXPAND_COLLAPSE",
    (data: PoolsInfoGTM) => {
      pushToDataLayer({
        event: "siteClick",
        gaEventCategory: "Site Click",
        gaEventAction: get(data, "payload.action"),
        gaEventLabel: get(data, "payload.section"),
        module: "Pools & Info Modal",
        tag: `${get(data, "payload.trackName")} R${get(
          data,
          "payload.raceNumber"
        )}`,
        microApp: undefined
      });
    }
  );
};
