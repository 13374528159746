import { get } from "lodash";
import tvgConf from "@tvg/conf";
import { FetchPolicy, WatchQueryFetchPolicy } from "@apollo/client";
import { getPortByBrand } from "@tvg/utils/generalUtils";
import {
  Props,
  Race,
  Data,
  RaceWagersApolloProps,
  RaceWagersSubscriptionsApolloProps
} from "./types";
import { RaceProgram } from "../../../types";

const QUERY_VARIABLES = {
  withGreyhounds: false,
  ...tvgConf().graphContext()
};

export const extractInfoFromRace = (race: Race): RaceProgram => ({
  id: get(race, "id"),
  tvgRaceId: get(race, "tvgRaceId"),
  trackName: get(race, "track.name"),
  country: get(race, "location.country"),
  trackAbbr: get(race, "track.code"),
  raceNumber: get(race, "number"),
  style: get(race, "highlighted.style"),
  description: get(race, "highlighted.description"),
  isGreyhound: get(race, "isGreyhound"),
  mtp: get(race, "mtp"),
  postTime: get(race, "postTime"),
  raceStatus: get(race, "status"),
  onTvg: get(race, "video.onTvg"),
  onTvg2: get(race, "video.onTvg2"),
  typeCode: get(race, "type.code"),
  perfAbbr: get(race, "track.perfAbbr"),
  importanceLevel: get(race, "highlighted.pinnedOrder"),
  isInFeaturedTrack: get(race, "track.featured"),
  isFavorite: get(race, "track.isFavorite"),
  promos: get(race, "promos"),
  hasWagersToday: get(race, "track.hasWagersToday")
});

export const getScheduledRaces = (data: Data): RaceProgram[] => {
  if (get(data, "scheduleRaces")) {
    return data.scheduleRaces.reduce(
      (acc: RaceProgram[], track: { races: Race[] }) => {
        if (track?.races?.length) {
          track.races.forEach((race) => {
            acc.push(extractInfoFromRace(race));
          });
        }
        return acc;
      },
      []
    );
  }
  return [];
};

export default {
  options: (props: Props) => {
    const variables = {
      ...QUERY_VARIABLES,
      loggedIn: !!get(props, "accountId", ""),
      wagerProfile: get(props, "wagerProfile") || getPortByBrand()
    };

    return {
      skip: !props.wagerProfile,
      fetchPolicy: "cache-and-network" as WatchQueryFetchPolicy,
      nextFetchPolicy: "cache-first" as FetchPolicy,
      ssr: false,
      variables
    };
  },
  apolloOptionsRaceWagers: (props: RaceWagersApolloProps) => {
    const variables = {
      accountId: +get(props, "accountId", ""),
      trackCodes: get(props, "trackCodes", []),
      startDate: get(props, "mtpRaceDate", ""),
      endDate: get(props, "mtpRaceDate", "")
    };
    const enabledFeature = get(props, "enabled", false);

    return {
      skip: !(
        enabledFeature &&
        variables.accountId &&
        variables.trackCodes.length > 0 &&
        variables.startDate &&
        variables.endDate
      ),
      fetchPolicy: "cache-and-network" as WatchQueryFetchPolicy,
      ssr: false,
      returnPartialData: false,
      variables
    };
  },
  optionsRaceWagersSubscriptions: (
    props: RaceWagersSubscriptionsApolloProps
  ) => {
    const enabledFeature = get(props, "enabled", false);

    const variables = {
      accountId: +get(props, "accountId", ""),
      trackCodes: get(props, "trackCodes", [])
    };

    return {
      skip: !(
        enabledFeature &&
        variables.accountId &&
        variables.trackCodes.length > 0
      ),
      variables
    };
  }
};
