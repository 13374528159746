// @flow
import mediatorChannels from "@tvg/mediator";
import { get } from "lodash";
import pushToDataLayer from "../gtmUtils";

type RepeatBetsGTMTypeEvents =
  | "REPEAT_BETS:LINK_CLICK"
  | "REPEAT_BETS:CLOSE_ACTION"
  | "REPEAT_BETS:CONFIRM_BET"
  | "REPEAT_BETS:SET_BETS"
  | "REPEAT_BETS:SHOW";

type repeatBetsGTMData = {
  type: RepeatBetsGTMTypeEvents,
  payload: {
    error?: string,
    selections?: string,
    trackName?: string,
    raceNumber?: string,
    betAmount?: number,
    confirmMode?: boolean,
    betType?: string,
    module?: "Confirm Bet Modal" | "Bet Ticket",
    runnerType?: "Horse Racing" | "Greyhound Racing",
    repeatBet?: string,
    module?: string,
    tag?: string
  }
};

export default () => {
  mediatorChannels.base.subscribe(
    "REPEAT_BETS:LINK_CLICK",
    (data: repeatBetsGTMData) => {
      pushToDataLayer({
        event: "siteClick",
        gaEventCategory: "Site Click",
        gaEventAction: "Open",
        gaEventLabel: "Repeat Bet",
        module: get(data, "payload.module"),
        tag: get(data, "payload.tag"),
        microApp: "non-Microapp"
      });
    }
  );

  mediatorChannels.base.subscribe("REPEAT_BETS:CLOSE_ACTION", () => {
    pushToDataLayer({
      event: "siteClick",
      gaEventCategory: "Site Click",
      gaEventAction: "Close",
      gaEventLabel: "Repeat Bet",
      module: "Repeat Bet Modal",
      tag: undefined,
      microApp: "non-Microapp"
    });
  });

  mediatorChannels.base.subscribe(
    "REPEAT_BETS:CONFIRM_BET",
    (data: repeatBetsGTMData) => {
      pushToDataLayer({
        event: "bet",
        gaEventCategory: "Bet",
        gaEventAction: "Bet Confirm",
        gaEventLabel: "Repeat Bet",
        module: "Repeat Bet Modal",
        runnerSelectionList: get(data, "payload.selections"),
        trackName: get(data, "payload.trackName"),
        raceNumber: get(data, "payload.raceNumber"),
        betId: undefined,
        betAmount: get(data, "payload.betAmount"),
        betType: get(data, "payload.betType"),
        sport: `${get(data, "payload.runnerType")} Racing`,
        repeatBet: get(data, "payload.repeatBet")
      });
    }
  );

  mediatorChannels.base.subscribe(
    "REPEAT_BETS:SET_BETS",
    (data: repeatBetsGTMData) => {
      pushToDataLayer({
        event: "bet",
        gaEventCategory: "Bet",
        gaEventAction: "Set Repeats Success",
        gaEventLabel: get(data, "payload.repeatBet", "1"),
        module: "Repeat Bet Modal",
        tag: undefined
      });
    }
  );

  mediatorChannels.base.subscribe("REPEAT_BETS:BACK", () => {
    pushToDataLayer({
      event: "bet",
      gaEventCategory: "Bet",
      gaEventAction: "Back Arrow Clicked",
      gaEventLabel: "Return to Confirm Bet Modal",
      module: "Repeat Bet Modal",
      tag: undefined
    });
  });

  mediatorChannels.base.subscribe("REPEAT_BETS:SET_TO_MAX", () => {
    pushToDataLayer({
      event: "bet",
      gaEventCategory: "Bet",
      gaEventAction: "Set To Max Repeat Applied",
      gaEventLabel: undefined,
      module: "Repeat Bet Modal",
      tag: undefined
    });
  });

  mediatorChannels.base.subscribe("REPEAT_BETS:SHOW", () => {
    pushToDataLayer({
      event: "bet",
      gaEventCategory: "Bet",
      gaEventAction: "Modal Opened",
      gaEventLabel: "Repeat Bet",
      module: "Bet Placed Modal",
      tag: undefined
    });
  });
};
