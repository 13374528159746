import {
  MediatorEventType,
  RaceTracksEventData
} from "@tvg/amplitude/modules/raceTracks/types";
import mediator from "@tvg/mediator";

export const sendPageViewEvent = (payload: RaceTracksEventData["payload"]) =>
  mediator.base.dispatch({ type: MediatorEventType.TRACK_CLICK, payload });

export const sendRaceClickEvent = (payload: RaceTracksEventData["payload"]) =>
  mediator.base.dispatch({ type: MediatorEventType.RACE_CLICK, payload });

export const sendTrackDetailsBackEvent = (
  payload: RaceTracksEventData["payload"]
) =>
  mediator.base.dispatch({
    type: MediatorEventType.RETURN_TO_RACETRACKS,
    payload
  });
