import styled from "styled-components";
import tvgConf from "@tvg/conf";
import { breakpoints, Theme } from "@tvg/design-system";

const getMobilePaddingBottom = (isBetSlipOpen: boolean) => {
  if (tvgConf().brand === "fdr") {
    if (tvgConf().product === "fdrios") {
      return isBetSlipOpen ? "182px" : "78px";
    }
    return isBetSlipOpen ? "152px" : "48px";
  }
  return isBetSlipOpen ? "104px" : "12px";
};

export const ToastContainer = styled.div<{
  theme: Theme;
  betSlipOpened: boolean;
  isLhnVisible: boolean;
  isBetSlipCollapsed: boolean;
}>`
  position: fixed;
  left: calc(
    50% -
      ${({ isLhnVisible, isBetSlipCollapsed }) =>
        isLhnVisible ? (isBetSlipCollapsed ? "135px" : "20px") : "175px"}
  );
  bottom: ${({ theme, betSlipOpened }) =>
    betSlipOpened ? "75px" : theme.space["space-4"]};
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: ${({ theme }) => theme.space["space-3"]};
  z-index: 99999;

  @media screen and ${breakpoints.tablet.max.sm} {
    left: 0;
    right: 0;
    bottom: ${({ betSlipOpened }) => getMobilePaddingBottom(betSlipOpened)};
  }
`;
