import React, { FC } from "react";
import { useSelector } from "react-redux";
import { InputCheckboxSelect } from "@tvg/design-system/web";
import { TABS_NAMES, useRaceTracksFilter } from "@urp/lib-racetracks";
import { getRaceTracksIsSearchActive } from "@urp/lib-racetracks/src/redux/selectors";
import { getIsLogged } from "@urp/store-selectors";
import { buildQaLabel } from "@tvg/test-utils/utils";
import { REGION_OPTIONS, TRACKS_OPTIONS } from "../TracksFilter/constants";
import { SECTION, CONTAINER, FILTER } from "../../utils/constants";
import { SupportedFilters } from "../../types";
import { Props } from "./types";
import { FiltersContainer, InputsWrapper, Section } from "./styled-component";
import RaceTracksSearch from "../RaceTracksSearch";
import FilterTags from "../FilterTags";

const AllTracksFilters: FC<Props> = ({ currentTab, qaLabel = "", loading }) => {
  const isTracksSearchActive = useSelector(getRaceTracksIsSearchActive);
  const isLogged = useSelector(getIsLogged);

  const {
    regionsFilter,
    trackTypesFilter,
    handleRegionChange,
    handleTrackChange
  } = useRaceTracksFilter();

  if (loading) {
    return null;
  }

  return (
    <Section data-qa-label={buildQaLabel([qaLabel, FILTER, SECTION])}>
      <FiltersContainer
        data-qa-label={buildQaLabel([qaLabel, FILTER, CONTAINER])}
      >
        <RaceTracksSearch
          analyticsEnrichment={{ activeTab: TABS_NAMES.ALL_TRACKS }}
        />
        <InputsWrapper hidden={isTracksSearchActive}>
          <InputCheckboxSelect
            qaLabel={buildQaLabel([qaLabel, FILTER, SupportedFilters.REGION])}
            selectList={REGION_OPTIONS}
            onSelectCheckbox={(values) =>
              handleRegionChange(values, currentTab)
            }
            selectedValues={regionsFilter}
            label="Region"
          />
          {isLogged && (
            <InputCheckboxSelect
              qaLabel={buildQaLabel([
                qaLabel,
                FILTER,
                SupportedFilters.TRACK_TYPE
              ])}
              selectList={[TRACKS_OPTIONS[1]]}
              onSelectCheckbox={(values) =>
                handleTrackChange(values, currentTab)
              }
              selectedValues={trackTypesFilter}
              label="Tracks"
            />
          )}
        </InputsWrapper>
      </FiltersContainer>
      <FilterTags currentTab={currentTab} />
    </Section>
  );
};

export default AllTracksFilters;
