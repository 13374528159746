import { get, uniqBy, isEqual } from "lodash";
import type { WatchQueryFetchPolicy, QueryHookOptions } from "@apollo/client";
import tvgConf from "@tvg/conf";
import { RaceStatusEnum, RaceTypeCodeEnum, Race } from "@tvg/ts-types/Race";
import { Track } from "@tvg/ts-types/Track";
import { UserOptedInPromos, WagerProfile } from "@tvg/ts-types/User";
import { getPortByBrand } from "@tvg/utils/generalUtils";
import { TopPool } from "@tvg/ts-types/Pool";
import { PromotionTypeEnum } from "@tvg/ts-types/Promos";
import {
  filterRacesByTrackCode,
  filterRacesByRaceType,
  filterRacesByStatus,
  filterTracksByRegion,
  filterRacesByRegion,
  filterTrackByTrackType,
  filterRacesByDistanceInterval,
  filterRacesByRaceAttribute
} from "../utils/races";
import { searchTracks } from "../utils/trackInfo";
import { NO_TRACKS_FILTER } from "../constants";
import { TrackFilterValueEnum } from "../types";

const allRaceClasses = true;

const QUERY_VARIABLES = {
  filterBy: {
    status: [
      RaceStatusEnum.MANUALLY_OPENED,
      RaceStatusEnum.OPEN,
      RaceStatusEnum.RACE_OFF,
      RaceStatusEnum.UP_NEXT,
      RaceStatusEnum.RACE_OFFICIAL
    ],
    allRaceClasses
  },
  sortBy: { byPostTime: "ASC" }
};

type GraphTodayRacesResults = {
  raceDate: string;
  races: Array<Race>;
};

type GraphTodayTracksResults = {
  tracks: Array<Track>;
};

export const apolloOptionsTodayRaces = (props: {
  wagerProfile: WagerProfile;
}) => {
  const variables = {
    ...QUERY_VARIABLES,
    wagerProfile: props.wagerProfile || getPortByBrand(),
    ...tvgConf().graphContext()
  };

  return {
    fetchPolicy: "cache-first" as WatchQueryFetchPolicy,
    ssr: true,
    variables
  };
};

export const getTodayRacesProps = (
  result: GraphTodayRacesResults,
  options: {
    tracksFilter?: Array<string>;
    raceTypesFilter?: Array<RaceTypeCodeEnum>;
    raceStatusFilter?: Array<RaceStatusEnum>;
    uniqByTrack?: boolean;
    racesFilter?: string[];
    regionsFilter?: string[];
    distancesFilter?: string[];
  },
  optedInPromos?: UserOptedInPromos,
  topPools?: TopPool[]
) => {
  let races = get(result, "races", []);
  if (options.tracksFilter?.length) {
    races = isEqual(options.tracksFilter, NO_TRACKS_FILTER)
      ? []
      : filterRacesByTrackCode(races, options.tracksFilter);
  }
  if (options.raceTypesFilter?.length) {
    races = filterRacesByRaceType(races, options.raceTypesFilter);
  }
  if (options.regionsFilter?.length) {
    races = filterRacesByRegion(races, options.regionsFilter);
  }
  if (options.raceStatusFilter?.length) {
    races = filterRacesByStatus(races, options.raceStatusFilter);
  }
  if (options.distancesFilter?.length) {
    races = filterRacesByDistanceInterval(races, options.distancesFilter);
  }
  if (options.racesFilter?.length) {
    races = filterRacesByRaceAttribute(
      races,
      options.racesFilter,
      optedInPromos,
      topPools
    );
  }

  races = options.uniqByTrack ? uniqBy(races, "track.code") : races;

  return {
    raceDate: get(result, "raceDate", ""),
    races
  };
};

export const apolloOptionsTracks = (props: {
  wagerProfile: WagerProfile;
  fetchPolicy: WatchQueryFetchPolicy;
  pollInterval: number;
}) => {
  const variables = {
    wagerProfile: props.wagerProfile || WagerProfile.PORT_GENERIC,
    sortByName: {
      byName: "ASC"
    },
    trackFilters: {
      allTrackClasses: true
    },
    ...tvgConf().graphContext()
  };

  return {
    fetchPolicy: props.fetchPolicy,
    pollInterval: props.pollInterval,
    ssr: true,
    variables
  };
};

export const getTracksProps = (
  result: GraphTodayTracksResults,
  tracksFilter: Array<string>,
  regionsFilter: Array<string>,
  searchQuery: string,
  onlyFeaturedTracks: boolean,
  maxResults: number,
  trackTypesFilter: TrackFilterValueEnum[],
  favoriteTracks: string[]
): GraphTodayTracksResults => {
  let tracksFiltered: Array<Track> = get(result, "tracks", []);
  if (tracksFilter.length > 0) {
    tracksFiltered = tracksFiltered.filter((track) =>
      tracksFilter.includes(track.code)
    );
  }
  if (regionsFilter.length > 0) {
    tracksFiltered = filterTracksByRegion(
      tracksFiltered,
      regionsFilter
    ) as Array<Track>;
  }
  if (searchQuery !== "") {
    tracksFiltered = searchTracks(tracksFiltered, searchQuery) as Array<Track>;
  }
  if (trackTypesFilter.length > 0) {
    tracksFiltered = filterTrackByTrackType(
      tracksFiltered,
      trackTypesFilter,
      favoriteTracks
    );
  }

  if (onlyFeaturedTracks) {
    tracksFiltered = tracksFiltered.filter((track) => track.featured);
  }

  return {
    tracks:
      maxResults >= 0 ? tracksFiltered.slice(0, maxResults) : tracksFiltered
  };
};

export const apolloOptionsUserPromos = (
  accountNumber: string,
  isUserLogged: boolean,
  promotionType: PromotionTypeEnum
): QueryHookOptions => {
  const variables = {
    accountNumber: +accountNumber,
    promotionType,
    ...tvgConf().graphContext()
  };

  return {
    skip: !accountNumber || !isUserLogged,
    variables
  };
};

export const apolloOptionsUserOptinPromos = (
  accountNumber: string,
  isUserLogged: boolean
): QueryHookOptions => {
  const variables = {
    accountNumber: +accountNumber,
    ...tvgConf().graphContext()
  };

  return {
    variables,
    skip: !accountNumber || !isUserLogged
  };
};
