import React, { PropsWithChildren } from "react";
import { SectionHeader } from "@tvg/design-system";
import { buildQaLabel } from "@tvg/test-utils/utils";
import { Props } from "./types";
import { CONTAINER, SECTION } from "../../utils/constants";

const TracksSection = ({
  title,
  children,
  qaLabel = ""
}: PropsWithChildren<Props>) => (
  <div data-qa-label={buildQaLabel([qaLabel, SECTION, CONTAINER])}>
    <SectionHeader title={title} qaLabel={buildQaLabel([qaLabel, SECTION])} />
    {children}
  </div>
);

export default TracksSection;
