// @flow
import { get } from "lodash";

type paymentInfo = {
  paymentType: string,
  accountNumber: string
};

type betInfo = {
  trackName: string,
  raceNumber: string,
  betAmount: number,
  wagerCost: number,
  betType: string
};

type Transaction = {
  date: string,
  amount: number,
  type: string,
  description: string,
  details: ?string,
  info: string
};

type OnHoldTransaction = {
  accountId: number,
  depositId: string,
  amount: number,
  feeAmount: number,
  netAmount: number,
  operationDateUTC: string,
  releaseAtUTC: string,
  releasedAtUTC: null,
  originalHoldDays: number,
  paymentType: string,
  userId: string,
  isDepositOnHold: boolean,
  transactionOnHoldId: string
};

export const paymentTypeDefinition = (type: string, amount: number) => {
  if (type === "Withdrawal" && amount < 0) {
    return "Withdrawal cancelled";
  }

  if (type === "Deposit" && amount < 0) {
    return "Deposit cancelled";
  }

  return type;
};

export const paymentTypesFactory = (
  transactionInfo: paymentInfo,
  formattedType: { [key: string]: string }
) => {
  const hasAsterisks = ["CC", "VIWD", "SLP", "DEB"];
  const paymentType = get(transactionInfo, "paymentType", "").trim();
  const accountNumber = get(transactionInfo, "accountNumber", "");
  const formattedAccountNumber =
    accountNumber.length === 9
      ? `${accountNumber.substring(0, 3)} ${accountNumber.substring(
          3,
          6
        )} ${accountNumber.substring(6, 9)} `
      : "";

  return {
    description: get(formattedType, paymentType, paymentType),
    details: hasAsterisks.includes(paymentType)
      ? `****${accountNumber.substring(
          accountNumber.length - 4,
          accountNumber.length
        )}`
      : `${formattedAccountNumber}`
  };
};

export const betFactory = (
  transactionInfo: betInfo,
  wagerTypes: { [key: string]: string }
) => {
  const trackName = get(transactionInfo, "trackName", "");
  const raceNumber = get(transactionInfo, "raceNumber", "");
  const betAmount = get(transactionInfo, "betAmount", "0");
  const wagerCost = get(transactionInfo, "wagerCost", "0");
  const betType = get(transactionInfo, "betType", "");
  const formattedBetType = get(wagerTypes, betType, betType);

  return {
    description: `${trackName} ${raceNumber}`,
    details: `$${parseFloat(betAmount).toFixed(
      2
    )} ${formattedBetType} - $${parseFloat(wagerCost).toFixed(2)} total`
  };
};

export const transactionTypeFormatter = (
  transaction: Transaction,
  formattedType: { [key: string]: string },
  wagerTypes: { [key: string]: string }
): {
  description?: string,
  details?: string
} => {
  const info = JSON.parse(get(transaction, "info", {}));

  let transactionType = transaction.type.toLowerCase();

  if (transactionType.indexOf("bet") !== -1) {
    transactionType = "bet";
  } else if (transactionType.indexOf("adjustment") !== -1) {
    transactionType = "adjustment";
  }

  const transactions = {
    deposit: paymentTypesFactory(info, formattedType),
    withdrawal: paymentTypesFactory(info, formattedType),
    bet: betFactory(info, wagerTypes),
    adjustment: {
      description: get(info, "adjustmentDescription", ""),
      details: ""
    },
    promotion: {
      description: get(info, "promotionDescription", ""),
      details: ""
    }
  };

  return transactions[transactionType] ? transactions[transactionType] : {};
};

export const transactionToHoldFormatter = (
  onHoldTransaction: OnHoldTransaction
) => {
  const info = {
    feeAmount:
      onHoldTransaction.feeAmount &&
      onHoldTransaction.feeAmount.toFixed(2).toString(),
    paymentType: onHoldTransaction.paymentType,
    accountNumber: onHoldTransaction.accountId
  };

  return {
    date: onHoldTransaction.operationDateUTC,
    releaseDate: onHoldTransaction.releaseAtUTC,
    amount: onHoldTransaction.netAmount,
    description: onHoldTransaction.paymentType,
    details: "",
    info: JSON.stringify(info),
    type: "Deposit",
    isDepositOnHold: onHoldTransaction.isDepositOnHold
  };
};
