import React, { memo, useEffect } from "react";
import { NormalizedCacheObject, ApolloClient } from "@apollo/client";
import { useSelector, useDispatch } from "react-redux";
import tvgConf from "@tvg/conf";
import { isFDR } from "@tvg/utils/generalUtils";
import TracksListComponent from "@tvg/tracks/src/v2/components/tracks-list";
import { get } from "lodash";
import { getIsNewTILEnabled } from "@urp/lib-racetracks/src/redux/selectors";
import ApolloContext from "@tvg/utils/apolloContext";
import { SeoArea } from "@tvg/design-system";
import { getAccountNumber, getIsLogged } from "@urp/store-selectors";
import { isAccountCompliantSelector } from "@tvg/sh-utils/sessionUtils";
import { setSeoTrackList } from "@urp/lib-racetracks/src/utils/analytics";
import { TracksInformationList } from "@urp/tracks-builder";
import { getFavoriteTracks } from "../../utils/helpers";

interface Props {
  showSeoFooter?: boolean;
}

const RaceTracksList = ({ showSeoFooter = false }: Props) => {
  const dispatch = useDispatch();

  const isAccountCompliant = useSelector(isAccountCompliantSelector);
  const enableSeoRaceTracks = useSelector((store) =>
    get(store, "capi.featureToggles.enableSeoRaceTracks", false)
  );
  const isNewTILEnabled = useSelector(getIsNewTILEnabled);

  const seoContentTitle = useSelector((store) =>
    get(store, "capi.messages.seoContentTrackListTitle", "")
  );
  const seoContent = useSelector((store) =>
    get(store, "capi.messages.seoContentTrackList", "")
  );

  const isLogged: boolean = useSelector(getIsLogged);
  const accountNumber: string = useSelector(getAccountNumber);

  useEffect(() => {
    if (isLogged && accountNumber && isAccountCompliant) {
      getFavoriteTracks(accountNumber, dispatch);
    }
  }, [isLogged, accountNumber, isAccountCompliant]);

  return (
    enableSeoRaceTracks && (
      <ApolloContext.Consumer>
        {(value: { fcpClient: ApolloClient<NormalizedCacheObject> | null }) => (
          <>
            {isFDR() && isNewTILEnabled ? (
              <TracksInformationList />
            ) : (
              <TracksListComponent
                // @ts-ignore
                device={tvgConf().device}
                fcpClient={value.fcpClient}
                setTracksData={setSeoTrackList}
              />
            )}
            {showSeoFooter && (
              <SeoArea title={seoContentTitle} message={seoContent} />
            )}
          </>
        )}
      </ApolloContext.Consumer>
    )
  );
};

export default memo(RaceTracksList);
