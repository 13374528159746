import React, { FC, useEffect } from "react";
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router";
import { SeoArea } from "@tvg/design-system";
import {
  RESULTED_RACES_STATUS,
  TABS,
  useRaceTracks,
  useRaceTracksFilter,
  useRaceTracksSearch
} from "@urp/lib-racetracks";
import {
  getRaceTracksIsSearchActive,
  getRaceTracksSearchQuery
} from "@urp/lib-racetracks/src/redux/selectors";
import UpcomingRaces from "@urp/upcoming-races";
import { getUpcomingRacesInfo } from "@urp/store-selectors";
import { RaceTracksContainer } from "@urp/racetracks";
import {
  Body,
  Container,
  EmptyMessageContainer,
  SectionContainer,
  TabContentContainer,
  UpcomingRacesContainer
} from "./styled-component";
import {
  getSeoRacesContent,
  getSeoRacesTitle,
  getURPRacingScheduleToggle
} from "../../redux/selectors";
import TracksAZ from "../../components/TracksAZ";
import EmptyMessage from "../../components/EmptyMessage";
import TracksFilter from "../../components/TracksFilter";
import AllTracksFilters from "../../components/AllTracksFilters";
import TrackDetails from "../../components/TrackDetails";
import TracksInformationList from "../../components/TracksInformationList";

interface TabContentProps {
  currentTab: TABS;
}

const TabContent: FC<TabContentProps> = ({ currentTab }) => {
  const seoTitle = useSelector(getSeoRacesTitle);
  const seoMessage = useSelector(getSeoRacesContent);
  const { pathname } = useLocation();
  const isURPTracksEnabled = useSelector(getURPRacingScheduleToggle);
  const isTracksSearchActive = useSelector(getRaceTracksIsSearchActive);
  const tracksSearchQuery = useSelector(getRaceTracksSearchQuery);
  const { noUpcomingRacesDesk: noUpcomingRacesMessages } =
    useSelector(getUpcomingRacesInfo);

  const isOnRaceTracks = pathname === "/tracks/all-info";

  const {
    raceTypesFilter,
    regionsFilter,
    trackTypesFilter,
    racesFilter,
    distancesFilter
  } = useRaceTracksFilter();

  const { onCancelSearch } = useRaceTracksSearch();

  const isRaceTypesFilterEnabled = true;
  const filterOptions = {
    isTracksSearchActive,
    isRaceTypesFilterEnabled,
    tracksSearchQuery,
    raceTypesFilter,
    regionsFilter,
    trackTypesFilter,
    racesFilter,
    distancesFilter
  };

  useEffect(() => {
    onCancelSearch();
  }, [currentTab]);

  const { tracksRaces, loading, isSearchOrFilterApplied } = useRaceTracks({
    ...filterOptions,
    raceStatusFilter: currentTab === TABS.RESULTS ? RESULTED_RACES_STATUS : []
  });

  const renderEmptyCase = (
    <EmptyMessageContainer>
      <EmptyMessage isGlobal isTracksSearchActive={isTracksSearchActive} />
    </EmptyMessageContainer>
  );

  const renderContentBody = () => {
    switch (currentTab) {
      case TABS.TODAY:
        return (
          <Container>
            <TracksFilter currentTab={currentTab} loading={loading} />
            {!tracksRaces.length && !loading && isSearchOrFilterApplied ? (
              renderEmptyCase
            ) : (
              <>
                <SectionContainer isUpcoming>
                  <UpcomingRacesContainer>
                    <UpcomingRaces
                      filterOptions={filterOptions}
                      noUpcomingRacesMessages={noUpcomingRacesMessages}
                    />
                  </UpcomingRacesContainer>
                </SectionContainer>
                <Body>
                  <SectionContainer>
                    <TracksAZ filterOptions={filterOptions} />
                  </SectionContainer>
                </Body>
              </>
            )}
            <SectionContainer>
              <SeoArea
                tag="h3"
                title={seoTitle}
                message={seoMessage}
                qaLabel="seoContainer"
              />
            </SectionContainer>
          </Container>
        );
      case TABS.RESULTS:
        return <></>;
      case TABS.ALL_TRACKS:
        return (
          <RaceTracksContainer>
            {isOnRaceTracks ? (
              <>
                <AllTracksFilters currentTab={currentTab} loading={loading} />
                <SectionContainer>
                  <TracksInformationList />
                </SectionContainer>
              </>
            ) : (
              <TrackDetails />
            )}
          </RaceTracksContainer>
        );
      default:
        return <></>;
    }
  };

  if (!isURPTracksEnabled) return <Navigate to="/races?view=bytrack" />;

  return <TabContentContainer>{renderContentBody()}</TabContentContainer>;
};

export default TabContent;
