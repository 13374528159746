import { RaceTypeCodeEnum } from "@tvg/ts-types/Race";
import { TrackFilterValueEnum } from "../../types";

export enum ActionType {
  RACE_TYPE_FILTER = "SET_RACE_TYPE_FILTERS",
  REGION_FILTER = "SET_REGION_FILTERS",
  DISTANCE_FILTER = "SET_DISTANCE_FILTERS",
  RACE_FILTER = "SET_RACE_FILTER",
  TRACK_TYPE_FILTER = "SET_TRACK_TYPE_FILTER",
  PRIZE_FILTER = "SET_PRIZE_FILTER",
  CLEAR_FILTERS = "CLEAR_FILTERS",
  START_SEARCH_MODE = "START_SEARCH_MODE",
  END_SEARCH_MODE = "END_SEARCH_MODE"
}

type RaceTypeFiltersType = {
  type: ActionType.RACE_TYPE_FILTER;
  payload: RaceTypeCodeEnum[];
};
type RegionFiltersType = {
  type: ActionType.REGION_FILTER;
  payload: string[];
};
type ClearFiltersType = { type: "CLEAR_FILTERS" };

type StartSearchMode = { type: "START_SEARCH_MODE" };
type EndSearchMode = { type: "END_SEARCH_MODE" };

interface RacesFilterAction {
  type: ActionType.RACE_FILTER;
  payload: string[];
}

interface DistancesFilterAction {
  type: ActionType.DISTANCE_FILTER;
  payload: string[];
}

interface TrackTypesFilter {
  type: ActionType.TRACK_TYPE_FILTER;
  payload: TrackFilterValueEnum[];
}

interface PrizesFilter {
  type: ActionType.PRIZE_FILTER;
  payload: string[];
}

export type RaceTracksFilterActionTypes =
  | RaceTypeFiltersType
  | RegionFiltersType
  | RacesFilterAction
  | DistancesFilterAction
  | TrackTypesFilter
  | ClearFiltersType
  | StartSearchMode
  | EndSearchMode;

export const setRaceTypeFilters = (
  filters: RaceTypeCodeEnum[]
): RaceTypeFiltersType => ({
  type: ActionType.RACE_TYPE_FILTER,
  payload: filters
});

export const setRegionFilters = (filters: string[]): RegionFiltersType => ({
  type: ActionType.REGION_FILTER,
  payload: filters
});

export const setRaceFilters = (filters: string[]): RacesFilterAction => ({
  type: ActionType.RACE_FILTER,
  payload: filters
});

export const setDistanceFilter = (
  filters: string[]
): DistancesFilterAction => ({
  type: ActionType.DISTANCE_FILTER,
  payload: filters
});

export const setTrackTypeFilter = (
  filters: TrackFilterValueEnum[]
): TrackTypesFilter => ({
  type: ActionType.TRACK_TYPE_FILTER,
  payload: filters
});

export const setPrizeFilter = (filters: string[]): PrizesFilter => ({
  type: ActionType.PRIZE_FILTER,
  payload: filters
});

export const clearFilters = (): ClearFiltersType => ({
  type: ActionType.CLEAR_FILTERS
});

export const startSearchMode = (): StartSearchMode => ({
  type: ActionType.START_SEARCH_MODE
});

export const endSearchMode = (): EndSearchMode => ({
  type: ActionType.END_SEARCH_MODE
});
