// @flow

import React, { type Node } from "react";
import { CSSTransition } from "react-transition-group";
import { get } from "lodash";
import Icon from "@fdr/static-ui/Icons";
import type { IconType } from "@fdr/static-ui/Icons/Types.js.flow";
import {
  MessageContainer,
  MessageTitle,
  MessageText,
  MessageIconContainer
} from "./styled-components";

type Props = {
  icon?: ?IconType,
  iconSize?: number,
  textTitle: string,
  textBody: string,
  qaLabel: string,
  children: ?(Node | Node[])
};

const FullPageMessage = (props: Props) => (
  <CSSTransition
    key={props.qaLabel}
    timeout={4000}
    classNames="full-container-messages"
  >
    <MessageContainer data-qa-label={props.qaLabel}>
      {props.icon && (
        <MessageIconContainer>
          <Icon
            icon={props.icon}
            size={props.iconSize}
            qaLabel={`${props.qaLabel}-icon`}
          />
        </MessageIconContainer>
      )}
      <MessageTitle data-qa-label={`${props.qaLabel}-title`}>
        {props.textTitle}
      </MessageTitle>
      <MessageText data-qa-label={`${props.qaLabel}-text`}>
        {props.textBody}
      </MessageText>
      {get(props, "children") && props.children}
    </MessageContainer>
  </CSSTransition>
);

FullPageMessage.defaultProps = {
  icon: null,
  iconSize: 200,
  textTitle: "",
  textBody: "",
  qaLabel: "",
  children: null
};

export default FullPageMessage;
