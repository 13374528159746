// @flow
import type { LoginActiveFlow, LoginActiveFlowStatus } from "@tvg/types/Login";
import type { NullaryFn } from "@tvg/types/Functional";

export type OpenLoginModalPayloadType = {
  loginComponent: React$Element<*>,
  loginActiveFlow?: LoginActiveFlow | null,
  callback: NullaryFn<void>,
  // You use this key to have additional changes on a flow
  // For example its being used to change the modal title on the recover-email flow
  loginActiveFlowStatus?: LoginActiveFlowStatus | null
};

export type LoginOpenModalAction = {
  type: "OPEN_LOGIN_MODAL",
  payload: OpenLoginModalPayloadType
};

export type LoginCloseModalAction = { type: "CLOSE_LOGIN_MODAL" };
export type LoginFlowResetAction = { type: "RESET_LOGIN_FLOW" };
export type LoginFlowStatusResetAction = { type: "RESET_LOGIN_FLOW_STATUS" };

export type SignUpModalAction = { type: "OPEN_SIGNUP" };

export type ContentCardsOpenModalAction = { type: "OPEN_CONTENT_CARDS_MODAL" };
export type ContentCardsCloseModalAction = {
  type: "CLOSE_CONTENT_CARDS_MODAL"
};

export type ErrorModalAction = { type: "OPEN_ERROR_MODAL" };

export type Actions =
  | LoginOpenModalAction
  | LoginCloseModalAction
  | LoginFlowResetAction
  | ContentCardsOpenModalAction
  | ContentCardsCloseModalAction
  | LoginFlowStatusResetAction
  | SignUpModalAction
  | ErrorModalAction;

export const openLoginModal = (payload: OpenLoginModalPayloadType) => ({
  type: "OPEN_LOGIN_MODAL",
  payload
});

export const closeLoginModal = () => ({ type: "CLOSE_LOGIN_MODAL" });

export const resetLoginFlow = () => ({ type: "RESET_LOGIN_FLOW" });

export const resetLoginFlowStatus = () => ({ type: "RESET_LOGIN_FLOW_STATUS" });

export const openErrorModal = (): ErrorModalAction => ({
  type: "OPEN_ERROR_MODAL"
});
