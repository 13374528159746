// @flow
import { type OperationComponent } from "@apollo/client";
import {
  format,
  subDays,
  subWeeks,
  subMonths,
  subYears,
  startOfDay,
  startOfYear,
  parseISO,
  endOfDay
} from "date-fns";
import { get } from "lodash";
import {
  type Transaction,
  type TimeFrameEnum,
  type TransactionTypeEnum
} from "@tvg/types/Account";
import { type Props } from "../types";
import {
  paymentTypeDefinition,
  transactionTypeFormatter,
  transactionToHoldFormatter
} from "../transactionTypeFormatter";

const MAX_RESULTS = 20;

export const getProps = (result: Transaction[]) => {
  const paymentOptions = get(result, "ownProps.paymentOptions");
  const wagerTypes = get(result, "ownProps.wagerTypes");
  const transactionTypeSummary = get(
    result,
    "data.accountTransactions.transactionTypeSummary",
    []
  );
  const originalOnHold = get(result, "data.fundsOnHoldHistory", []);
  let transactions = get(result, "data.accountTransactions.transactions", []);

  transactions = transactions.map((transaction) => {
    const transactionInfo = transactionTypeFormatter(
      transaction,
      paymentOptions,
      wagerTypes
    );

    const { type, amount } = transaction;

    return {
      ...transaction,
      // when type withdrawal and amount is negative it's cancelled withdrawal
      type: paymentTypeDefinition(type, amount),
      date: format(parseISO(transaction.date), "MM/dd/yyyy - hh:mma"),
      originalDate: transaction.date,
      description: transactionInfo.description,
      details: transactionInfo.details
    };
  });

  const transactionsOnHold = originalOnHold.map((transaction) => {
    const onHoldFormated = transactionToHoldFormatter(transaction);
    const transactionFormated = transactionTypeFormatter(
      onHoldFormated,
      paymentOptions,
      wagerTypes
    );
    return {
      ...onHoldFormated,
      ...transactionFormated,
      type: get(onHoldFormated, "isDepositOnHold", false)
        ? "Pending Deposit"
        : "Funds on Hold",
      date: format(parseISO(onHoldFormated.date), "MM/dd/yyyy - hh:mma"),
      releaseDate: format(parseISO(onHoldFormated.releaseDate), "MM/dd/yyyy")
    };
  });

  const transactionsOnHoldTotal = originalOnHold
    .reduce((acc, cur) => acc + cur.netAmount, 0)
    .toFixed(2)
    .toString();

  const totalTransactions = get(
    result,
    "data.accountTransactions.totalTransactions",
    0
  );
  const totalPages = Math.ceil(totalTransactions / MAX_RESULTS);

  return {
    startBalance: get(result, "data.accountTransactions.startBalance", 0),
    endBalance: get(result, "data.accountTransactions.endBalance", 0),
    transactions,
    transactionTypeSummary,
    transactionsOnHold,
    transactionsOnHoldTotal,
    totalPages
  };
};

const getStartDate = (timeFrameFilter: TimeFrameEnum, refDate: string) => {
  const date = startOfDay(new Date());
  switch (timeFrameFilter) {
    case "TODAY":
      return date;
    case "YESTERDAY":
      return subDays(date, 1);
    case "WEEK":
      return subWeeks(date, 1);
    case "MONTH":
      return subMonths(date, 1);
    case "YTD":
      return startOfYear(date);
    default:
      return (refDate && parseISO(refDate)) || subYears(date, 1);
  }
};

const getEndDate = (timeFrameFilter: TimeFrameEnum, refDate: string) => {
  const date =
    timeFrameFilter === "CUSTOM" && refDate ? parseISO(refDate) : new Date();
  return endOfDay(date);
};

const getTypesToFilter = (
  appliedTypesFilter: Array<TransactionTypeEnum> = []
) => {
  if (appliedTypesFilter.length === 0) return null;

  if (appliedTypesFilter.includes("ADJUSTMENT")) {
    const adjustmentsTypes = [
      "POSITIVE_ADJUSTMENT",
      "NEGATIVE_ADJUSTMENT",
      "TERMINATION_FEE",
      "INACTIVITY_FEE"
    ];
    return [...appliedTypesFilter, ...adjustmentsTypes];
  }

  return appliedTypesFilter;
};

const buildQueryVars = (props) => {
  const { accountId } = props;

  const startDate = getStartDate(
    props.timeFrameFilter,
    props.timeFrameStartDate || props.timeFrameEndDate
  );

  const endDate = getEndDate(
    props.timeFrameFilter,
    props.timeFrameEndDate || props.timeFrameStartDate
  );

  const startDateTime = startDate.toISOString();
  const endDateTime = endDate.toISOString();

  return {
    accountId: Number(accountId),
    startDateTime,
    endDateTime,
    currentPage: props.page - 1,
    maxResults: MAX_RESULTS,
    dateSort: "DESC",
    types: getTypesToFilter(props.appliedTypesFilter)
  };
};

export const skipBaseConditions = (props: Props) =>
  !props.accountId ||
  props.accountId === 0 ||
  (props.timeFrameFilter === "CUSTOM" &&
    !props.timeFrameStartDate &&
    !props.timeFrameEndDate);

export default {
  options: (props: Props) => ({
    client: props.gasClient,
    pollInterval: 0,
    fetchPolicy: "cache-and-network",
    ssr: false,
    variables: buildQueryVars(props)
  }),

  props: (result: OperationComponent<Response>) => ({
    ...getProps(result),
    isLoading: result.data.loading,
    hasError:
      get(result, "data.error", undefined) !== undefined ||
      get(result, "data.networkStatus") === 8
  })
};
