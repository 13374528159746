// @flow
import styled from "styled-components";
import { fontNormal } from "@fdr/static-ui/Typography";
import buildColor from "@fdr/static-ui/ColorPalette";

export const SessionInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${buildColor("grey", "D4")};
  font-family: ${fontNormal};
  font-size: 12px;
  line-height: 150%;
  text-align: center;

  & > div > span {
    vertical-align: baseline;
  }
`;
