import type { WagerProfile } from "@tvg/ts-types/User";
import type { Race } from "@tvg/ts-types/Race";
import type { ApolloClient, NormalizedCacheObject } from "@apollo/client";
import type { SeoTrack } from "@tvg/ts-types/Track";
import { useQuery } from "@apollo/client";
import { useSelector } from "react-redux";
import {
  getAccountNumber,
  getIsLogged,
  getWagerProfile
} from "@urp/store-selectors";
import tvgConf from "@tvg/conf";
import { useEffect } from "react";
import { get } from "lodash";
import {
  GET_RDA_TRACK_RACES,
  GET_TRACK_RACES
} from "../graphql/queries/TrackRacesQuery";

const INCLUDE_GREYHOUNDS = false;
const DEFAULT_POLL_INTERVAL = 30000;

const getVariables = (
  wagerProfile: WagerProfile,
  trackCode: string,
  accountNumber: string
) => ({
  wagerProfile,
  sortByRaceNumber: {
    byRaceNumber: "ASC"
  },
  raceFilters: {
    allRaceClasses: INCLUDE_GREYHOUNDS,
    trackCode: trackCode || undefined
  },
  trackFilters: {
    code: trackCode || undefined
  },
  accountId: accountNumber,
  product: tvgConf().product,
  brand: tvgConf().brand
});

const useTrackRaces = (
  rdaClient?: ApolloClient<NormalizedCacheObject>,
  track?: SeoTrack
) => {
  const isLogged = useSelector(getIsLogged);
  const accountNumber = useSelector(getAccountNumber);
  const wagerProfile = useSelector(getWagerProfile);

  const isUserLogged = isLogged && !!accountNumber;

  const variables = getVariables(
    wagerProfile,
    track?.code ?? "",
    accountNumber
  );

  const races = useQuery(GET_TRACK_RACES, {
    skip: isUserLogged,
    variables,
    pollInterval: DEFAULT_POLL_INTERVAL
  });

  const rdaRaces = useQuery(GET_RDA_TRACK_RACES, {
    skip: !isUserLogged,
    variables,
    client: rdaClient,
    pollInterval: DEFAULT_POLL_INTERVAL
  });

  useEffect(() => {}, []);

  const loading = races.loading || rdaRaces.loading;
  const results: Race[] =
    get(races, "data.races") ?? get(rdaRaces, "data.tracks[0].races", []);

  const startPolling = (pollingInterval: number = DEFAULT_POLL_INTERVAL) => {
    if (isUserLogged) rdaRaces.startPolling(pollingInterval);
    else races.startPolling(pollingInterval);
  };

  return {
    results,
    loading,
    startPolling,
    stopPolling: isUserLogged ? rdaRaces.stopPolling : races.stopPolling
  };
};

export default useTrackRaces;
