import styled, { css } from "styled-components";

export const Container = styled.div<{ isFavorite: boolean }>`
  margin-right: ${({ theme }) => theme.space["space-3"]};

  button {
    width: 20px;
    height: 20px;

    :hover {
      /* TODO: hard-coded until token is provided */
      background-color: ${({ theme }) => theme.colors.grey["100"]};
    }
  }

  svg {
    path {
      transition: fill 0.2s ease-in-out, stroke 0.2s ease-in-out;
      ${({ isFavorite, theme }) =>
        isFavorite &&
        // To-do: change tokens
        css`
          fill: ${theme.colorTokens.component.button.promo.background.hover};
          stroke: ${theme.colorTokens.component.button.promo.background.hover};
        `}
    }
  }
`;
