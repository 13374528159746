import React from "react";
import {
  MessageWrapper,
  ContentWrapper,
  TextWrapper
} from "./styled-components";
import { Icon } from "../Icon";
import { IconProps } from "../Icon/types";
import { Link } from "../../../src/components/link";
import { Paragraph } from "../../../src/components/typography/paragraph";
import { useTheme } from "../../../src/theming";

export interface Props {
  qaLabel: string;
  icon: IconProps;
  text?: string;
  title?: string;
  link: {
    onClick: () => void;
    text: string;
  };
}

export const AlertBanner = ({ qaLabel, icon, text, title, link }: Props) => {
  const { colors } = useTheme();
  return (
    <MessageWrapper data-qa-label={qaLabel}>
      <ContentWrapper>
        <Icon
          {...icon}
          qaLabel={`${qaLabel}-type-icon`}
          lineColor={icon.lineColor || "white.900"}
        />
        <TextWrapper>
          {title && (
            // TODO: Replace this Paragraph component with the web one once it's created so it's possible to use colorTokens
            <Paragraph
              qaLabel={`${qaLabel}-text`}
              ml="space-4"
              color={colors.white[900]}
              fontWeight="bold"
            >
              {title}
            </Paragraph>
          )}
          {text && (
            // TODO: Replace this Paragraph component with the web one once it's created so it's possible to use colorTokens
            <Paragraph
              qaLabel={`${qaLabel}-text`}
              ml="space-2"
              color={colors.white[900]}
            >
              {text}
            </Paragraph>
          )}
          <Link
            onPress={link.onClick}
            qaLabel={`${qaLabel}-link`}
            // eslint-disable-next-line react-native/no-inline-styles
            style={{
              textDecorationLine: "underline",
              color: colors.white[900]
            }}
            ml="space-4"
          >
            {link.text}
          </Link>
        </TextWrapper>
      </ContentWrapper>
    </MessageWrapper>
  );
};

export default AlertBanner;
