import React from "react";
import { FdIcon } from "@tvg/design-system/web";
import mediator from "@tvg/mediator";
import { NullaryFn } from "@tvg/ts-types/Functional";
import { Switch } from "@tvg/design-system";
import { isFdrProduct } from "@tvg/sh-utils/mobileUtils";
import { MediatorEventType as GlobalWalletEvents } from "@tvg/amplitude/modules/globalWallet/types";
import {
  AccountSection,
  ActionsWrapper,
  LinksSection,
  AccountLinkGroup,
  HeaderText,
  LinkText,
  AccountLinkRow,
  Footer,
  AccountMenuHeader,
  IconButtonWrapper,
  IconButtonDescription,
  IconWrapper
} from "./styled-components";
import JoinBar from "../JoinBar/index";
import { AccountMenuLinkProps, Balance } from "../../types";
import RGMessageState from "../RGMessageState";
import AccountMenuLink from "../AccountMenuLink";
import LinksGroup from "../LinksGroup";
import AccountBalanceDetails from "../AccountBalanceDetails";

export interface RgMessagePerState {
  firstMessage: string;
  secondMessage: string;
  link?: string;
  logo: string;
}

export interface Props {
  sessionStartAt: Date;
  userName: string;
  balance: number | null;
  isBalanceShown: boolean;
  onToggleShownBalance: NullaryFn<void>;
  balancesSummary: Balance[];
  balances: Balance[];
  accountLinks: AccountMenuLinkProps[];
  accountOverviewLinks: AccountMenuLinkProps[];
  otherLinks: AccountMenuLinkProps[];
  isPreferencesShown: boolean;
  isBetConfirmationEnabled: boolean;
  onBetConfirmationToggle: NullaryFn<void>;
  device: string;
  deposits: {
    withdrawHandler: NullaryFn<void>;
    depositHandler: NullaryFn<void>;
  };
  onLogout: NullaryFn<Promise<void>>;
  supportUrl: string;
  onSupport: NullaryFn<void>;
  noLoginRequiredForAccountPage: boolean;
  isLogged: boolean;
  rgMessagePerState?: Record<string, string> | undefined;
  onViewBalanceDetails: NullaryFn<void>;
  onOpenPlayableBalanceModal: NullaryFn<void>;
  deskAccountLinks?: AccountMenuLinkProps[];
}

const eventsRegistration = (
  destinationUrl: string,
  eventLabel: string,
  isEvenTimeout: boolean = true
) => {
  mediator.base.dispatch({
    type: GlobalWalletEvents.WITHDRAW_DEPOSIT,
    payload: {
      destinationUrl,
      eventLabel,
      isEvenTimeout
    }
  });
};

const AccountMenuMain = ({
  isBalanceShown,
  onToggleShownBalance,
  userName,
  balancesSummary,
  balances,
  accountLinks = [],
  otherLinks = [],
  accountOverviewLinks = [],
  device,
  isPreferencesShown,
  isBetConfirmationEnabled,
  onBetConfirmationToggle,
  sessionStartAt,
  deposits,
  onLogout,
  supportUrl,
  onSupport,
  rgMessagePerState,
  noLoginRequiredForAccountPage,
  isLogged,
  onViewBalanceDetails,
  onOpenPlayableBalanceModal,
  deskAccountLinks
}: Props) => (
  // The user can only view the Account Menu without being logged in
  // if noLoginRequiredForAccountPage is set to true.
  <AccountSection data-qa-label="account-balance">
    <JoinBar
      isVisible={noLoginRequiredForAccountPage && !isLogged}
      onLogin={() => mediator.base.dispatch({ type: "OPEN_LOGIN" })}
      onJoin={() => mediator.base.dispatch({ type: "OPEN_SIGNUP" })}
    />
    {isLogged && (
      <>
        <AccountMenuHeader>
          <AccountBalanceDetails
            isBalanceVisible={isBalanceShown}
            onVisibilityToggle={onToggleShownBalance}
            sessionStartAt={sessionStartAt}
            username={userName}
            balancesSummary={balancesSummary}
            balances={balances}
            onViewBalanceDetails={onViewBalanceDetails}
            onOpenPlayableBalanceModal={onOpenPlayableBalanceModal}
          />
          <ActionsWrapper>
            <IconButtonWrapper>
              <IconWrapper
                onClick={() => {
                  deposits.depositHandler();
                  eventsRegistration("/deposit", "Deposit", false);
                }}
                fdBackground={false}
              >
                <FdIcon name="deposit" color="content.onDark" />
              </IconWrapper>

              <IconButtonDescription>Deposit</IconButtonDescription>
            </IconButtonWrapper>
            <IconButtonWrapper>
              <IconWrapper
                onClick={() => {
                  deposits.withdrawHandler();
                  eventsRegistration("/withdraw", "Withdraw", false);
                }}
                fdBackground
              >
                <FdIcon name="withdraw" color="content.onDark" />
              </IconWrapper>

              <IconButtonDescription>Withdraw</IconButtonDescription>
            </IconButtonWrapper>
          </ActionsWrapper>
        </AccountMenuHeader>
        {
          // Account overview start
        }

        {deskAccountLinks && !isFdrProduct() && (
          <LinksSection data-qa-label="my-account">
            <LinksGroup
              headerText="Your account"
              links={deskAccountLinks}
              device={device}
            />
          </LinksSection>
        )}

        {isFdrProduct() && (
          <>
            <LinksSection data-qa-label="account-wallet">
              <LinksGroup
                headerText="Wallet"
                links={accountLinks}
                device={device}
              />
            </LinksSection>

            <LinksSection data-qa-label="account-overview-section">
              <LinksGroup
                headerText="Account overview"
                links={accountOverviewLinks}
                device={device}
              />
            </LinksSection>

            {isPreferencesShown && (
              <LinksSection data-qa-label="preferences-section">
                <AccountLinkGroup
                  device={device}
                  data-qa-label="account-preferences"
                >
                  <HeaderText data-qa-label="account-preferences-title">
                    Preferences
                  </HeaderText>
                  <li>
                    <AccountLinkRow data-qa-label="account-nav-betConfirmation">
                      <LinkText data-qa-label="account-link-text">
                        Bet Confirmation
                      </LinkText>
                      <Switch
                        isOn={isBetConfirmationEnabled}
                        onPress={onBetConfirmationToggle}
                        qaLabel={`account-betConfirm-toggle-${
                          isBetConfirmationEnabled ? "on" : "off"
                        }`}
                      />
                    </AccountLinkRow>
                  </li>
                </AccountLinkGroup>
              </LinksSection>
            )}
          </>
        )}
      </>
    )}

    {isFdrProduct() && (
      <>
        <LinksSection data-qa-label="other-section">
          <LinksGroup
            headerText={isLogged ? "Other Links" : "Links"}
            links={otherLinks}
            device={device}
          />
        </LinksSection>

        <LinksSection data-qa-label="support-section">
          <AccountLinkGroup device={device} data-qa-label="account-support">
            <li>
              <AccountMenuLink
                qaLabel="account-supportBtn"
                to={supportUrl}
                onClick={onSupport}
                linkText="Support"
                hideLeftIcon
              />
            </li>
          </AccountLinkGroup>
        </LinksSection>
      </>
    )}
    {isLogged && (
      <>
        <LinksSection
          data-qa-label="log-out-section"
          withoutMargin={!isFdrProduct()}
        >
          <AccountLinkGroup device={device} data-qa-label="account-logout">
            <button
              onClick={onLogout}
              type="button"
              data-qa-label="account-logoutBtn"
            >
              <AccountLinkRow isDanger>
                <LinkText>Log Out</LinkText>
                <FdIcon
                  name="logout"
                  color="component.button.destructive.background.base"
                />
              </AccountLinkRow>
            </button>
          </AccountLinkGroup>
        </LinksSection>
        {rgMessagePerState?.firstMessage && (
          <Footer>
            <RGMessageState
              firstMessage={rgMessagePerState?.firstMessage as string}
              secondMessage={rgMessagePerState?.secondMessage}
              logo={rgMessagePerState?.image}
              link={rgMessagePerState?.link}
              qaLabel="rg-message-state"
            />
          </Footer>
        )}
      </>
    )}
  </AccountSection>
);
export default AccountMenuMain;
