import { get } from "lodash";
import tvgConf from "@tvg/conf";

const enableMobileDatadogRUM = get(
  window,
  "__DESKTOP_PRELOADED_STATE__.capi.featureToggles.enableMobileDatadogRUM",
  false
);

if (enableMobileDatadogRUM && tvgConf.device !== "desktop") {
  // eslint-disable-next-line
  require("@datadog/browser-rum"); // initialize window.DD_RUM

  // @ts-ignore
  window.DD_RUM.init({
    applicationId: tvgConf().config("datadogRum").mobileApplicationId,
    clientToken: tvgConf().config("datadogRum").mobileClientToken,
    site: tvgConf().config("datadogRum").site,
    service: "tvg-desk",
    env: tvgConf.environment, // Our application’s environment. Follows the tag syntax requirements.
    // Inorder to match the version to the build deployed in production, additional work is required within our App.
    version: "1.0.0", // Default to 1.0.0, since we can't get the build version yet.
    sessionSampleRate: 100, // The percentage of sessions to track: 100 for all, 0 for none. Only tracked sessions send RUM events.
    sessionReplaySampleRate: 20, // The percentage of tracked sessions with Browser RUM & Session Replay pricing features: 100 for all, 0 for none.
    trackUserInteractions: true, // Enables automatic collection of users actions.
    trackResources: true, // Enables collection of resource events.
    trackLongTasks: true, // Enables collection of long task events. A long task event is any task in the browser that blocks the main thread for more than 50ms.
    // Default privacy options for Session Replay are designed to protect end user privacy and prevent sensitive organizational information from being collected.
    defaultPrivacyLevel: "mask-user-input"
  });
}
