import {
  handleTouchMove,
  evaluateReachingEdge
} from "@tvg/utils/swipeEventHandlers";
import { get } from "lodash";
import type { NavigateFunction } from "react-router-dom";
import type { TopPool } from "@tvg/ts-types/Pool";
import type { NullaryFn } from "@tvg/ts-types/Functional";
import buildRaceUrl from "@tvg/formatter/url";
import { sendRaceCellClickedAnalyticEvt } from "@urp/lib-racetracks/src/utils/analytics";
import {
  getRacePromos,
  isUserOptedInPromo
} from "@urp/lib-racetracks/src/utils/races";
import { Module } from "@tvg/amplitude/modules/raceCell/types";
import type { UserOptedInPromos } from "@tvg/ts-types/User";
import type { Race } from "@tvg/ts-types/Race";
import { sendScrollEvent } from "../../events";

export const onTouchMove = (e: React.TouchEvent<HTMLUListElement>) => {
  let swipeDirection = handleTouchMove(e);
  const isLivingOnTheEdge = evaluateReachingEdge(e);
  swipeDirection = isLivingOnTheEdge ? 0 : swipeDirection;

  if (swipeDirection !== 0) {
    sendScrollEvent(swipeDirection < 0);
  }
};

export const navigateFromPool = (pool: TopPool, navigate: NavigateFunction) => {
  if (pool) {
    const url = `${buildRaceUrl(
      pool.trackCode,
      pool.track,
      pool.race.raceNumber,
      pool.race.isGreyhound
    )}&betType=${pool.wagerType.id}`;

    navigate(url);
  }
};

export const openLoginCb =
  (cb: NullaryFn<void>) =>
  (_: Record<string, string>, success: Record<string, string>) => {
    if (get(success, "status", "fail") === "success") {
      cb();
    }
  };

export const onPoolClick =
  (navigate: NavigateFunction, optedInPromos: UserOptedInPromos) =>
  (pool: TopPool) => {
    const availableRacePromos = getRacePromos(pool.race as unknown as Race);
    const isOptedIn = isUserOptedInPromo(optedInPromos, availableRacePromos);

    sendRaceCellClickedAnalyticEvt({
      module: Module.POOLS,
      trackName: pool.track,
      raceNumber: pool.race.raceNumber,
      linkUrl: `${buildRaceUrl(
        pool.trackCode,
        pool.track,
        pool.race.raceNumber,
        pool.race.isGreyhound
      )}&betType=${get(pool, "wagerType.id")}`,
      raceHasPromos: !!availableRacePromos.length,
      trackHasPromos: false,
      promoOptedIn: isOptedIn ? "true" : "false",
      isFDTV: pool.race.video.onTvg || pool.race.video.onTvg2,
      trackCountry: pool.race.location.country
    });
    // Adding setTimeout so we guarantee the navigate is delayed and the event above triggers the correct global properties
    setTimeout(() => {
      navigateFromPool(pool, navigate);
    });
  };

export const getSortedPools = (a: TopPool, b: TopPool) => {
  if (a.amount > b.amount) return -1;
  if (a.amount < b.amount) return 1;
  if (a.race.mtp > b.race.mtp) return 1;
  if (a.race.mtp < b.race.mtp) return -1;
  return 0;
};
