import React, { PureComponent } from "react";
import styled, { keyframes } from "styled-components";
import color from "@fdr/static-ui/ColorPalette";

const pulseAnimation = keyframes`
  0% {
    fill: ${color("grey", "100")};
  }

  50% {
    fill: ${color("grey", "000")};
  }

  100% {
    fill: ${color("grey", "100")};
  }
`;

const Container = styled.div`
  width: 100%;
  background-color: white;
  overflow: hidden;
`;

const SVG = styled.svg`
  display: block;
  height: 125px;
  width: 1366px;

  rect.pulse {
    animation: ${pulseAnimation} 1.25s infinite;
  }
`;

export default class UpcomingRacesMask extends PureComponent<Props> {
  render() {
    return (
      <Container>
        <SVG>
          <g fill="none" fillRule="evenodd">
            <rect
              width="141"
              height="116"
              x="12.5"
              fill={color("white", "100")}
              stroke={color("grey", "200")}
              rx="4"
            />
            <rect
              width="141"
              height="116"
              x="162.5"
              fill={color("white", "100")}
              stroke={color("grey", "200")}
              rx="4"
            />
            <rect
              width="36"
              height="16"
              x="24"
              y="11.5"
              fill={color("grey", "100")}
              rx="1"
              className="pulse"
            />
            <rect
              width="36"
              height="16"
              x="174"
              y="11.5"
              fill={color("grey", "100")}
              rx="1"
              className="pulse"
            />
            <rect
              width="90"
              height="8"
              x="24"
              y="40.5"
              fill={color("grey", "100")}
              rx="1"
              className="pulse"
            />
            <rect
              width="98"
              height="8"
              x="174"
              y="40.5"
              fill={color("grey", "100")}
              rx="1"
              className="pulse"
            />
            <rect
              width="42"
              height="8"
              x="24"
              y="55.5"
              fill={color("grey", "100")}
              rx="1"
              className="pulse"
            />
            <rect
              width="42"
              height="8"
              x="174"
              y="55.5"
              fill={color("grey", "100")}
              rx="1"
              className="pulse"
            />
            <rect
              width="141"
              height="116"
              x="312.5"
              fill={color("white", "100")}
              stroke={color("grey", "200")}
              rx="4"
            />
            <rect
              width="52"
              height="16"
              x="324"
              y="11.5"
              fill={color("grey", "100")}
              rx="1"
              className="pulse"
            />
            <rect
              width="74"
              height="8"
              x="324"
              y="40.5"
              fill={color("grey", "100")}
              rx="1"
              className="pulse"
            />
            <rect
              width="42"
              height="8"
              x="324"
              y="55.5"
              fill={color("grey", "100")}
              rx="1"
              className="pulse"
            />
            <rect
              width="141"
              height="116"
              x="462.5"
              fill={color("white", "100")}
              stroke={color("grey", "200")}
              rx="4"
            />
            <rect
              width="52"
              height="16"
              x="474"
              y="11.5"
              fill={color("grey", "100")}
              rx="1"
              className="pulse"
            />
            <rect
              width="90"
              height="8"
              x="474"
              y="40.5"
              fill={color("grey", "100")}
              rx="1"
              className="pulse"
            />
            <rect
              width="42"
              height="8"
              x="474"
              y="55.5"
              fill={color("grey", "100")}
              rx="1"
              className="pulse"
            />
          </g>
        </SVG>
      </Container>
    );
  }
}
