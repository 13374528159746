// @flow
import { REGISTRATION_STEPS } from "@tvg/atomic-ui/_static/Constants/progressBar";
import type { Actions } from "../actions";
import type {
  StepsEnum,
  PromoBody,
  WelcomeFields,
  SsnFields,
  AddressFields,
  TvProviderFields,
  LegalContentType
} from "../types";

export type State = {
  currentStep: StepsEnum,
  lastStep: StepsEnum,
  isEditing: boolean,
  promos: {
    selected: string,
    isPromoCodeModalOpen: boolean,
    invalidPromo: boolean,
    list: {
      [key: string]: PromoBody
    }
  },
  welcome: {
    isValid: boolean,
    fields: WelcomeFields
  },
  ssn: {
    isValid: boolean,
    fields: SsnFields
  },
  fullSsn: {
    isValid: boolean
  },
  address: {
    isValid: boolean,
    fields: AddressFields,
    infoScreen?: {
      status: string,
      state: string,
      ageLimit: string,
      redirectUrl: string
    }
  },
  tvPreferences: {
    isTvPreferencesModalOpen: boolean,
    selectedProvider: TvProviderFields
  },
  onfido: {
    hasValidatedOnfido: boolean,
    accountId: string,
    applicantId: string,
    sdkToken: string,
    uuid: string
  },
  legalContent: {
    isModalOpen: boolean,
    contentType: LegalContentType,
    modalOrigin: LegalContentType
  }
};

// TODO: reset initial state before merging
export const initialState = {
  currentStep: REGISTRATION_STEPS.WELCOME,
  lastStep: REGISTRATION_STEPS.WELCOME,
  isEditing: false,
  promos: {
    isPromoCodeModalOpen: false,
    invalidPromo: false,
    selected: "",
    list: {}
  },
  welcome: {
    isValid: false,
    fields: {
      firstName: "",
      lastName: "",
      birth: "",
      email: "",
      phone: "",
      password: ""
    }
  },
  address: {
    isValid: false,
    fields: {
      home: {
        street: "",
        details: "",
        city: "",
        state: "",
        zip: ""
      },
      mailing: {
        street: "",
        details: "",
        city: "",
        state: "",
        zip: ""
      },
      hasMailingAddress: false
    }
  },
  infoScreen: {
    isOpen: false,
    status: ""
  },
  ssn: {
    isValid: false,
    fields: {
      ssn: ["", "", "", ""]
    }
  },
  tvPreferences: {
    isTvPreferencesModalOpen: false,
    selectedProvider: { value: "", key: "" }
  },
  fullSsn: {
    isValid: false
  },
  onfido: {
    hasValidatedOnfido: false,
    accountId: "",
    applicantId: "",
    sdkToken: "",
    uuid: ""
  },
  passThroughAddress: false,
  legalContent: {
    isModalOpen: false,
    contentType: "",
    modalOrigin: ""
  }
};

export default function reducer(
  state: State = initialState,
  action: Actions
): State {
  switch (action.type) {
    case "CHANGE_STEP_REGISTRATION": {
      return {
        ...state,
        currentStep: action.payload.currentStep
      };
    }
    case "CHANGE_LAST_STEP_REGISTRATION": {
      return {
        ...state,
        lastStep: action.payload.lastStep
      };
    }
    case "SET_IS_EDITING_REGISTRATION": {
      return {
        ...state,
        isEditing: action.payload.isEditing
      };
    }
    case "SET_A_PROMOTION_REGISTRATION": {
      return {
        ...state,
        promos: {
          ...state.promos,
          list: {
            [action.payload.promoId]: action.payload.promoBody,
            ...state.promos.list
          }
        }
      };
    }
    case "SET_A_SELECTED_PROMOTION_REGISTRATION": {
      return {
        ...state,
        promos: {
          ...state.promos,
          selected: action.payload.promoId
        }
      };
    }
    case "HANDLE_PROMO_CODE_MODAL_REGISTRATION": {
      return {
        ...state,
        promos: {
          ...state.promos,
          isPromoCodeModalOpen: action.payload.isOpen
        }
      };
    }
    case "SET_INVALID_PROMO_REGISTRATION": {
      return {
        ...state,
        promos: {
          ...state.promos,
          invalidPromo: action.payload.invalidPromo
        }
      };
    }
    case "SET_WELCOME_FIELDS_REGISTRATION": {
      return {
        ...state,
        welcome: {
          ...state.welcome,
          fields: action.payload.fields
        }
      };
    }
    case "SET_WELCOME_BIRTH_REGISTRATION": {
      return {
        ...state,
        welcome: {
          ...state.welcome,
          fields: {
            ...state.welcome.fields,
            birth: action.payload.birth
          }
        }
      };
    }
    case "SET_WELCOME_VALID_FORM_REGISTRATION": {
      return {
        ...state,
        welcome: {
          ...state.welcome,
          isValid: action.payload.isValid
        }
      };
    }
    case "SET_ADDRESS_FIELDS_REGISTRATION": {
      return {
        ...state,
        address: {
          ...state.address,
          fields: {
            ...state.address.fields,
            ...action.payload.fields
          }
        }
      };
    }
    case "SET_LOCATION_ADDRESS_FIELDS_REGISTRATION": {
      return {
        ...state,
        address: {
          ...state.address,
          fields: {
            ...state.address.fields,
            home: {
              ...state.address.fields.home,
              city: action.payload.fields.city,
              state: action.payload.fields.state,
              zip: action.payload.fields.zip
            }
          }
        }
      };
    }
    case "SET_ADDRESS_VALID_FORM_REGISTRATION": {
      return {
        ...state,
        address: {
          ...state.address,
          isValid: action.payload.isValid
        }
      };
    }
    case "SET_SSN_FIELDS_REGISTRATION": {
      return {
        ...state,
        ssn: {
          ...state.ssn,
          fields: action.payload.fields
        }
      };
    }
    case "SET_SSN_VALID_FORM_REGISTRATION": {
      return {
        ...state,
        ssn: {
          ...state.ssn,
          isValid: action.payload.isValid
        }
      };
    }
    case "SET_FULL_SSN_VALID_FORM_REGISTRATION": {
      return {
        ...state,
        fullSsn: {
          ...state.fullSsn,
          isValid: action.payload.isValid
        }
      };
    }
    case "SET_ADDRESS_INFO_SCREEN_REGISTRATION": {
      return {
        ...state,
        infoScreen: {
          ...state.address.infoScreen,
          status: action.payload.status,
          isOpen: action.payload.isOpen,
          state: action.payload.state,
          ageLimit: action.payload.ageLimit,
          redirectUrl: action.payload.redirectUrl
        }
      };
    }
    case "HANDLE_ERROR_MODAL_REGISTRATION": {
      return {
        ...state,
        infoScreen: {
          ...state.address.infoScreen,
          status: action.payload.status,
          isOpen: action.payload.isOpen
        }
      };
    }
    case "HANDLE_TV_PREFERENCES_MODAL_REGISTRATION": {
      return {
        ...state,
        tvPreferences: {
          ...state.tvPreferences,
          isTvPreferencesModalOpen: action.payload.isOpen
        }
      };
    }
    case "SET_TV_PREFERENCES_SCREEN_REGISTRATION": {
      return {
        ...state,
        tvPreferences: {
          ...state.tvPreferences,
          selectedProvider: action.payload.selectedProvider
        }
      };
    }
    case "HANDLE_LEGAL_CONTENT_MODAL_REGISTRATION": {
      return {
        ...state,
        legalContent: {
          ...state.legalContent,
          isModalOpen: action.payload.isOpen,
          contentType: action.payload.contentType
        }
      };
    }
    case "SET_LEGAL_CONTENT_MODAL_ORIGIN_REGISTRATION": {
      return {
        ...state,
        legalContent: {
          ...state.legalContent,
          modalOrigin: action.payload.modalOrigin
        }
      };
    }
    case "SET_ONFIDO_DETAILS_REGISTRATION": {
      return {
        ...state,
        onfido: {
          ...state.onfido,
          accountId: action.payload.accountId,
          applicantId: action.payload.applicantId,
          sdkToken: action.payload.sdkToken,
          uuid: action.payload.uuid
        }
      };
    }
    case "SET_VALIDATED_ONFIDO_REGISTRATION": {
      return {
        ...state,
        onfido: {
          ...state.onfido,
          hasValidatedOnfido: action.payload
        }
      };
    }
    case "CLEAN_REGISTRATION": {
      return initialState;
    }
    case "SET_PASS_THROUGH_ADDRESS": {
      return { ...state, passThroughAddress: action.payload.value };
    }
    default: {
      return state;
    }
  }
}
