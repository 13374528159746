import type { Race } from "@tvg/ts-types/Race";
import type { ApolloClient, NormalizedCacheObject } from "@apollo/client";
import { RaceTypeCodeEnum } from "@tvg/ts-types/Race";
import { RegionFiltersEnum } from "@urp/lib-racetracks";

export type FilterValueTypes = RaceTypeCodeEnum | RegionFiltersEnum | string;

export interface FilterItem<T = FilterValueTypes> {
  label: string;
  value: T;
  cb?: () => void;
}

export interface TracksAzCapiMessage {
  title: string;
  resultedTitle: string;
}
export interface FavoriteTracksMessage {
  title: string;
  noFavorites: {
    title: string;
    hint: string;
  };
}
export interface GroupedRacesByTrack {
  [code: string]: Race[];
}

export interface ApolloContextProps {
  fcpClient?: ApolloClient<NormalizedCacheObject>;
  rdaClient?: ApolloClient<NormalizedCacheObject>;
}
export interface TracksEmptyMessageInfo {
  bySearch: {
    tracks: {
      title: string;
      hint: string;
    };
    results: {
      title: string;
      hint: string;
    };
  };
  byFilters: {
    title: string;
    hint: string;
  };
}

export interface SeoTracksPageProps {
  title: string;
  content: string;
}

export enum SupportedFilters {
  REGION = "REGION",
  BETTING_FEATURE = "BETTING_FEATURE",
  DISTANCE = "DISTANCE",
  RACE_TYPE = "RACE_TYPE",
  TRACK_TYPE = "TRACK_TYPE"
}

export interface RacingScheduleConfigMessage {
  id?: SupportedFilters;
  label?: string;
  visible?: boolean;
}
