// @flow

import React, { PureComponent } from "react";
import styled, { keyframes } from "styled-components";
import color from "../ColorPalette";

const pulseAnimation = keyframes`
  0% {
    fill: ${color("grey", "100")};
  }

  50% {
    fill: ${color("grey", "000")};
  }

  100% {
    fill: ${color("grey", "100")};
  }
`;

const SVG = styled.svg`
  display: block;

  rect.pulse {
    animation: ${pulseAnimation} 1.25s infinite;
  }
`;

const Container = styled.div`
  overflow-x: hidden;
  width: 100%;
  background-color: ${color("white", "100")};
`;

export default class TransactionHistoryMask extends PureComponent<*> {
  render() {
    return (
      <Container>
        <SVG xmlns="http://www.w3.org/2000/svg" width="512" height="100%">
          <g fill="none" fillRule="evenodd">
            <rect width="414" height="816" fill="#F0F3F8" />
            <polygon fill="#FFF" points="0 0 414 0 414 816 0 816" />
            <rect width="374" height="1" x="40" y="115" fill="#CFD6DB" />
            <rect width="374" height="1" x="40" y="231" fill="#CFD6DB" />
            <rect
              width="127"
              height="8"
              x="40"
              y="41"
              fill="#F0F3F8"
              rx="2"
              className="pulse"
            />
            <rect
              width="151"
              height="8"
              x="40"
              y="157"
              fill="#F0F3F8"
              rx="2"
              className="pulse"
            />
            <rect
              width="103"
              height="8"
              x="40"
              y="92"
              fill="#F0F3F8"
              rx="2"
              className="pulse"
            />
            <rect
              width="119"
              height="8"
              x="40"
              y="208"
              fill="#F0F3F8"
              rx="2"
              className="pulse"
            />
            <rect
              width="49"
              height="8"
              x="40"
              y="17"
              fill="#F0F3F8"
              rx="2"
              className="pulse"
            />
            <rect
              width="89"
              height="8"
              x="40"
              y="133"
              fill="#F0F3F8"
              rx="2"
              className="pulse"
            />
            <rect width="374" height="1" x="40" y="347" fill="#CFD6DB" />
            <rect
              width="127"
              height="8"
              x="40"
              y="273"
              fill="#F0F3F8"
              rx="2"
              className="pulse"
            />
            <rect
              width="103"
              height="8"
              x="40"
              y="324"
              fill="#F0F3F8"
              rx="2"
              className="pulse"
            />
            <rect
              width="73"
              height="8"
              x="40"
              y="249"
              fill="#F0F3F8"
              rx="2"
              className="pulse"
            />
            <rect width="374" height="1" x="40" y="463" fill="#CFD6DB" />
            <rect
              width="143"
              height="8"
              x="40"
              y="389"
              fill="#F0F3F8"
              rx="2"
              className="pulse"
            />
            <rect
              width="119"
              height="8"
              x="40"
              y="440"
              fill="#F0F3F8"
              rx="2"
              className="pulse"
            />
            <rect
              width="89"
              height="8"
              x="40"
              y="365"
              fill="#F0F3F8"
              rx="2"
              className="pulse"
            />
            <rect width="374" height="1" x="40" y="579" fill="#CFD6DB" />
            <rect
              width="127"
              height="8"
              x="40"
              y="505"
              fill="#F0F3F8"
              rx="2"
              className="pulse"
            />
            <rect
              width="103"
              height="8"
              x="40"
              y="556"
              fill="#F0F3F8"
              rx="2"
              className="pulse"
            />
            <rect
              width="73"
              height="8"
              x="40"
              y="481"
              fill="#F0F3F8"
              rx="2"
              className="pulse"
            />
            <rect width="374" height="1" x="40" y="695" fill="#CFD6DB" />
            <rect
              width="151"
              height="8"
              x="40"
              y="621"
              fill="#F0F3F8"
              rx="2"
              className="pulse"
            />
            <rect
              width="95"
              height="8"
              x="40"
              y="672"
              fill="#F0F3F8"
              rx="2"
              className="pulse"
            />
            <rect
              width="89"
              height="8"
              x="40"
              y="597"
              fill="#F0F3F8"
              rx="2"
              className="pulse"
            />
            <rect width="374" height="1" x="40" y="811" fill="#CFD6DB" />
            <rect
              width="119"
              height="8"
              x="40"
              y="737"
              fill="#F0F3F8"
              rx="2"
              className="pulse"
            />
            <rect
              width="103"
              height="8"
              x="40"
              y="788"
              fill="#F0F3F8"
              rx="2"
              className="pulse"
            />
            <rect
              width="49"
              height="8"
              x="40"
              y="713"
              fill="#F0F3F8"
              rx="2"
              className="pulse"
            />
          </g>
        </SVG>
      </Container>
    );
  }
}
