import React, { memo } from "react";
import { Module } from "@tvg/amplitude/modules/raceCell/types";
import { buildQaLabel } from "@tvg/test-utils/utils";
import { Props } from "./types";
import CollapsibleWrapper from "../CollapsibleWrapper";
import { CONTAINER, LETTER_BLOCK, TITLE } from "../../utils/constants";
import SectionTitle from "../SectionTitle";

const TrackLetterBlock = ({
  letter,
  groupedRacesByTrack,
  onTrackClickHandler,
  qaLabel = "",
  checkRacePromos
}: Props) => (
  <div data-qa-label={buildQaLabel([qaLabel, LETTER_BLOCK, CONTAINER])}>
    <SectionTitle qaLabel={buildQaLabel([qaLabel, LETTER_BLOCK, TITLE])}>
      {letter.toUpperCase()}
    </SectionTitle>
    {groupedRacesByTrack.map((track) => (
      <CollapsibleWrapper
        key={track.code}
        track={track}
        onTrackClickHandler={onTrackClickHandler}
        module={Module.TRACKS_AZ}
        checkRacePromos={checkRacePromos}
      />
    ))}
  </div>
);

export default memo(TrackLetterBlock);
