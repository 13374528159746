import React, { FC } from "react";
import { useTheme } from "../../theming";
import { SeoContainer } from "./styled-components";
import { Header, Paragraph } from "../typography";
import { SeoAreaProps } from "./types";

export const SeoArea: FC<SeoAreaProps> = ({
  title,
  message,
  tag = "h1",
  qaLabel = "seo-area"
}) => {
  const theme = useTheme();

  return (
    <SeoContainer data-qa-label={qaLabel} theme={theme}>
      <Header
        qaLabel="seo-title"
        tag={tag}
        marginBottom="space-4"
        display="block"
        fontFamily="bold"
      >
        {title}
      </Header>
      <Paragraph
        qaLabel="seo-content"
        color="grey.800"
        lineHeight="s"
        whiteSpace="normal"
      >
        <span
          data-qa-label="seoMessage"
          dangerouslySetInnerHTML={{ __html: message }}
        />
      </Paragraph>
    </SeoContainer>
  );
};

export { SeoAreaProps };
export default SeoArea;
