import type { SeoTrack } from "@tvg/ts-types/Track";
import mediator from "@tvg/mediator";
import {
  MediatorEventType,
  Module,
  RaceCellData
} from "@tvg/amplitude/modules/raceCell/types";
import { FilterType } from "../types";

interface OpenEventPayload {
  isOpen: boolean;
  trackName: string;
}

interface FavoriteEventPayload {
  isFavoriting: boolean;
  module: Module;
  trackName: string;
  activeTab: string;
}

interface TabOpenedEventPayload {
  tabName: string;
  url: string;
}
interface FilterEventPayload {
  filterType: FilterType;
  filterName:
    | "race_type"
    | "region"
    | "track_type"
    | "betting_features"
    | "distance";
  filterValue: string;
  activeTab: string;
}

export const sendOpenAnalyticEvt = ({
  isOpen,
  trackName
}: OpenEventPayload) => {
  mediator.base.dispatch({
    type: "TRACKS_TRACK_CLICK",
    payload: {
      isOpen,
      trackName
    }
  });
};

export const sendTracksTabOpenedAnalyticEvt = ({
  tabName,
  url
}: TabOpenedEventPayload) => {
  mediator.base.dispatch({
    type: "TRACKS_TAB_OPENED",
    payload: {
      tabName,
      url
    }
  });
};

export const sendTracksFilterAnalyticEvt = ({
  filterType,
  filterName,
  filterValue,
  activeTab
}: FilterEventPayload) => {
  mediator.base.dispatch({
    type: "TRACKS_FILTER_APPLIED",
    payload: {
      filterType,
      filterName,
      filterValue,
      activeTab
    }
  });
};

export const sendTracksSearchAnalyticEvt = (
  analyticsEnrichment?: Record<string, unknown>
) => {
  mediator.base.dispatch({
    type: "TRACKS_SEARCH_APPLIED",
    payload: {
      ...(analyticsEnrichment || {}),
      filterType: FilterType.SEARCH
    }
  });
};

export const sendFavoriteAnalyticEvt = ({
  isFavoriting,
  module,
  trackName,
  activeTab
}: FavoriteEventPayload) => {
  mediator.base.dispatch({
    type: "TRACKS_FAVORITE",
    payload: {
      isFavoriting,
      module,
      trackName,
      activeTab
    }
  });
};

export const sendRaceCellClickedAnalyticEvt = ({
  module,
  trackName,
  raceNumber,
  linkUrl,
  raceHasPromos,
  trackHasPromos,
  promoOptedIn,
  isFDTV,
  trackCountry,
  tabActive
}: RaceCellData["payload"]) => {
  const data: RaceCellData = {
    type: MediatorEventType.CLICKED,
    payload: {
      module,
      trackName,
      raceNumber,
      linkUrl,
      raceHasPromos,
      trackHasPromos,
      promoOptedIn,
      isFDTV,
      trackCountry,
      tabActive
    }
  };
  mediator.base.dispatch(data);
};

export const isApplyingFilter = (filters: string[], filterType: string[]) =>
  filterType.some((filter) => filters.includes(filter));

export const setSeoTrackList = (seoTrackList: Array<SeoTrack>) => ({
  type: "SET_SEO_TRACK_LIST",
  payload: seoTrackList
});

export const setFromQuickLinks = (fromQuickLinks: boolean) => ({
  type: "SET_FROM_QUICK_LINKS",
  payload: fromQuickLinks
});
