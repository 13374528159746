// @flow
// $FlowFixMe
import React, { memo } from "react";
import Icon from "@fdr/static-ui/Icons";
import { arrowUp } from "@fdr/static-ui/Icons/icons";
import type { UnaryFn } from "@tvg/types/Functional";
import { ToggleButton, IconAnimated } from "./styled-components";

type Props = {
  labelHide: string,
  labelShow: string,
  isOpen: boolean,
  onToggle: UnaryFn<SyntheticEvent<HTMLButtonElement>, void>,
  qaLabel?: string
};

export const ToggleHideShow = memo(
  ({
    labelHide,
    labelShow,
    isOpen,
    onToggle,
    qaLabel = "toggle-hide-show"
  }: Props) => {
    return (
      <ToggleButton onClick={onToggle} data-qa-label={qaLabel}>
        <span>{isOpen ? labelHide : labelShow}</span>
        <IconAnimated isOpen={isOpen}>
          <Icon icon={arrowUp} />
        </IconAnimated>
      </ToggleButton>
    );
  }
);

export default ToggleHideShow;
