import React from "react";
import { Button } from "@tvg/design-system";
import { headerWagerPadGtm } from "../../gtm";
import useBuildRaceLink from "../../hooks/useBuildRaceLink";

type wagerButtonProps = {
  isTablet: boolean;
};

const windowConfig =
  "toolbar=0,location=0,status=0,menubar=0,scrollbars=1,resizable=0,top=0,left=0,width=480px,height=800px";

const WagerpadButton = (props: wagerButtonProps) => {
  const link = useBuildRaceLink();

  return !props.isTablet ? (
    <Button
      variant="marketing"
      iconPosition="start"
      icon="bets"
      onPress={() => {
        headerWagerPadGtm();
        if (typeof window !== "undefined") {
          window.open(link, "_blank", windowConfig);
        }
      }}
      qaLabel="wagerpad-button"
      ml="space-5"
      isDisabled={!link}
    >
      Wagerpad
    </Button>
  ) : null;
};

export default WagerpadButton;
