// @flow

import React, { type Node } from "react";
import { LabelStyled } from "./styled-components";

type Props = {
  qaLabel?: string,
  children: Node
};

export const Label = ({ qaLabel = "label", children }: Props) => {
  return <LabelStyled data-qa-label={qaLabel}>{children}</LabelStyled>;
};

export default Label;
