// @flow
import React, { PureComponent, type Node } from "react";
import { noop } from "lodash";
import DefaultButton from "./styled-components";

type Props = {
  /**
   * children of the Text component
   */
  children: Node,
  /**
   * Callback to be triggered by the button
   */
  onClick: () => mixed,
  /**
   * Classname to style the component
   */
  className: string,
  /**
   * Disables the button
   */
  isDisabled: boolean,
  /**
   * qaLabel for test
   */
  qaLabel: string,
  renderTag: boolean
};

export default class ButtonDefault extends PureComponent<Props> {
  static defaultProps = {
    children: null,
    onClick: noop,
    className: "",
    isDisabled: false,
    qaLabel: "",
    renderTag: false
  };

  render() {
    const componentProps = {
      ...(this.props.qaLabel !== ""
        ? {
            "data-qa-label": this.props.qaLabel
          }
        : {}),
      onClick: !this.props.isDisabled ? this.props.onClick : noop,
      className: this.props.className,
      isDisabled: this.props.isDisabled
    };

    return (
      <DefaultButton {...componentProps} disabled={this.props.isDisabled}>
        {this.props.children}
      </DefaultButton>
    );
  }
}
