// @flow

import styled from "styled-components";
import buildColor from "@fdr/static-ui/ColorPalette";

export const Container = styled.div`
  width: 32px;
  min-width: 32px;
  height: 32px;
  border-radius: 41px;
  background-color: ${buildColor("grey", "L2")};
  position: relative;
  overflow: hidden;
`;

export const Svg = styled.svg`
  position: absolute;
  top: 6px;
  left: 2px;
  height: 26px;
`;
