// @flow
import { isUndefined } from "lodash";
import type { Dispatch } from "redux";
import { isXSell } from "@tvg/sh-utils/mobileUtils";
import type { GeolocationStatus } from "@tvg/sh-geolocation/src/utils/requestGeolocation";
import { FixedBannerProps } from "@fdr/atomic-ui/_molecules/MessageV2/types";
import { onOpenComplianceModal } from "@tvg/sh-lib-account-wallet/src/utils/mobileUtils";
import { geoComplyErrorModal } from "@tvg/sh-geolocation/src/redux/actions";
import { isTvg5, isFDR } from "@tvg/utils/generalUtils";
import { openErrorModal } from "@tvg/login-controller/src/actions/modal";

export type UserProps = {
  isVerified: boolean,
  isTermsAccepted: boolean,
  isLogged: boolean
};

export const bannerProps = (
  dispatch: Dispatch<*>,
  user: UserProps,
  accountCompliantBannerMsg: {
    verifyAccount: FixedBannerProps,
    acceptTerms: FixedBannerProps
  },
  geolocationBannerMsg: FixedBannerProps,
  geolocationStatus: GeolocationStatus
) => {
  // In xSell geo errors are shown in the wrapper app
  if (
    isXSell() &&
    ["LOCATION_FAILURE", "GEOLOCATION_REJECTED"].includes(geolocationStatus)
  ) {
    return null;
  }

  const geolocationProps = {
    ...geolocationBannerMsg[geolocationStatus],
    link: {
      ...geolocationBannerMsg[geolocationStatus]?.link,
      onClick: () => {
        if (isTvg5() && isFDR()) {
          dispatch(openErrorModal());
        } else {
          dispatch(geoComplyErrorModal());
        }
      }
    }
  };

  if (geolocationProps.text && user.isLogged) {
    return geolocationProps;
  }

  const isUnverifiedOrUnacceptedTerms =
    (!isUndefined(user.isVerified) && !user.isVerified) ||
    (!isUndefined(user.isTermsAccepted) && !user.isTermsAccepted);

  if (user.isLogged && isUnverifiedOrUnacceptedTerms) {
    const compliantTypeProps = !user.isVerified
      ? accountCompliantBannerMsg.verifyAccount
      : accountCompliantBannerMsg.acceptTerms;
    const unverifiedUserProps = {
      type: "error",
      text: "Cannot verify your account",
      qaLabel: "fixed-banner-verify-account",
      ...compliantTypeProps,
      link: {
        onClick: (event: Event) => {
          onOpenComplianceModal();
          event.preventDefault();
        },
        text: "Read more",
        url: "",
        ...compliantTypeProps?.link
      }
    };
    return unverifiedUserProps;
  }

  return null;
};
