import React from "react";
import { useColorTokens } from "../../../../theming";
import { useQaLabelActive } from "../../../../hooks/useQaLabelActive";
import { useEvents } from "../../../../hooks/useEvents";
import { BetTypesText, BetTypesTouchable } from "./styled-components";
import { BetTypeButtonProps } from "./types";

export const BetTypeButton: React.FC<BetTypeButtonProps> = ({
  betType,
  isSelected = false,
  qaLabel = betType.code,
  onPress
}) => {
  const viewTestProps = useQaLabelActive(qaLabel, isSelected ? "active" : "");
  const { events, eventsHandlers } = useEvents({});
  const colorTokens = useColorTokens();

  const pressHandler = () => {
    onPress(betType.id);
  };

  return (
    <BetTypesTouchable
      accessibilityRole="tab"
      onPress={pressHandler}
      onLongPress={pressHandler}
      underlayColor={colorTokens.component.input.pressed}
      {...eventsHandlers}
      {...events}
      isSelected={isSelected}
      {...viewTestProps}
    >
      <BetTypesText {...events} isSelected={isSelected}>
        {betType.name}
      </BetTypesText>
    </BetTypesTouchable>
  );
};
