export type RgModalActions = {
  type:
    | "RG_MODAL_DEPOSIT_LIMIT_OPEN"
    | "RG_MODAL_FUNDING_CONTROL_OPEN"
    | "RG_MODAL_TIMEOUT_OPEN"
    | "RG_MODAL_SUBMIT"
    | "RG_MODAL_CANCEL"
    | "RG_MODAL_CLOSE"
    | "RG_MODAL_SELF_EXCLUSION_OPEN"
    | "RG_MODAL_SUSPENSION_OPEN"
    | "RG_MODAL_ERROR"
    | "GET_RG_MODAL_CALLBACK"
    | "GET_RG_MODAL_CALLBACK_SECONDARY"
    | "RG_MODAL_CLEAR_CALLBACKS"
    | "RG_MODAL_PROCESSING"
    | "RG_MODAL_FINISHED"
    | "OPEN_AW_MODAL"
    | "CLOSE_AW_MODAL"
    | "OPEN_TRANSACTION_HISTORY_MODAL"
    | "CLOSE_TRANSACTION_HISTORY_MODAL";
  payload?: {
    newLimit?: string;
    currentLimit?: string;
    appliedDate?: string;
    fundingMethod?: string;
    start?: string;
    end?: string;
    path?: string;
    title?: string;
  };
};

export type SetEmailReferralOpenAction = {
  type: "SET_EMAIL_REFERRAL_OPEN";
  payload: boolean;
};

export const setEmailReferralOpen = (
  isOpen: boolean
): SetEmailReferralOpenAction => ({
  type: "SET_EMAIL_REFERRAL_OPEN",
  payload: isOpen
});
