// @flow
import { type Dispatch } from "redux";
import type { RegionData } from "@tvg/geolocation";
import { get } from "lodash";
import states from "@tvg/conf/src/states";
import TvgGeolocation from "@tvg/geolocation";
import { getRegionByType } from "@tvg/utils/geoUtils";
import type { NullaryFn } from "@tvg/types/Functional";
import { RegionType } from "@tvg/ts-types/Geolocation";
import {
  checkValidWageringState,
  createSportsbookRegionCookie,
  getSportsbookRegionCookie
} from "@tvg/sh-utils/sessionUtils";

export type GeolocationStatus =
  | "LOCATION_IN_PROGRESS"
  | "LOCATION_FAILURE"
  | "GEOLOCATION_PASSED"
  | "GEOLOCATION_REJECTED";

export type LocationError =
  | "PERMISSION_DENIED"
  | "POSITION_UNAVAILABLE"
  | "TIMEOUT"
  | "LOCATION_SERVICE_ERROR"
  | "UNAVAILABLE_GEOLOCATION"
  | "STATE_UNAVAILABLE";

export type GeoLocationGetAction = {
  type: "GEO_LOCATION_GET",
  payload: RegionData
};

export type GeoLocationSetStateAction = {
  type: "GEO_LOCATION_SET_STATE",
  payload: { state: string }
};

export type GeoLocationResetAction = {
  type: "GEO_LOCATION_RESET"
};

export type GeoLocationStateSelectorAction = {
  type: "GEO_SHOW_STATE_SELECTOR",
  payload: boolean
};

export const getGeoLocation = (
  locationData: RegionData
): GeoLocationGetAction => ({
  type: "GEO_LOCATION_GET",
  payload: locationData
});

export const setGeoLocationState = (
  state: string
): GeoLocationSetStateAction => ({
  type: "GEO_LOCATION_SET_STATE",
  payload: { state }
});

export const resetGeoLocation = (): GeoLocationResetAction => ({
  type: "GEO_LOCATION_RESET"
});

export const setShowStateSelector = (
  showStateSelector: boolean
): GeoLocationStateSelectorAction => ({
  type: "GEO_SHOW_STATE_SELECTOR",
  payload: showStateSelector
});

type Props = {
  loading?: NullaryFn<void>,
  dispatch: Dispatch<GeoLocationGetAction>,
  allowedStates: Array<string>,
  isUserLogged: boolean
};

const getLocationError = (err: PositionError | Error): LocationError => {
  switch (get(err, "code", -1)) {
    case 1:
      return "PERMISSION_DENIED";
    case 2:
      return "POSITION_UNAVAILABLE";
    case 3:
      return "TIMEOUT";
    default:
      return "LOCATION_SERVICE_ERROR";
  }
};

const requestGeolocation = ({
  loading,
  dispatch,
  allowedStates,
  isUserLogged
}: Props): Promise<*> => {
  if (TvgGeolocation.isGeolocationAvailable()) {
    const tvgGeolocation = new TvgGeolocation();

    if (loading) {
      loading();
    }

    return tvgGeolocation
      .requestLocation()
      .then((locationData) => {
        const location = locationData;

        const region = getRegionByType(
          get(locationData, "regions", []),
          RegionType.STATE
        );

        const stateAbbr = get(
          states.find((state) => state.name === get(region, "name")),
          "abbreviation",
          ""
        );
        location.state = stateAbbr || getSportsbookRegionCookie();

        dispatch(getGeoLocation(location));

        const isUserStateAllowedBets = checkValidWageringState(
          stateAbbr,
          allowedStates
        );

        if (!isUserLogged && isUserStateAllowedBets) {
          createSportsbookRegionCookie(stateAbbr);
        } else if (!getSportsbookRegionCookie()) {
          // $FlowFixMe
          dispatch(setShowStateSelector(true));
        }

        if (!stateAbbr) {
          throw new Error("geolocation didnt get the user location state");
        }

        return Promise.resolve({
          location: get(location, "payload", {})
        });
      })
      .catch((err: Error) => {
        dispatch(getGeoLocation(TvgGeolocation.defaultRegionObject));
        return Promise.reject(new Error(getLocationError(err)));
      });
  }
  return Promise.reject(new Error("UNAVAILABLE_GEOLOCATION"));
};

export default requestGeolocation;
