import { combineReducers } from "redux";

import quickLinksReducer from "@tvg/quick-links/redux-manager";

import preferencesReducer, {
  initialState as initialPreferencesState
} from "@tvg/sh-lib-preferences/redux/reducers";

import capiReducer, {
  initialState as initialCapiState
} from "@tvg/sh-lib-capi-messages/redux/reducers";

import {
  desktopLoginControllerReducers,
  desktopLoginControllerReducersDefaultState
  // @ts-ignore - while we don't refactor login-controller to TS
} from "@tvg/login-controller/src/reducers";

import brazeReducer, {
  initialState as initialBrazeReducer
} from "@tvg/braze/src/store/reducers";

import registrationReducer, {
  initialState as initialRegistrationState
} from "@tvg/rma/src/reducer";

import promosReducer, {
  initialState as initialPromosState
} from "@tvg/promos/src/reducers";

import wagerRewardsReducer, {
  initialState as initialWagerRewardsState
} from "@tvg/wager-rewards/src/reducers";

import seoRaceTracksReducer, {
  initialState as initialSeoRaceTracksState
} from "@tvg/tracks/src/v2/redux/reducers";

import referReducer, {
  initialState as initialReferState
} from "@tvg/refer/src/reducers";

import mtpStatusReducer, {
  initialState as initialMtpStatusState
} from "@tvg/mtp-update/src/reducers";

import userFavoritesReducer, {
  initialState as initialUserFavoritesState
} from "@tvg/tracks/src/v2/redux/userFavoritesReducer";

import pawsReducer, { initialPawsState } from "@tvg/sh-lib-paws/redux/reducers";

import withdrawalsReducer, {
  initialState as initialWithdrawalsState
} from "@tvg/withdrawals/src/reducers";

import homeReducer, {
  initialState as initialHomeState
} from "@tvg/home/src/store/reducers";

import myBetsStandaloneReducer, {
  initialState as initialMyBetsStandaloneState
} from "@tvg/sh-lib-my-bets/redux/reducers";

import betTicketReducer, {
  initialState as initialBetTicketReducerState
} from "@tvg/desktop-bet/src/store/reducers";

import hdrReducer, {
  initialState as initialHdrState
} from "@tvg/hdr/src/store/reducers";

import tracksBuilderReducer, {
  initialState as initialTracksBuilderState
} from "@tvg/tracks-builder/src/reducers";

import { raceTracksInitialState, raceTracksReducer } from "@urp/lib-racetracks";

import programPageReducer, {
  initialState as initialProgramPageReducer
} from "@tvg/pp/src/store/reducers";

import {
  reducers as brazeInboxReducers,
  initialState as brazeInboxInitialState
} from "@urp/braze-inbox";

import toastReducer, {
  initialState as initialToastState
} from "@tvg/toast-manager/src/store/reducers";

import locationSplashReducer, {
  initialState as initialLocationSplashState
} from "@tvg/location-splash/reducer";

import RaceProgramVideoReducer, {
  initialState as initialVideoPGMState
} from "@fdr/video/reducers/video";

import { ApplicationState } from "../types";

import appReducer, { initialState as initialAppState } from "./appReducer";

import modalReducer, {
  initialState as initialModalState
} from "./modalReducer";

export const initialState = {
  app: initialAppState,
  home: initialHomeState,
  capi: initialCapiState,
  preferences: initialPreferencesState,
  registration: initialRegistrationState,
  promos: initialPromosState,
  wagerRewards: initialWagerRewardsState,
  refer: initialReferState,
  userFavorites: initialUserFavoritesState,
  seoRaceTracks: initialSeoRaceTracksState,
  mtpStatus: initialMtpStatusState,
  paws: initialPawsState,
  withdrawals: initialWithdrawalsState,
  myBetsStandalone: initialMyBetsStandaloneState,
  modal: initialModalState,
  topTracks: initialTracksBuilderState, // TODO: remove when topTracksDS is toggle on
  raceTracks: raceTracksInitialState,
  hdr: initialHdrState,
  BetTicket: initialBetTicketReducerState,
  toast: initialToastState,
  RaceProgramVideo: initialVideoPGMState,
  programPage: initialProgramPageReducer,
  ...desktopLoginControllerReducersDefaultState,
  brazeData: initialBrazeReducer,
  brazeInbox: brazeInboxInitialState,
  locationSplash: initialLocationSplashState
} as ApplicationState;

const rootReducer = combineReducers({
  app: appReducer,
  home: homeReducer,
  capi: capiReducer,
  preferences: preferencesReducer,
  registration: registrationReducer,
  promos: promosReducer,
  wagerRewards: wagerRewardsReducer,
  refer: referReducer,
  userFavorites: userFavoritesReducer,
  seoRaceTracks: seoRaceTracksReducer,
  mtpStatus: mtpStatusReducer,
  paws: pawsReducer,
  withdrawals: withdrawalsReducer,
  quickLinks: quickLinksReducer,
  myBetsStandalone: myBetsStandaloneReducer,
  modal: modalReducer,
  topTracks: tracksBuilderReducer,
  raceTracks: raceTracksReducer,
  hdr: hdrReducer,
  BetTicket: betTicketReducer,
  toast: toastReducer,
  programPage: programPageReducer,
  ...desktopLoginControllerReducers,
  brazeData: brazeReducer,
  brazeInbox: brazeInboxReducers,
  locationSplash: locationSplashReducer,
  RaceProgramVideo: RaceProgramVideoReducer
});

export default rootReducer;
