import styled, { css } from "styled-components";

export const TabContentContainer = styled.div`
  background-color: ${({ theme }) => theme.colorTokens.background.base};
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const UpcomingRacesContainer = styled.section`
  background-color: ${({ theme }) => theme.colorTokens.background.surface};
  width: 100%;
  padding-bottom: 0;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colorTokens.background.base};
  user-select: none;
  align-items: center;
  width: 100%;
`;

export const SectionContainer = styled.section<{ isUpcoming?: boolean }>`
  width: 100%;
  border-radius: ${({ theme }) => theme.radii.s};
  overflow: hidden;
  padding: ${({ theme }) => theme.space["space-5"]};
  background-color: ${({ theme }) => theme.colorTokens.background.base};
  ${({ isUpcoming }) =>
    isUpcoming &&
    css`
      padding-bottom: 0;
    `}
`;

export const EmptyMessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.space["space-5"]};
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding: ${({ theme }) => theme.space["space-5"]};
  margin: ${({ theme }) => theme.space["space-5"]};
  background-color: ${({ theme }) => theme.colorTokens.background.surface};
  flex: 1;
  width: fill-available;
`;
