import type { SeoTrack } from "@tvg/ts-types/Track";
import {
  getUSStateFullName,
  getCanStateFullName,
  getCountryFullName
} from "@urp/lib-racetracks/src/utils/trackInfo";

export const getTrackSubtitle = (track: SeoTrack) => {
  if (track.location.country === "USA")
    return getUSStateFullName(track.location.state);

  if (track.location.country === "CAN")
    return getCanStateFullName(track.location.state);

  return getCountryFullName(track.location.country);
};
