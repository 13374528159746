import React, { SyntheticEvent, useState } from "react";
import { format } from "date-fns";
import { UnaryFn, NullaryFn } from "@tvg/ts-types/Functional";
import mediator from "@tvg/mediator";
import { MediatorEventType as GlobalWalletEvents } from "@tvg/amplitude/modules/globalWallet/types";
import {
  Container,
  SessionContainer,
  SessionDescription,
  SessionPart,
  PlaceHolder,
  StyledUnmountClosed
} from "./styled-components";
import { Balance } from "../../types";
import SessionInfo from "../SessionInfo";
import AccountBalances from "../AccountBalances";
import AccountBalancesSummary from "../AccountBalancesSummary";
import AccountDetails from "../AccountDetails";

interface Props {
  sessionStartAt: Date;
  username: string;
  balancesSummary: Balance[];
  balances: Balance[];
  isBalanceVisible: boolean;
  onVisibilityToggle: UnaryFn<SyntheticEvent<HTMLButtonElement>, void>;
  qaLabel?: string;
  onViewBalanceDetails: NullaryFn<void>;
  onOpenPlayableBalanceModal: NullaryFn<void>;
}

export const AccountBalanceDetails = ({
  username,
  balancesSummary,
  balances,
  sessionStartAt,
  isBalanceVisible,
  onVisibilityToggle,
  onViewBalanceDetails,
  onOpenPlayableBalanceModal,
  qaLabel = "account-balance-details"
}: Props) => {
  const [isCollapsableOpen, setCollapsable] = useState(false);

  return (
    <Container data-qa-label={qaLabel}>
      <SessionContainer>
        <SessionPart>
          Last login:&nbsp;
          <SessionDescription data-qa-label={`${qaLabel}-lastLogin`}>
            {sessionStartAt && format(sessionStartAt, "MM/dd/yy")}
            &nbsp;at&nbsp;
            {sessionStartAt && format(sessionStartAt, "h:mm a")}
          </SessionDescription>
        </SessionPart>

        <SessionPart>
          <SessionInfo sessionStartAt={sessionStartAt} />
        </SessionPart>
      </SessionContainer>

      <AccountDetails username={username} />

      <AccountBalancesSummary
        isVisible={isBalanceVisible}
        onToggle={onVisibilityToggle}
        balances={balancesSummary}
        setCollapsable={(val: boolean) => {
          mediator.base.dispatch({
            type: GlobalWalletEvents.BALANCE_EXPANDED,
            payload: {
              isBalanceExpanded: val
            }
          });
          setCollapsable(val);
        }}
        isCollapsableOpen={isCollapsableOpen}
      />

      {isBalanceVisible && (
        <StyledUnmountClosed
          isOpened={isCollapsableOpen}
          data-qa-label={`${qaLabel}-collapse`}
        >
          <PlaceHolder />
          <AccountBalances
            balances={balances}
            onViewBalanceDetails={onViewBalanceDetails}
            onOpenPlayableBalanceModal={onOpenPlayableBalanceModal}
          />
        </StyledUnmountClosed>
      )}
    </Container>
  );
};

export default AccountBalanceDetails;
