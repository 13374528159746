// @flow
import mediatorChannels from "@tvg/mediator";
import { get } from "lodash";
import pushToDataLayer from "../gtmUtils";

type BetAmountType = "minimum" | "custom";

type BetPrefType = {
  type: "BET_PREFERENCES:SAVE_CHANGES",
  payload: {
    betType: string,
    betAmountOption: BetAmountType,
    tag?: string
  }
};

export default () => {
  mediatorChannels.base.subscribe(
    "BET_PREFERENCES:SAVE_CHANGES",
    (data: BetPrefType): void => {
      pushToDataLayer({
        event: "siteClick",
        gaEventCategory: "Site Click",
        gaEventAction: "Save changes",
        gaEventLabel: {
          betType: data.payload.betType,
          betAmountOption: data.payload.betAmountOption
        },
        module: "Default bet",
        tag: undefined,
        microApp: undefined
      });
    }
  );

  mediatorChannels.base.subscribe(
    "BET_PREFERENCES:NAVIGATE_MORE_PAGE",
    (data: BetPrefType): void => {
      pushToDataLayer({
        event: "navigation",
        gaEventCategory: "Navigation",
        gaEventAction: "Navigated To",
        gaEventLabel: "More Menu",
        menu: undefined,
        module: "Default bet",
        sport: undefined,
        microApp: "non-Microapp",
        tag: get(data, "payload.tag"),
        destinationUrl: "/account"
      });
    }
  );
};
