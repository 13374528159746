// @flow
// $FlowFixMe
import React, { useEffect, useState } from "react";
import {
  AccountDetailsWrapper,
  UserWrapper,
  UserInfo,
  UserInfoDetails,
  UserFullname,
  UserSession,
  UserSessionTime
} from "./styled-components";
import Avatar from "../../_atoms/Avatar";
import Label from "../../_atoms/Label";

type Props = {
  fullName: string,
  username: string,
  sessionStartAt: Date,
  qaLabel?: string
};

function formatToTwoDigits(n: number) {
  return n > 9 ? n : `0${n}`;
}

// format like: HH:MM:SS - where hours can go above 24 hours
function startTimeInterval(startAt: Date, callback: (time: string) => void) {
  const interval = setInterval(() => {
    const currentTime = new Date();
    const secondsElapsed = (currentTime.getTime() - startAt.getTime()) / 1000;
    const hoursElapsed = secondsElapsed / 3600;
    const includeHours = hoursElapsed > 1;
    const time =
      (includeHours ? `${formatToTwoDigits(Math.floor(hoursElapsed))}:` : ``) +
      new Date(secondsElapsed * 1000).toISOString().substr(14, 5);

    callback(time);
  }, 1000);

  return interval;
}

export const AccountDetails = ({
  fullName,
  username,
  sessionStartAt,
  qaLabel = "account-details"
}: Props) => {
  const [sessionTime, setSessionTime] = useState(null);

  useEffect(() => {
    const interval = startTimeInterval(sessionStartAt, setSessionTime);
    return () => clearInterval(interval);
  }, [sessionStartAt]);

  return (
    <AccountDetailsWrapper data-qa-label={qaLabel}>
      <UserWrapper>
        <UserInfo>
          <Avatar />
          <UserInfoDetails data-qa-label={`${qaLabel}-user-info`}>
            <UserFullname data-qa-label={`${qaLabel}-user-fullname`}>
              {fullName}
            </UserFullname>
            <Label qaLabel={`${qaLabel}-user-name`}>{username}</Label>
          </UserInfoDetails>
        </UserInfo>
        <UserSession>
          <UserSessionTime data-qa-label={`${qaLabel}-sessionTime`}>
            {sessionTime}
          </UserSessionTime>
          <Label>session time</Label>
        </UserSession>
      </UserWrapper>
    </AccountDetailsWrapper>
  );
};

export default AccountDetails;
