// @flow
// $FlowFixMe
import React, { useMemo } from "react";
import { uniqueId } from "lodash";
import { type Transaction } from "@tvg/types/Account";
import TransactionListItem from "../../_molecules/TransactionHistoryListItem";
import TransactionList from "./styled-components";

type Props = {
  transactions: Transaction[]
};

export const renderTransactionList = (transactions: Transaction[]) => (
  <TransactionList>
    {transactions.map((transaction: Transaction) => {
      const transactionId = uniqueId(transaction.type);
      return (
        <TransactionListItem
          key={`transactionList_${transactionId}`}
          transaction={transaction}
        />
      );
    })}
  </TransactionList>
);

const TransactionHistoryList = (props: Props) =>
  useMemo(() => renderTransactionList(props.transactions), [props]);

TransactionHistoryList.defaultProps = {
  transactions: []
};

export default TransactionHistoryList;
