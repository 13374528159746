// @flow
import { get } from "lodash";
import mediatorChannels from "@tvg/mediator";
import tvgConf from "@tvg/conf";

import type { InfoScreenGtm } from "@tvg/types/Registration";

import pushToDataLayer, { getSiteVersion } from "../../gtmUtils";

type InfoScreenGtmTypeEvents =
  | "REGISTRATION:INFO_SCREEN_ERROR"
  | "REGISTRATION:INFO_SCREEN_CTA_CLICK";

type InfoScreenGtmData = {
  type: InfoScreenGtmTypeEvents,
  payload: InfoScreenGtm
};

export default () => {
  const siteVersion = getSiteVersion(tvgConf().product);

  mediatorChannels.base.subscribe(
    "REGISTRATION:INFO_SCREEN_ERROR",
    (data: InfoScreenGtmData) => {
      pushToDataLayer({
        event: "registration",
        gaEventCategory: "Registration",
        gaEventAction: get(data, "payload.eventAction", ""),
        gaEventLabel: get(data, "payload.eventLabel", ""),
        siteVersion,
        module: get(data, "payload.module", "")
      });
    }
  );

  mediatorChannels.base.subscribe(
    "REGISTRATION:INFO_SCREEN_CTA_CLICK",
    (data: InfoScreenGtmData) => {
      pushToDataLayer({
        event: "registration",
        gaEventCategory: "Registration",
        gaEventAction: get(data, "payload.eventAction", ""),
        gaEventLabel: get(data, "payload.eventLabel", ""),
        siteVersion,
        module: get(data, "payload.module", "")
      });
    }
  );
};
