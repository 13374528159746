// @flow
import React, { Component } from "react";
import type { NullaryFn } from "@tvg/types/Functional";
import { noop, bindAll } from "lodash";

import { Switch, Slider } from "./styled-components";

type Props = {
  /**
   * Tells the toggle if it is on / off
   */
  on: boolean,
  /**
   * Callback for toggle click
   */
  onClick: NullaryFn<mixed>,
  /**
   * qaLabel for test
   */
  qaLabel: string
};

export default class Toggle extends Component<Props> {
  static defaultProps = {
    on: false,
    onClick: noop,
    qaLabel: ""
  };

  constructor(props: Props) {
    super(props);
    bindAll(this, ["clickToggle"]);
  }

  clickToggle(evt: Event) {
    evt.stopPropagation();
    evt.preventDefault();
    this.props.onClick();
  }

  render() {
    return (
      <Switch
        isOn={this.props.on}
        onClick={this.clickToggle}
        {...(this.props.qaLabel !== ""
          ? {
              "data-qa-label": this.props.qaLabel
            }
          : {})}
      >
        <input type="checkbox" />
        <Slider isOn={this.props.on} />
      </Switch>
    );
  }
}
