// @flow
import { get } from "lodash";

import tvgConf from "@tvg/conf";
import mediator from "@tvg/mediator";
import pushToDataLayer, { getSiteVersion } from "../../gtmUtils";

type OtherInteractionsGTMEventsType =
  | "REGISTRATION:FIELD_FILL_AND_VALIDATED"
  | "REGISTRATION:FIELD_ERROR";

type OtherInteractionsGTM = {
  type: OtherInteractionsGTMEventsType,
  payload: {
    module?: string,
    label?: string,
    isEditing?: boolean
  }
};

export default () => {
  const siteVersion = getSiteVersion(tvgConf().product);

  mediator.base.subscribe(
    "REGISTRATION:FIELD_FILL_AND_VALIDATED",
    (data: OtherInteractionsGTM) => {
      pushToDataLayer({
        event: "registration",
        gaEventCategory: "Registration",
        gaEventAction: get(data, "payload.isEditing")
          ? "Edit Reg Field Fills"
          : "Reg Field Fills",
        gaEventLabel: get(data, "payload.label"),
        module: get(data, "payload.isEditing")
          ? `Edit ${get(data, "payload.module")}`
          : get(data, "payload.module"),
        siteVersion
      });
    }
  );

  mediator.base.subscribe(
    "REGISTRATION:FIELD_ERROR",
    (data: OtherInteractionsGTM) => {
      pushToDataLayer({
        event: "registration",
        gaEventCategory: "Registration",
        gaEventAction: get(data, "payload.isEditing")
          ? "Edit Registration Error"
          : "Registration Error",
        gaEventLabel: get(data, "payload.label"),
        module: get(data, "payload.isEditing")
          ? `Edit ${get(data, "payload.module")}`
          : get(data, "payload.module"),
        siteVersion
      });
    }
  );
};
