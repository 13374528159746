import React, { memo } from "react";
import { useSelector } from "react-redux";
import {
  getRaceTrackContent,
  getRaceTrackDefaultMessage
} from "../../redux/selectors";
import { Container } from "./styled-components";
import { Props } from "./types";
import Box from "../../containers/Box";

const TrackDetailsContent = ({ trackCode = "" }: Props) => {
  const defaultContent = useSelector(getRaceTrackDefaultMessage);
  const content = useSelector(getRaceTrackContent(trackCode)) ?? defaultContent;

  if (!content) return null;

  return (
    <Box>
      <Container dangerouslySetInnerHTML={{ __html: content }} />
    </Box>
  );
};

export default memo(TrackDetailsContent);
