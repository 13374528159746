// @flow
// $FlowFixMe
import React, { memo } from "react";
import formatCurrency from "@tvg/formatter/currency";
import {
  AccountBalancesWrapper,
  Row,
  ColumnLabel,
  ColumnValue
} from "./styled-components";

export type Balance = {
  label: string,
  value: number,
  isHighlighted?: boolean
};

type Props = {
  balances: Balance[],
  qaLabel?: string
};

export const AccountBalances = memo(
  ({ balances, qaLabel = "account-balances" }: Props) => {
    return (
      <AccountBalancesWrapper data-qa-label={qaLabel}>
        {balances.map((balance) => {
          const balanceQaLabel = `${qaLabel}-${balance.label
            .toLowerCase()
            .trim()
            .replace(/ /g, "-")}`;

          return (
            <Row data-qa-label={balanceQaLabel} key={balance.label}>
              <ColumnLabel
                isHighlighted={!!balance.isHighlighted}
                data-qa-label={`${balanceQaLabel}-text`}
              >
                {balance.label}
              </ColumnLabel>
              <ColumnValue
                isHighlighted={!!balance.isHighlighted}
                data-qa-label={`${balanceQaLabel}-value`}
              >
                {formatCurrency(balance.value)}
              </ColumnValue>
            </Row>
          );
        })}
      </AccountBalancesWrapper>
    );
  }
);

export default AccountBalances;
