import mediator from "@tvg/mediator";

export const handleHamburgerMenuGtm = (isOpen: boolean) =>
  mediator.base.dispatch({
    type: "NAVIGATION:HAMBURGER_MENU_CLICK",
    payload: {
      isOpen
    }
  });

export const headerWagerPadGtm = () =>
  mediator.base.dispatch({
    type: "HEADER:WAGERPAD_CLICK"
  });
