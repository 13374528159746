// @flow

import mediatorChannels from "@tvg/mediator";
import { get } from "lodash";
import pushToDataLayer from "../gtmUtils";

type RaceAlertsGTMEventsType = "RACE_ALERTS:TOGGLE";

type RaceAlertsGTMType = {
  type: RaceAlertsGTMEventsType,
  payload: {
    toggledOn: boolean, // true if being turned on, false otherwise
    module: string,
    eventLabel: string // "Race Alerts" or "Alerts For Race"
  }
};

export default () => {
  mediatorChannels.base.subscribe(
    "RACE_ALERTS:TOGGLE",
    (data: RaceAlertsGTMType) => {
      pushToDataLayer({
        event: "siteClick",
        gaEventCategory: "Site Click",
        gaEventAction: get(data, "payload.toggledOn")
          ? "Toggle On"
          : "Toggle Off",
        gaEventLabel: get(data, "payload.eventLabel"),
        module: get(data, "payload.module"),
        tag: undefined,
        microApp: "non-Microapp"
      });
    }
  );
};
