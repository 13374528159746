import { SeoTrack } from "@tvg/ts-types/Track";
import { TrackFilterValueEnum } from "../types";

export const filterTrackByTrackType = (
  tracks: SeoTrack[],
  filter: TrackFilterValueEnum[],
  favoriteTracks: string[]
) =>
  tracks.filter((track) => {
    const isFavoriteTrack =
      favoriteTracks.includes(track.code) || track.isFavorite;
    const isFeaturedTrack = track.featured;
    const filterIncludesFeatured = filter.includes(
      TrackFilterValueEnum.FEATURED
    );
    const filterIncludesFavorites = filter.includes(
      TrackFilterValueEnum.FAVORITE
    );

    return (
      (filterIncludesFeatured && isFeaturedTrack) ||
      (filterIncludesFavorites && isFavoriteTrack) ||
      (filterIncludesFeatured &&
        filterIncludesFavorites &&
        isFeaturedTrack &&
        isFavoriteTrack)
    );
  });
