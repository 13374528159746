// @flow

import React, { PureComponent } from "react";
import styled, { keyframes } from "styled-components";
import color from "@fdr/static-ui/ColorPalette";

const pulseAnimation = keyframes`
    0% {
    fill: ${color("navy", "600")};
  }

  50% {
    fill: ${color("navy", "800")};
  }

  100% {
    fill: ${color("navy", "600")};
  }
`;

const SVG = styled.svg`
  display: block;

  rect.pulse {
    animation: ${pulseAnimation} 1.25s infinite;
  }
`;

const Container = styled.div`
  width: 100%;
  overflow-x: hidden;
`;

export default class ProgramPageHeaderMask extends PureComponent<*> {
  render() {
    return (
      <Container>
        <SVG
          xmlns="http://www.w3.org/2000/svg"
          width="1024"
          height="149"
          viewBox="0 0 1024 149"
        >
          <g fill="none" fillRule="evenodd" transform="translate(0 -48)">
            <rect width="1024" height="667" fill="#F0F3F8" />
            <g transform="translate(0 48)">
              <rect width="1024" height="149" fill="#152A47" />
              <rect
                className="pulse"
                width="284"
                height="8"
                x="12"
                y="82"
                fill="#224168"
                rx="2"
              />
              <rect
                className="pulse"
                width="135"
                height="8"
                x="12"
                y="52"
                fill="#224168"
                rx="2"
              />
              <rect
                className="pulse"
                width="41"
                height="20"
                x="12"
                y="8"
                fill="#224168"
                rx="2"
              />
            </g>
          </g>
        </SVG>
      </Container>
    );
  }
}
