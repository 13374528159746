// @flow
import { get } from "lodash";
import mediatorChannels from "@tvg/mediator";
import tvgConf from "@tvg/conf";

import pushToDataLayer, { getSiteVersion } from "../../gtmUtils";

type authModalType = "Promo To Be Added" | "Pre-Applied Promo" | "Referral";

type SuccessAuthenticationGTMTypeEvents =
  | "REGISTRATION:SUCCESS_AUTHENTICATION"
  | "REGISTRATION:SUCCESS_AUTHENTICATION_CTA_CLICK";

type SuccessAuthenticationGTMData = {
  type: SuccessAuthenticationGTMTypeEvents,
  payload: {
    gaEventLabel?: string,
    module?: authModalType
  }
};

export default () => {
  const siteVersion = getSiteVersion(tvgConf().product);

  mediatorChannels.base.subscribe(
    "REGISTRATION:SUCCESS_AUTHENTICATION",
    (data: SuccessAuthenticationGTMData) => {
      pushToDataLayer({
        event: "registration",
        gaEventCategory: "Registration",
        gaEventAction: "Authentication Modal Loaded",
        gaEventLabel: get(data, "payload.eventLabel", ""),
        siteVersion,
        module: get(data, "payload.module", "Promo To Be Added")
      });
    }
  );

  mediatorChannels.base.subscribe(
    "REGISTRATION:SUCCESS_AUTHENTICATION_CTA_CLICK",
    (data: SuccessAuthenticationGTMData) => {
      pushToDataLayer({
        event: "registration",
        gaEventCategory: "Registration",
        gaEventAction: "Authentication Modal CTA Clicked",
        gaEventLabel: get(data, "payload.eventLabel", ""),
        siteVersion,
        module: get(data, "payload.module", "Promo To Be Added")
      });
    }
  );
};
