import { useDispatch, useSelector } from "react-redux";
import type { RaceTypeCodeEnum } from "@tvg/ts-types/Race";
import { union } from "lodash";
import {
  setRaceTypeFilters,
  setRegionFilters,
  clearFilters,
  setTrackTypeFilter,
  setDistanceFilter,
  setRaceFilters,
  startSearchMode,
  endSearchMode
} from "../redux/actions/raceTracksFilter";
import {
  getDistancesFilter,
  getRacesFilter,
  getRaceTypesFilter,
  getRegionsFilter,
  getTracksFilterMsg,
  getTrackTypesFilter
} from "../redux/selectors";
import {
  isApplyingFilter,
  sendTracksFilterAnalyticEvt
} from "../utils/analytics";
import { FilterType, TrackFilterValueEnum } from "../types";

const useRaceTracksFilter = () => {
  const dispatch = useDispatch();
  const raceTypesFilter = useSelector(getRaceTypesFilter);
  const regionsFilter = useSelector(getRegionsFilter);
  const trackTypesFilter = useSelector(getTrackTypesFilter);
  const racesFilter = useSelector(getRacesFilter);
  const distancesFilter = useSelector(getDistancesFilter);
  const tracksFilterMsg = useSelector(getTracksFilterMsg);

  const handleRaceTypeChange = (raceType: string[], currentTab: string) => {
    const newRaceTypeFilters = union(raceTypesFilter, raceType);
    const shouldSendAnalyticEvt = isApplyingFilter(
      newRaceTypeFilters,
      raceType
    );
    dispatch(setRaceTypeFilters(raceType as RaceTypeCodeEnum[]));
    if (shouldSendAnalyticEvt) {
      sendTracksFilterAnalyticEvt({
        filterType: FilterType.FACET,
        filterName: "race_type",
        filterValue: raceType.toString(),
        activeTab: currentTab
      });
    }
  };

  const handleRegionChange = (region: string[], currentTab: string) => {
    const newRegionFilters = union(regionsFilter, region);
    const shouldSendAnalyticEvt = isApplyingFilter(newRegionFilters, region);
    dispatch(setRegionFilters(region));
    if (shouldSendAnalyticEvt) {
      sendTracksFilterAnalyticEvt({
        filterType: FilterType.FACET,
        filterName: "region",
        filterValue: region.toString(),
        activeTab: currentTab
      });
    }
  };

  const handleTrackChange = (trackType: string[], currentTab: string) => {
    const newTrackTypesFilter = union(trackTypesFilter, trackType);
    const shouldSendAnalyticEvt = isApplyingFilter(
      newTrackTypesFilter,
      trackType
    );
    dispatch(setTrackTypeFilter(trackType as TrackFilterValueEnum[]));
    if (shouldSendAnalyticEvt) {
      sendTracksFilterAnalyticEvt({
        filterType: FilterType.FACET,
        filterName: "track_type",
        filterValue: trackType.toString(),
        activeTab: currentTab
      });
    }
  };

  const handleRaceFilter = (bettingFeatures: string[], currentTab: string) => {
    const newRacesFilter = union(racesFilter, bettingFeatures);
    const shouldSendAnalyticEvt = isApplyingFilter(
      newRacesFilter,
      bettingFeatures
    );
    dispatch(setRaceFilters(bettingFeatures));
    if (shouldSendAnalyticEvt) {
      sendTracksFilterAnalyticEvt({
        filterType: FilterType.FACET,
        filterName: "betting_features",
        filterValue: bettingFeatures.toString(),
        activeTab: currentTab
      });
    }
  };

  const handleDistanceFilter = (distance: string[], currentTab: string) => {
    const newDistancesFilter = union(distancesFilter, distance);
    const shouldSendAnalyticEvt = isApplyingFilter(
      newDistancesFilter,
      distance
    );
    dispatch(setDistanceFilter(distance));
    if (shouldSendAnalyticEvt) {
      sendTracksFilterAnalyticEvt({
        filterType: FilterType.FACET,
        filterName: "distance",
        filterValue: distance.toString(),
        activeTab: currentTab
      });
    }
  };

  const handleClearFilters = () => {
    dispatch(clearFilters());
  };

  const handleStartSearchMode = () => {
    dispatch(startSearchMode());
  };

  const handleEndSearchMode = () => {
    dispatch(endSearchMode());
  };

  return {
    tracksFilterMsg,
    raceTypesFilter,
    regionsFilter,
    trackTypesFilter,
    racesFilter,
    distancesFilter,
    handleRaceTypeChange,
    handleRegionChange,
    handleClearFilters,
    handleTrackChange,
    handleRaceFilter,
    handleDistanceFilter,
    handleStartSearchMode,
    handleEndSearchMode
  };
};

export default useRaceTracksFilter;
