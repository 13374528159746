// @flow

import styled, { css } from "styled-components";
import { fontBold } from "@fdr/static-ui/Typography";
import buildColor from "@fdr/static-ui/ColorPalette";

export const AccountDetailsWrapper = styled.div`
  padding: 8px 16px 12px 16px;
`;

export const UserWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 8px;
`;

export const UserInfo = styled.div`
  display: flex;
`;

export const UserInfoDetails = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 8px;
`;

export const textStyles = css`
  font-family: ${fontBold};
  font-weight: 700;
  font-size: 16px;
  line-height: 125%;
`;

export const UserFullname = styled.span`
  ${textStyles};
  color: ${buildColor("navy")};
`;

export const UserSession = styled.div`
  display: flex;
  flex-direction: column;
`;

export const UserSessionTime = styled.div`
  ${textStyles};
  color: ${buildColor("grey", "D4")};
  min-height: 20px;
  text-align: right;
`;
