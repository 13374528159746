// @flow
import mediatorChannels from "@tvg/mediator";
import { get } from "lodash";
import pushToDataLayer from "../gtmUtils";

type PicksAlertsGTMEventsType = "PICKS_ALERTS:USER_ACTIONS";

type PicksAlertsGTM = {
  type: PicksAlertsGTMEventsType,
  payload: {
    action: "Confirm" | "Cancel" | "Track Alerts" | "Talent Alerts" | "Close",
    toggleStatus: "On" | "Off",
    entityType: "Track" | "Talent",
    name: string
  }
};

export default () => {
  mediatorChannels.base.subscribe(
    "PICKS_ALERTS:USER_ACTIONS",
    (data: PicksAlertsGTM) => {
      pushToDataLayer({
        event: "siteClick",
        gaEventCategory: "Site Click",
        gaEventAction: get(data, "payload.action"),
        gaEventLabel: undefined,
        module: "TVG Picks Alerts",
        tag: undefined,
        microApp: undefined
      });
    }
  );

  mediatorChannels.base.subscribe(
    "PICKS_ALERTS:TOGGLE_CLICK",
    (data: PicksAlertsGTM) => {
      pushToDataLayer({
        event: "siteClick",
        gaEventCategory: "Site Click",
        gaEventAction: `Toggle ${get(data, "payload.toggleStatus")}`,
        gaEventLabel: `${get(data, "payload.entityType")} Alerts`,
        module: "TVG Picks Alerts",
        tag: get(data, "payload.name"),
        microApp: undefined
      });
    }
  );
};
