import React from "react";
import ModalV2 from "@tvg/atomic-ui/_templates/ModalV2";
import { NullaryFn } from "@tvg/ts-types/Functional";
import { Modal } from "@tvg/design-system";
import { isFdrProduct } from "@tvg/sh-utils/mobileUtils";
import {
  Body,
  Description,
  ModalClose,
  ModalHeader,
  ModalPlaceholder,
  ModalTitle,
  Section,
  Title
} from "./styled-components";
import { PlayableBalanceContent } from "../../types";

interface Props {
  onClose: NullaryFn<void>;
  isOpen: boolean;
  modalContent: PlayableBalanceContent;
}

const PlayableModalHeader = (onClose: NullaryFn<void>, title: string) => (
  <ModalHeader>
    <ModalPlaceholder />
    <ModalTitle>{title}</ModalTitle>
    <ModalClose onClick={onClose}>Close</ModalClose>
  </ModalHeader>
);

const PlayableBalanceModal = (props: Props) =>
  !isFdrProduct() ? (
    <Modal
      isOpen={props.isOpen}
      title={<ModalTitle>{props.modalContent.title}</ModalTitle>}
      onOverlayClick={props.onClose}
      onClose={props.onClose}
      padding={0}
      headerLeftElement={undefined}
      maxWidth={450}
    >
      <Body>
        <Description>{props.modalContent.description}</Description>
        {props.modalContent.sections.map((section) => (
          <Section>
            <Title>{section.title}</Title>
            <Description>{section.description}</Description>
          </Section>
        ))}
      </Body>
    </Modal>
  ) : (
    <ModalV2
      onClose={props.onClose}
      title={props.modalContent.title}
      isOpen={props.isOpen}
      animation="bottom"
      isFullHeight={false}
      hasCloseButton={false}
      hasHeader={false}
      modalTopChildren={() =>
        PlayableModalHeader(props.onClose, props.modalContent.title)
      }
    >
      {() => (
        <Body>
          <Description>{props.modalContent.description}</Description>
          {props.modalContent.sections.map((section) => (
            <Section>
              <Title>{section.title}</Title>
              <Description>{section.description}</Description>
            </Section>
          ))}
        </Body>
      )}
    </ModalV2>
  );

export default PlayableBalanceModal;
